import React, { useState, useEffect } from 'react';
import Navigation from '../Overlays/Navigation';
import Banner from '../Additional/Banner';
import ClipLoader from "react-spinners/FadeLoader";
import { fetchQuote, createActivityQuoteDB, getToken,getApplicationCorrespondence } from '../API/Model';
import * as htmlToImage from 'html-to-image';
import jsPDF from "jspdf";
import './QuoteSummary_page.css';
import '../styles.css';


function QuoteSummary_page() {

    const page = 8;
    let quote = {
        "ApplicationNumber": '"' + window.localStorage.getItem("quote_number") + '"',
        "Owner": "SalesPortal"
    }
    const [url, setURL] = useState(null);
    const [flag, set_flag] = useState(false);
    const [load, setonLoad] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    const [coverage, setCoverage] = useState({
        covA: 0,
        covB: 0,
        covC: 0,
        covD: 0,
        covE: 0,
        covF_eachPerson: 0,
        covF_eachOccurrence: 0,
        policy_deductible: 0,
        waterDamage: 0,
        tropical_cyclone: 0,
    });

   
    useEffect(() => {
        if (load === false) {

            setonLoad(true);
            localStorage.setItem('status', "COMP");

            createActivityQuoteDB("QuoteSummary", "PL", "QuoteSummary page loaded");
            localStorage.setItem('current_page', "QuoteSummary");

            async function fetchData() {
                const response = await fetchQuote(JSON.stringify(quote));

                window.localStorage.setItem("quote_information", JSON.stringify({
                    firstYearPremium: response.DTOBasicPolicy[0].FinalPremiumAmt,
                    secondYearPremium: response.DTOLine[0].EstimatedSecondYearAssessment,
                    fiveYearPremium: response.DTOLine[0].EstimatedFiveYearAveAnnualCost,
                    num_bath_full: response.DTOLine[0].DTORisk[0].DTOBuilding[0].NumberBathrooms,
                    num_bath_part: response.DTOLine[0].DTORisk[0].DTOBuilding[0].NumberPartialBathrooms,
                    num_stories: response.DTOLine[0].DTORisk[0].DTOBuilding[0].NumStories,
                    sqft: response.DTOLine[0].DTORisk[0].DTOBuilding[0].SqFt,
                    build_year: response.DTOLine[0].DTORisk[0].DTOBuilding[0].YearBuilt,
                    found_type: response.DTOLine[0].DTORisk[0].DTOBuilding[0].FoundationType,
                    num_family: response.DTOLine[0].DTORisk[0].DTOBuilding[0].NumberOfFamily,
                }));

                setCoverage({
                    ...coverage,
                    covA: response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovALimit,
                    covB: response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovBLimit,
                    covC: response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovCLimit,
                    covD: response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovDLimit,
                    covE: response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovELimit,
                    covF: response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovALimit,
                    covF_eachPerson: response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovFLimit,
                    covF_eachOccurrence: response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovFLimitEachOccurrence,
                    policy_deductible: response.DTOLine[0].DTORisk[0].DTOBuilding[0].AllPerilDed,
                    waterDamage: response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovWaterDamageLimitIncrease,
                    tropical_cyclone: response.DTOLine[0].DTORisk[0].DTOBuilding[0].NamedStormDed,
                });

                setLoading(false);
                //set_flag(true);
            }
            fetchData();
            fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/updateQuoteDB', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Quote_Number: localStorage.getItem('quote_number'),
                    Quote_Status_code: "COMP",
                })
            })

        }
    });

    useEffect(() => {

        if (flag === true) {
     
            async function fetchToken() {
                const response = await getToken(JSON.stringify({}));
                if (response !== "ERROR") {
                    getURL(response)
                }
            }

            async function getURL(token) {
                const response = await getApplicationCorrespondence(JSON.stringify({
                    ApplicationNumber: window.localStorage.getItem("quote_number"),
                    CorrespondenceTemplateId: "HO5Estimate",
                    Token: token
                }));
                if (response !== "ERROR") {
                    setURL(response);
                }
            }

            set_flag(false);
            fetchToken();
        }
    }, [flag]);


 
    useEffect(() => {

        if (url != null) {

            fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'portal/checkCompletedQuote', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Quote_Number: localStorage.getItem('quote_number'),
                })
            })
                .then(res => res.json())
                .then(result => {
                  
                    if (result[0] === undefined) {

                        let formData = new FormData()
                        formData.append('pdf_url', url);
                        formData.append('email', JSON.parse(localStorage.getItem("personal_information")).email);
                        formData.append('subject', "Your Mutual Assurance Estimate");
                        formData.append('first_name', JSON.parse(localStorage.getItem("personal_information")).first_name);
                        formData.append('last_name', JSON.parse(localStorage.getItem("personal_information")).last_name);
                        formData.append('type', "email_a");
                        formData.append('inspection_name', JSON.parse(localStorage.getItem("personal_information")).inspection_contact_name);
                        

                        fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'EmailSender/sendEmail', {
                            method: 'POST',
                            body: formData
                        })
                            .then(res => res.json())
                            .then(result => {
                                createActivityQuoteDB("HomeInspection", "EMAIL_SENT", "Summary email sent");

                                fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'portal/updateQuoteDB', {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        Quote_Number: localStorage.getItem('quote_number'),
                                        Quote_Status_code: "COMP",
                                    })
                                })
                            })

                        formData = new FormData()
                        formData.append('email', JSON.parse(localStorage.getItem("personal_information")).email);
                        formData.append('pdf_url', null);
                        formData.append('subject', "SalesPortal - Inspection Request");
                        formData.append('type', "email_d");
                        formData.append('quote_number', localStorage.getItem("quote_number"));
                        formData.append('inspection_name', JSON.parse(localStorage.getItem("personal_information")).inspection_contact_name);
                        formData.append('inspection_type', JSON.parse(localStorage.getItem("personal_information")).inspection_contact_type);
                        formData.append('inspection_email', JSON.parse(localStorage.getItem("personal_information")).inspection_email);
                        formData.append('inspection_phone', JSON.parse(localStorage.getItem("personal_information")).inspection_number);

                        fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'EmailSender/sendEmail', {
                            method: 'POST',
                            body: formData
                        })
                            .then(res => res.json())
                            .then(result => {
                                createActivityQuoteDB("HomeInspection", "EMAIL_SENT", "Inspection email sent");

                                fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/updateQuoteDB', {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        Quote_Number: localStorage.getItem('quote_number'),
                                        Quote_Status_code: "COMP",
                                    })
                                })
                            })
                    }
                })
        }
    }, [url]);

    function formatNumber(number) {

        if (number != null) {

            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
            });
            return formatter.format(number);
        }
    }

    function printScreen() {
        console.log(url);
        htmlToImage.toPng(document.getElementById('content'), { quality: 2 })
            .then(function (dataUrl) {
                const pdf = new jsPDF('p', 'mm', [225, 420]);
                pdf.addImage(dataUrl, 'PNG', -15, -10, 230, 420);
                pdf.save("Coverage_" + window.localStorage.getItem("quote_number") + ".pdf");
            });
    }

    function handleToggle() {
        setIsExpanded(!isExpanded);
    }
    const iconClass = isExpanded ? 'rotate' : '';



    return (

        <div >
            <Navigation page={page} progress={0} />
            {
                loading === true ?
                    <div className="loading">
                        <div className="loading_icon">
                            <ClipLoader color={"#003550"} loading={true} height={20} width={5} radius={10} margin={10} />
                        </div>
                    </div>
                    :
                    <div className="wrap_p7" id="content">
{/*                        <div className={load === true ? {} : "d-none"}>
*/}                        <div className="quotesummary_box_wrapper">

                            <div className="baner" >

                                <p className="p8_header">We will contact <b>{JSON.parse(localStorage.getItem("personal_information")).inspection_contact_name}</b> at
                                    <b> {JSON.parse(localStorage.getItem("personal_information")).perf_contact}</b> within 3-5 business days to schedule your inspection.</p>

                            </div>
                            <Banner covA={formatNumber(coverage.covA.toString())} />

                            <p className="p6_title">Your Homeowners Estimate Summary
                                <button className="title_1_button" onClick={handleToggle}><span className={`arrow-icon ${iconClass}`}></span></button>
                                <img alt=" " className="icon_p7 share" src="/images/media_icon.jpg" />
                                <a href={url} download target="_blank">
                                    <img alt=" " className="icon_p7 document" href={url + ".pdf"} src="/images/document_icon.png" />
                                </a>
                                <img alt=" " className="icon_p7 print" onClick={printScreen} src="/images/printer_icon.png" />
                            </p>
                            {isExpanded &&

                                <div className="contentBox_p6" >

                                    <div className="p6_header">
                                        <br />
                                        <p className="p6_label bol">What’s included</p>
                                        <p className="p6_label ita">Home and personal coverages</p>
                                        <div className="vDivider" />
                                    </div>
                                    <div className="p6_c1_r1">
                                        <p className="p6_label bol"><h4> COVERAGE</h4> </p>
                                    </div>
                                    <div className="p6_c2_r1">
                                        <p className="p6_label bol"><h4> LIMIT</h4> </p>
                                    </div>

                                    <div className="p6_c1_r3">
                                        <p className="p6_label ">A - Residence</p>
                                    </div>
                                    <div className="p6_c2_r3">
                                        <p className="p6_label bol">{formatNumber(coverage.covA.toString())}</p>
                                    </div>

                                    <div className="p6_c1_r4">
                                        <p className="p6_label">B - Related Private Structures</p>
                                    </div>
                                    <div className="p6_c2_r4">
                                        <p className="p6_label bol">{formatNumber(coverage.covB.toString())}</p>
                                    </div>

                                    <div className="p6_c1_r5">
                                        <p className="p6_label">C - Personal Property</p>
                                    </div>
                                    <div className="p6_c2_r5">
                                        <p className="p6_label bol">{formatNumber(coverage.covC.toString())}</p>
                                    </div>

                                    <div className="p6_c1_r6">
                                        <p className="p6_label">D - Additional Living Costs and Loss of Rent</p>
                                    </div>
                                    <div className="p6_c2_r6">
                                        <p className="p6_label bol">{formatNumber(coverage.covD.toString())}</p>
                                    </div>

                                    <div className="p6_c1_r7">
                                        <p className="p6_label">E - Personal Liability</p>
                                    </div>
                                    <div className="p6_c2_r7">
                                        <p className="p6_label bol">{formatNumber(coverage.covE.toString())}</p>
                                    </div>

                                    <div className="p6_r8">

                                        <p className="p6_label">F - Medical Payments to Others</p>
                                    </div>

                                    <div className="p6_c1_r9">
                                        <p className="p6_label ml-5">Each Person</p>
                                    </div>
                                    <div className="p6_c2_r9">
                                        <p className="p6_label bol">{formatNumber(coverage.covF_eachPerson.toString())}</p>
                                    </div>

                                    <div className="p6_c1_r10">
                                        <p className="p6_label ml-5">Each Occurence</p>
                                    </div>

                                    <div className="p6_c2_r10">
                                        <p className="p6_label bol">{formatNumber(coverage.covF_eachOccurrence.toString())}</p>
                                    </div>

                                    <div className="p6_c1_r11">

                                        <p className="p6_label">Water Damage - Sewer/Drain/Sump/Surface Water</p>

                                    </div>
                                    <div className="p6_c2_r11">
                                        <p className="p6_label bol">
                                            $5,000</p>
                                    </div>
                                    <div className="p6_c1_r12">
                                        <div className="vDivider" />
                                    </div>
                                    <div className="p6_c1_r13">

                                        <p className="p6_label">All Perils deductible</p>
                                    </div>
                                    <div className="p6_c2_r13">

                                        <p className="p6_label bol">{formatNumber(coverage.policy_deductible.toString())}</p>
                                    </div>

                                    <div className="p6_c1_r14">
                                        <p className="p6_label">Tropical Cyclone deductible - (% of your Dwelling - Cov. A)</p>
                                    </div>
                                    <div className="p6_c2_r14">
                                        <p className="p6_label bol">{formatNumber(coverage.tropical_cyclone.toString())}</p>
                                    </div>

                                    <div className="p6_c1_r15">
                                        <div className="vDivider" />
                                        <p className="p6_label bol mt-3"><h4>ADDITIONAL COVERAGES INCLUDED:</h4> </p>
                                    </div>

                                    <div className="p6_c1_r16">
                                        <p className="p6_label">Personal Property Replacement Cost</p>
                                    </div>
                                    <div className="p6_c2_r16">
                                        <p className="p6_label ">Service Line</p>
                                    </div>

                                    <div className="p6_c1_r17">
                                        <p className="p6_label">Personal Property All Perils Coverage (HO5)</p>
                                    </div>
                                    <div className="p6_c2_r17">
                                        <p className="p6_label ">Equipment Breakdown</p>
                                    </div>

                                    <div className="p6_c1_r18">
                                        <p className="p6_label">Expanded Replacement Cost 125%</p>
                                    </div>
                                    <div className="p6_c2_r18">
                                        <p className="p6_label ">Personal Injury</p>
                                    </div>

                                    <div className="p6_c1_r19">
                                        <p className="p6_label">Inflation Guard Protection</p>
                                    </div>
                                    <div className="p6_c2_r19">
                                        <p className="p6_label ">Identity Recovery Coverage</p>
                                    </div>

                                </div>
                            }
                            <div className="p6_footer">

                                <div className="wrap">
                                    <br />
                                    <br /><br /><br />
                                    <a href="https://www.mutual-assurance.com/">
                                        <button type="submit" className="btn_p7" >Return to Mutual Assurance Home</button>
                                    </a>
                                    <br /><br /><br /><br /><br />
                                    <b>
                                        <p className="footer_p8_1 "> Questions? (800) 648-1794</p>
                                        <p className="footer_p8_1_5"><a href="mailto: service@mutual-assurance.com">service@mutual-assurance.com</a></p>
                                        <p className="footer_p8_2 ">(Monday to Friday 8am – 4:30pm)</p>
                                    </b>
                                    <p className="p6_label ita">Your customized quote has been saved</p>


                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default QuoteSummary_page