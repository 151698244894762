import React from 'react';
import { Link } from "react-router-dom";
import './HowItWorks_page.css';
import '../styles.css';
import { Redirect } from 'react-router';
function HowItWorks_page() {

    function onButtonClick() {
    
        fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'HowItWorksPage', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                session_id: localStorage.getItem('session_id'),
                HowItWorksPage_continue: 1
            })
        })
            .then(res => res.json())
            .then(result => {
            },
            (error) => {
            })
    }

        return (
            <div>
            
                <article className = "style">
                    <p className="txt_p2_1a">Thanks for getting an estimate with Mutual Assurance Society! With our unique perpetual Homeowner policy, members pay a one-time premium the first year and then a low annual assessment that is a fraction of the premium each year after that. This means significantly lower costs over the life of the policy.</p>

                    <p className="txt_p2_1b">The process of becoming a member usually takes 2-4 weeks and involves a thorough review of your home.</p>

                    <div className="txt_p2_2">Here's How it works:</div>
                      
                    <div className="spc" >
                        <p className="nmb"> 1 </p>
                        <img className="p2_icon" src="/images/Step_1_Icon.jpg" alt="Step_1"  />
                        <p className="txt_p2_3"><span className="bold_p2_3">Estimate.</span> We’ll give you an estimated insurance quote today. The quote may change depending on the details of your home that are found during the inspection.</p>
                       
                    </div>
                    <div className="spc">
                        <p className="nmb"> 2 </p>
                        <img className="p2_icon"  src="/images/Step_2_Icon.jpg" alt="Step_2" />
                        <p className="txt_p2_3"><span className="bold_p2_3">Inspection.</span> Schedule your free home inspection. We inspect the interior and exterior of your home to identify any safety hazards.</p>
                    </div>
                    <div className="spc">
                        <p className="nmb"> 3 </p>
                        <img className="p2_icon"  src="/images/Step_3_Icon.jpg" alt="Step_3" />
                        <p className="txt_p2_3"><span className="bold_p2_3">Review.</span> An underwriter will review the inspection report and discuss any changes needed to improve your home’s safety.</p>
                    </div>
                    <div className="spc">
                        <p className="nmb"> 4 </p>
                        <img className="p2_icon"  src="/images/Step_4_Icon.jpg" alt="Step_4" />
                        <p className="txt_p2_3"><span className="bold_p2_3">Quote.</span> Get a firm quote for your policy.</p>
                    </div>
                    <div className="spc">
                        <p className="nmb"> 5 </p>
                        <img className="p2_icon"  src="/images/Step_5_Icon.jpg" alt="Step_5" />
                        <p className="txt_p2_3"><span className="bold_p2_3">Start Saving.</span> Once you become a member, you’ll start saving thousands on your home insurance.</p>
                    </div>
                </article>

                <Link to={{
                    pathname: "/YourInfo"
                }}>
                    <button className="btn_p2" onClick={onButtonClick}>Continue</button>
                   
                </Link>
                <Link to={{
                    pathname: "/",
                }}>
                    <button className="btn_p2_2" onClick={onButtonClick}>Back</button>
                </Link>
            </div>

        );
    
}

export default HowItWorks_page
