import { Link, useHistory } from "react-router-dom";
import Navigation from '../Overlays/Navigation';
import React, { useState, useEffect } from 'react';
import './HomeInspection_page.css';
import ClipLoader from "react-spinners/FadeLoader";
import '../styles.css';
import { updateQuote, fetchQuote, getToken, getApplicationCorrespondence, createActivityQuoteDB } from '../API/Model';
import moment from 'moment';
import { Redirect } from 'react-router';


function HomeInspection_page() {

    let history = useHistory();
    const page = 7;
  
    const [load, onLoad] = useState(false);
    const [submitLoading, setsubmitLoading] = useState(false);
    const [quote_information, setquote_information] = useState(0);
    let radio_options = ["Email", "Phone", "Text"];
    const [url, setURL] = useState(null);
    const [flag, set_flag] = useState(false);



    const [personal_info, setPersonalInfo] = useState({
        Inspection_contact_name: "",
        Inspection_contact_type: "",
        Inspection_number: "",
        Inspection_email: "",
        Inspection_pref_contact_type: "",
    });


    let quote = {
        "ApplicationNumber": '"' + window.localStorage.getItem("quote_number") + '"',
        "Owner": "SalesPortal"
    }

    const onChangeUser = (e) => {
        setPersonalInfo({ ...personal_info, [e.target.name]: e.target.value });
    }


    useEffect(() => {

        if (load === false) {

            createActivityQuoteDB("HomeInspection", "PL", "HomeInspection page loaded");
            localStorage.setItem('current_page', "HomeInspection");

            setPersonalInfo({
                ...personal_info,
                Inspection_contact_name: JSON.parse(localStorage.getItem("personal_information")).inspection_contact_name,
                Inspection_contact_type: JSON.parse(localStorage.getItem("personal_information")).inspection_contact_type,
                Inspection_email: JSON.parse(localStorage.getItem("personal_information")).inspection_email,
                Inspection_number: JSON.parse(localStorage.getItem("personal_information")).inspection_number,
                Inspection_pref_contact_type: JSON.parse(localStorage.getItem("personal_information")).inspection_pref_contact_type,
            });

            async function fetchData() {

                const response = await fetchQuote(JSON.stringify(quote));
                setquote_information({
                    ExternalValidateBeanExist: "No",
                    DTOApplication: [
                        response
                    ]
                })
                setsubmitLoading(false);
                onLoad(true);
            }

            fetchData();
        }
        set_flag(true);

    }, []);

    useEffect(() => {

        if (flag === true) {

            async function fetchToken() {
                const response = await getToken(JSON.stringify({}));
                if (response !== "ERROR") {
                    getURL(response)
                }
            }

            async function getURL(token) {
                const response = await getApplicationCorrespondence(JSON.stringify({
                    ApplicationNumber: window.localStorage.getItem("quote_number"),
                    CorrespondenceTemplateId: "HO5Estimate",
                    Token: token
                }));
                if (response !== "ERROR") {
                    setURL(response);
                }
            }

            set_flag(false);
            fetchToken();
        }
    }, [flag]);



    function onButtonClick() {

        createActivityQuoteDB("HomeInspection", "NAV", "Continue button");
        setsubmitLoading(true);


        window.localStorage.setItem("personal_information", JSON.stringify({
            ...JSON.parse(localStorage.getItem("personal_information")),
            perf_contact: (personal_info.Inspection_pref_contact_type === "Email" ? personal_info.Inspection_email : personal_info.Inspection_number),
            inspection_contact_name: personal_info.Inspection_contact_name,
            inspection_contact_type: personal_info.Inspection_contact_type,
            inspection_number: personal_info.Inspection_number,
            inspection_email: personal_info.Inspection_email,
            inspection_pref_contact_type: personal_info.Inspection_pref_contact_type,
        }));


        async function updateInspection() {

            // add days
            const addDt = moment(quote_information.DTOApplication[0].DTOApplicationInfo[0].AddDt, "YYYYMMDD");
            const needByDt = addDt.add(14, 'days').format("YYYYMMDD");

            createActivityQuoteDB("AdditionalInfo", "API_REQ", "Inspection data update request");

            quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].InspectionContactType = (personal_info.Inspection_contact_type === "Someone_else" ? "Assured" : personal_info.Inspection_contact_type);
            quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].InspectionContactName = personal_info.Inspection_contact_name;
            quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].InspectionNumber = personal_info.Inspection_number;
            quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].InspectionDt = quote_information.DTOApplication[0].DTOApplicationInfo[0].AddDt;
            quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].InspectionNeedByDt = needByDt;
            quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].InspectionRush = "No";
            quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].InspectionRequestedBy = "Web Estimate";
            quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].InspectionSpecialInstructions = "Best Way to Contact:" + personal_info.Inspection_pref_contact_type + " " + "Email: " + personal_info.Inspection_email;
            if (quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].CovALimit > 750000 ) {
                quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].InspectionType = "High";
            } else {
                quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].InspectionType = "Standard";
            }
            let updatedQuestionReplies = {
                QuestionReply: [
                    {
                        Name: "NewPurchase",
                        Value: JSON.parse(localStorage.getItem("personal_information")).prior_addr === true ? "YES" : "NO",
                        VisibleInd: "Yes"
                    },
                ]
            }
            quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].QuestionReplies = updatedQuestionReplies;

            fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'portal/updateQuoteInformation', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Quote_Number: localStorage.getItem('quote_number'),
                    Inspection_Email: personal_info.Inspection_email,
                    Inspection_Best_Way_To_Contact: personal_info.Inspection_pref_contact_type,
                })
            })

            const response = await updateQuote(JSON.stringify(quote_information));

            if (response === "SUCCESS") {
                createActivityQuoteDB("AdditionalInfo", "API_RES", "Inspection data update response - " + response);
            }
            //history.push("/QuoteSummary");
        }

        updateInspection();

        const email_sent = localStorage.getItem('inspection_email') === "YES";
        if (url != null && !email_sent) {

                            let formData = new FormData()
                            formData.append('pdf_url', url);
                            formData.append('email', personal_info.Inspection_email);
                            formData.append('subject', "Your Mutual Assurance Estimate");
                            formData.append('first_name', JSON.parse(localStorage.getItem("personal_information")).first_name);
                            formData.append('last_name', JSON.parse(localStorage.getItem("personal_information")).last_name);
                            formData.append('type', "email_a");
                            formData.append('inspection_name', JSON.parse(localStorage.getItem("personal_information")).inspection_contact_name);


                            fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'EmailSender/sendEmail', {
                                method: 'POST',
                                body: formData
                            })
                                .then(res => res.json())
                                .then(result => {
                                    createActivityQuoteDB("HomeInspection", "EMAIL_SENT", "Summary email sent");

                                    fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/updateQuoteDB', {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            Quote_Number: localStorage.getItem('quote_number'),
                                            Quote_Status_code: "STR",
                                        })
                                    })
                                })
                            formData = new FormData()
                            formData.append('email', personal_info.Inspection_email);
                            formData.append('pdf_url', null);
                            formData.append('subject', "SalesPortal - Inspection Request");
                            formData.append('type', "email_d");
                            formData.append('quote_number', localStorage.getItem("quote_number"));
                            formData.append('inspection_name', JSON.parse(localStorage.getItem("personal_information")).inspection_contact_name);
                            formData.append('inspection_type', JSON.parse(localStorage.getItem("personal_information")).inspection_contact_type);
                            formData.append('inspection_email', JSON.parse(localStorage.getItem("personal_information")).inspection_email);
                            formData.append('inspection_phone', JSON.parse(localStorage.getItem("personal_information")).inspection_number);

                            fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'EmailSender/sendEmail', {
                                method: 'POST',
                                body: formData
                            })
                                .then(res => res.json())
                                .then(result => {
                                    createActivityQuoteDB("HomeInspection", "EMAIL_SENT", "Inspection email sent");

                                    fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/updateQuoteDB', {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            Quote_Number: localStorage.getItem('quote_number'),
                                            Quote_Status_code: "STR",
                                        })
                                    })
                                })
                window.localStorage.setItem("inspection_email", "YES");

        }

    }


    function onBackButtonClick() {
        createActivityQuoteDB("HomeInspection", "NAV", "Back button");
    }

    if (localStorage.getItem('status') === "COMP") {
        return <Redirect push to={'/QuoteSummary'} />;
    } else {
        return (
            <div>

                <Navigation page={page} progress={5} />
                {
                    submitLoading ?
                        <div className="loading">
                            <div className="loading_icon">
                                <ClipLoader color={"#003550"} loading={true} height={20} width={5} radius={10} margin={10} />
                            </div>
                        </div>
                        :
/*                        <div className={load === true ? {} : "d-none"}>
*/                        <div className="homeinspection_box_wrapper">

                            <p className="page_8_title">Let’s schedule your home inspection.</p>
                            <div className="page_8_box">

                                <div className="p8_r1">
                                    <p className="page_8_text mt-2">We conduct a free inspection of your home to provide common sense measures that protect your home and ensure you have the right coverage.</p>
                                    <div className="page_8_Divider" /><br />
{/*                                    <p className="page_8_text">Please provide the name and contact information for the person who will be responsible for scheduling the inspection. This can be you, your realtor, or someone else who can provide access to the property.</p>
*/}

                                </div>
                                <div className="p8_r2_c1">
                                    <p className="page_8_text_2">Inspection Contact Name:</p>
                                    <input className="page_8_input mt-3" placeholder="name" name="Inspection_contact_name" id="contact_name" value={personal_info.Inspection_contact_name} onChange={onChangeUser} />
                                </div>
                                <div className="p8_r2_c2">
                                    <p className="page_8_text_2 mb-2">Who will schedule the inspection?</p>
                                    <select className="page_8_input mt-3 p-0 pl-3" name="Inspection_contact_type" id="contact_type" size="1" value={personal_info.Inspection_contact_type} onChange={onChangeUser}>
                                        <option value="Assured" defaultValue>Me</option>
                                        <option value="Realtor">My Realtor</option>
                                        <option value="Someone_else">Someone Else</option>
                                    </select>
                                </div>
                                <div className="p8_r3">
                                    <p className="page_8_text_2">Best way to contact</p>
                                    <br className="mobile_space" />
                                    {radio_options.map(result => (
                                        <>
                                            <input className="radio_button" type="radio" value={result} checked={personal_info.Inspection_pref_contact_type === result}
                                                onChange={onChangeUser}
                                                name="Inspection_pref_contact_type" />
                                            <b className="radio_button_text light ">{result}</b>
                                            <span className="radio_break" />
                                        </>
                                    ))}
                                </div>

                                <div className="p8_r4_c1">
                                    <p className="page_8_text_2">Email: </p>
                                    <br />
                                    <input className="page_8_input page_8_padd" placeholder="Email" name="Inspection_email" id="email" value={personal_info.Inspection_email} onChange={onChangeUser} />
                                </div>



                                <div className="p8_r5">
                                    <p className="page_8_text_2">Phone: </p>
                                    <br />
                                    <input className="page_8_input page_8_padd" placeholder="Phone" name="Inspection_number" id="phone" value={personal_info.Inspection_number} onChange={onChangeUser} />
                                    <br /><br />
                                    <div className="page_8_Divider" /><br />

                                    <div className="page_8_button_wrapper">
                                        <Link to="/Coverages">
                                        <p type="submit" className="page_8_button_2" onClick={onBackButtonClick}>&lt;<span>Back</span></p>
                                        </Link>
                                        <Link to="/AdditionalInfo">
                                            <button type="submit" className="page_8_button" onClick={onButtonClick}>Continue</button>
                                       </Link>
                                    </div>
                                    <b>
                                        <p className="footer_p7_1"> Questions? (800) 648-1794</p>
                                        <p className="footer_p7_1_5"><a href="mailto: service@mutual-assurance.com">service@mutual-assurance.com</a></p>
                                        <p className="footer_p7_2">(Monday to Friday 8am – 4:30pm)</p>
                                    </b>
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

export default HomeInspection_page
