import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import GooglePlaces from '../Additional/GooglePlaces';
import Alert from '../Additional/Alert';
import { v4 as uuidv4 } from 'uuid';
import './Home_page.css';
import '../styles.css';
import { createActivityQuoteDB, retrieveCustomer } from '../API/Model';
import axios from 'axios'
import Navigation from '../Overlays/Navigation';

class Home_page extends Component {

    constructor(props) {
        super(props);
        localStorage.clear();

        this.state = {
            addressInfo: {},
            disable: true,
            alert_state: false,
            error_message: "",
            width: window.innerWidth,
            height: window.innerHeight,
            errorHeight: 85,
            mainErrorHeight: 65,
            cov_a_limit: null,
            isFocused: false,
            selectedOption: false,
            formData: {
                firstName: '',
                lastName: '',
                email: '',
                zipcode: ''
            }
        }

        this.setAddressInfo = this.setAddressInfo.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onCovALimitChange = this.onCovALimitChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        localStorage.setItem('session_id', uuidv4());
        localStorage.setItem('current_page', "Home");
    }

    async componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        const res = await axios.get('https://geolocation-db.com/json/')
        localStorage.setItem('user_ip', res.data.IPv4);
        localStorage.setItem('status', "STR");
        createActivityQuoteDB("IndexPage", "PL", "Index page loaded");
    }

    handleResize() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    formatCurrency(num) {
        return num ? "$" + num.toLocaleString("en-US", { minimumFractionDigits: 0 }) : "";
    }

    parseCurrency(str) {
        return str ? parseInt(str.replace(/\D/g, "")) : null;
    }

    onCovALimitChange(e) {
        const newValue = this.parseCurrency(e.target.value.replace(/,/g, ''));
        if (newValue <= 99999999) {
            this.setState({ cov_a_limit: newValue })
        }
        else if (!newValue) {
            this.setState({ cov_a_limit: null })
        }
    }

    handleBlur(e) {
        const { cov_a_limit } = this.state;
        if (!cov_a_limit) {
            this.setState({ cov_a_limit: null });
        }
        else if (cov_a_limit < 100000) {
            this.setState({ cov_a_limit: 100000 });
        }
    }

    handleOptionChange = (event) => {
        this.setState({
            selectedOption: event.target.value === 'yes',
        });
        this.setState({
            formData: {
                firstName: '',
                lastName: '',
                email: '',
                zipcode: ''
            },
        });
    };

    async handleSubmit(e) {
        this.setState({
            mainErrorHeight: 65,
        })
        const cov_a = this.state.cov_a_limit;
        const existing_customer = this.state.selectedOption ? "YES" : "NO";
        if (this.state.selectedOption) {
            e.preventDefault();
            this.setState({
                alert_state: false,
            })
            const formData = this.state.formData;
            const response = await retrieveCustomer(JSON.stringify(formData));
            let myError = null;
            try {
                myError = response.Error[0].Message;
            } catch (error) {}

            if (myError !== null) {
                this.setState({
                    alert_state: true,
                    error_message: myError,
                    errorHeight: 130,
                    mainErrorHeight: 85,
                })
            }
            else {
                const personalInfo = JSON.parse(window.localStorage.getItem('personal_information'));
                personalInfo.first_name = this.state.formData.firstName;
                personalInfo.last_name = this.state.formData.lastName;
                personalInfo.email = this.state.formData.email;
                personalInfo.mail_addr_zip = this.state.formData.zipcode;
                personalInfo.customer_ref = response.CustomerRef;
                window.localStorage.setItem('personal_information', JSON.stringify(personalInfo));
                window.localStorage.setItem("existing_customer", existing_customer);
                if (cov_a !== null) {
                    window.localStorage.setItem("cov_a", cov_a.toString());
                }
                this.onButtonClick();
                this.props.history.push('/YourInfo');

            }
        }
        else {
            if (cov_a !== null) {
                window.localStorage.setItem("cov_a", cov_a.toString());
            }
            window.localStorage.setItem("existing_customer", existing_customer);
            this.onButtonClick();
            this.props.history.push('/YourInfo');
        }

    }

    handleInputChange(event) {
        const { name, value } = event.target;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    }

    setAddressInfo(addressInfo) {
        this.setState({
            addressInfo,
            mainErrorHeight: 65,
        })
        if (this.state.addressInfo.state !== "Virginia") {

            this.setState({
                disable: true,
                alert_state: true
            })
            this.setState({
                error_message: "We only offer policies for homes in Virginia. Thanks for checking us out.",
                errorHeight: 85,
            })

        } else {
            if (this.state.addressInfo.city
                && this.state.addressInfo.country
                && this.state.addressInfo.state
                && this.state.addressInfo.street
                && this.state.addressInfo.street_number
                && this.state.addressInfo.zip) {
                this.setState({
                    disable: false,
                    alert_state: false
                })
            } else {
                this.setState({
                    disable: true,
                    alert_state: true,
                    error_message: "We are unable complete your estimate at this time. Please contact customer service at 800-648-1794.",
                    errorHeight: 105,
                })
            }
        }

    }


    async onButtonClick() {

        createActivityQuoteDB("IndexPage", "NAV", "Conitnue button");
        window.grecaptcha.enterprise.ready(async function () {
            try {
                const token = await window.grecaptcha.enterprise.execute('6LfCsp0dAAAAAK_ovmpumPsEqegcHoMmNcxz89u3', { action: 'LOGIN' });

            } catch (e) {
                console.error("Capcha Error : ", e);
            }
        });
    }

    render() {

        const { formData } = this.state;
        const { cov_a_limit } = this.state;

        return (
            <div>
                <Navigation page={0} progress={0} />
                <div className="box_home_wrapper">

                <div className="offset"></div>
                {this.state.width < 758 &&
                        <Alert status={this.state.alert_state} message={this.state.error_message} width={this.state.width - 30} height={this.state.errorHeight} margin={0} color={"#ffb3b3"} />
                }
                {this.state.width > 758 &&

                        <Alert status={this.state.alert_state} message={this.state.error_message} width={810} height={this.state.mainErrorHeight} margin={"auto"} marginRight={"auto"} color={"#ffb3b3"} />
                }
                    <p className="p1_txt">Our policyholders save $2,000 on average over 5 years.<br/>
                        Estimate your savings:</p>
                <div className="box">
                    <GooglePlaces setAdressInfo={this.setAddressInfo} />

                    <div >
                        <div className="homeValue">
                            <input
                            type="text"
                                value={cov_a_limit}
                                value={this.formatCurrency(cov_a_limit)}
                                onChange={this.onCovALimitChange}
                                onBlur={this.handleBlur}
                            placeholder="Your home value"
                            />
                            <span className="baner_icon_info_home baner_icon" data-tooltip="This is the cost to replace your home in case of total loss.  You can find this on your current policy as ‘Coverage A’, or the ‘Dwelling/Residence Limit’.  If you are unsure of the value, you can use the market value of your home from Zillow or another source as a rough estimate. Prior to providing a firm quote, we will conduct an inspection of your home to ensure you have the right amount of coverage." />
                         </div>
                    </div>
                    <div className="radio-buttons-container">
                        <label htmlFor="yes-checkbox" className="checkboxText">Are you existing member of Mutual Assurance?</label>
                        <div className="radio-button-wrapper">
                            <input
                                type="radio"
                                id="yes-radio"
                                name="here"
                                value="yes"
                                checked={this.state.selectedOption}
                                onChange={this.handleOptionChange}
                            />
                            <label htmlFor="yes-radio" className="checkboxText">Yes</label>
                        </div>
                        <div className="radio-button-wrapper">
                            <input
                                type="radio"
                                id="no-radio"
                                name="here"
                                value="no"
                                checked={!this.state.selectedOption}
                                onChange={this.handleOptionChange}
                            />
                            <label htmlFor="no-radio" className="checkboxText">No</label>
                        </div>
                    </div>

                    {this.state.selectedOption && (
                        <div className="text-box-container">
                            <p className="checkboxText">Customer Search:</p>
                            <form onSubmit={this.handleSubmit} className="text-box-wrapper">
                                <input type="text" placeholder="First Name" className="text-box_input" name="firstName" value={formData.firstName} onChange={this.handleInputChange} required />
                                <input type="text" placeholder="Last Name" className="text-box_input" name="lastName" value={formData.lastName} onChange={this.handleInputChange} required />
                                <input type="email" placeholder="Email Address" className="text-box_input" name="email" value={formData.email} onChange={this.handleInputChange} required />
                                <input type="text" placeholder="Mailing Address Zip Code" className="text-box_input" name="zipcode" value={formData.zipcode} onChange={this.handleInputChange} required />
                            </form>
                        </div>
                    )}

                    {
                        this.state.disable
                            ? <Link to="/YourInfo" className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                <button disabled={this.state.disable} className="btn_page_1" onClick={this.handleSubmit} style={{ background: "#808080" }}>Continue</button>
                            </Link>
                            : <div className="disabledCursor">
{/*                                    <Link to="/YourInfo" className="disabledCursor"></Link>
*/}                                <button disabled={this.state.disable} className="btn_page_1" onClick={this.handleSubmit}>Continue</button>
                            </div>
                    }
                    </div>
                    </div>
            </div>
        );
    }
}

export default withRouter(Home_page);
