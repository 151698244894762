import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { Layout } from './components/Layout';
import Home_page  from './components/Page 1/Home_page';
import HowItWorks_page  from './components/Page 2/HowItWorks_page';
import YourInfo_page from './components/Page 3/YourInfo_page';
import AdditionalInfo_page from './components/Page 4/AdditionalInfo_page';
import PropertyInfo_page from './components/Page 5/PropertyInfo_page';
import Coverages_page from './components/Page 6/Coverages_page';
import QuoteSummary_page from './components/Page 8/QuoteSummary_page';
import HomeInspection_page from './components/Page 7/HomeInspection_page';
import Page_retrieve_a from './components/Retrive Pages/Page_retrieve_a';
import Page_retrieve_a_2 from './components/Retrive Pages/Page_retrieve_a_2';
import Page_retrieve_b from './components/Retrive Pages/Page_retrieve_b';
import Page_save from './components/Save Page/Page_save';
import './custom.css'
import ScrollToTop from './components/Additional/ScrollToTop';
import New_Landing_Page from './components/Page9/New_Landing_Page';
import New_Landing_Page_2 from './components/Page10/New_Landing_Page_2';
import New_Landing_Page_3 from './components/Page11/New_Landing_Page_3';
import Landing_Page_Blacksburg from './components/Landing_Pages/Blacksburg/Landing_Page_Blacksburg';
import Inspection_Info_Blacksburg from './components/Landing_Pages/Blacksburg/Inspection_Info_Blacksburg';
import Landing_Page_Botetourt from './components/Landing_Pages/Botetourt/Landing_Page_Botetourt';
import Inspection_Info_Botetourt from './components/Landing_Pages/Botetourt/Inspection_Info_Botetourt';
import Landing_Page_Culpeper from './components/Landing_Pages/Culpeper/Landing_Page_Culpeper';
import Inspection_Info_Culpeper from './components/Landing_Pages/Culpeper/Inspection_Info_Culpeper';
import Landing_Page_Harrisonburg from './components/Landing_Pages/Harrisonburg/Landing_Page_Harrisonburg';
import Inspection_Info_Harrisonburg from './components/Landing_Pages/Harrisonburg/Inspection_Info_Harrisonburg';

export default class App extends Component {

    static displayName = App.name;
 
    render() {
    
        return (

            <Router>
                <ScrollToTop/>
                <Layout>
                    <Switch>
                        <Route exact path="/">
                            <Home_page />
                        </Route>
                        <Route exact path="/HowItWorks">
                            <HowItWorks_page />
                        </Route>
                        <Route exact path="/YourInfo">
                            <YourInfo_page/>
                        </Route>
                        <Route exact path="/PropertyInfo">
                            <PropertyInfo_page/>
                        </Route>
                        <Route exact path="/Coverages">
                            <Coverages_page/>
                        </Route>
                        <Route exact path="/AdditionalInfo">
                            <AdditionalInfo_page/>
                        </Route>
                        <Route exact path="/QuoteSummary">
                            <QuoteSummary_page/>
                        </Route>
                        <Route exact path="/RetrieveQuote">
                            <Page_retrieve_b/>
                        </Route>
                        <Route exact path="/RetrieveEmail">
                            <Page_retrieve_a/>
                        </Route>
                        <Route exact path="/RetrieveEmailList">
                            <Page_retrieve_a_2/>
                        </Route>
                        <Route exact path="/HomeInspection">
                            <HomeInspection_page/>
                        </Route>
                        <Route exact path="/saveforlater">
                            <Page_save/>
                        </Route>
                        {/*2nd route*/}
                        <Route exact path="/Inspection">
                            <New_Landing_Page />
                        </Route>
                        <Route exact path="/InspectionInfo">
                            <New_Landing_Page_2 />
                        </Route>
                        <Route exact path="/EstimateSummary">
                            <New_Landing_Page_3 />
                        </Route>
                        <Route exact path="/Blacksburg">
                            <Landing_Page_Blacksburg />
                        </Route>
                        <Route exact path="/BlacksburgInfo">
                            <Inspection_Info_Blacksburg />
                        </Route>
                        <Route exact path="/Botetourt">
                            <Landing_Page_Botetourt />
                        </Route>
                        <Route exact path="/BotetourtInfo">
                            <Inspection_Info_Botetourt />
                        </Route>
                        <Route exact path="/Culpeper">
                            <Landing_Page_Culpeper />
                        </Route>
                        <Route exact path="/CulpeperInfo">
                            <Inspection_Info_Culpeper />
                        </Route>
                        <Route exact path="/Harrisonburg">
                            <Landing_Page_Harrisonburg />
                        </Route>
                        <Route exact path="/HarrisonburgInfo">
                            <Inspection_Info_Harrisonburg />
                        </Route>
                    </Switch>
                </Layout>
            </Router>
        );
    }
}