import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { fetchQuote, updateQuote, createActivityQuoteDB } from '../API/Model';
import './PropertyInfo_page.css';
import Navigation from '../Overlays/Navigation';
import ClipLoader from "react-spinners/FadeLoader";
import '../styles.css';
import { Redirect } from 'react-router';

function PropertyInfo_page() {

    const page = 4;
    let history = useHistory();
    const [quote_information, setquote_information] = useState(0);
    const [updatedQuote, setupdatedQuote] = useState(false);
    const [submitLoading, setsubmitLoading] = useState(false);
    const [load, setonLoad] = useState(false);
    const [group_1_active, setgroup_1_active] = useState(null);
    const [group_2_active, setgroup_2_active] = useState(null);
    const [count_full, setCount_full] = useState(0);
    const [count_half, setCount_half] = useState(0);
    const [number_of_stories, set_number_of_stories] = useState(0);
    const [square_footage, set_square_footage] = useState(0);
    const [yearOfConstruction, set_yearOfConstruction] = useState(0);
    const [numberOfFamilies, set_numberOfFamilies] = useState(0);
    const [foundationType, set_foundationType] = useState(0);
    const [homeConstructionMaterial, set_homeConstructionMaterial] = useState(0);
    const [roofMaterial, set_RoofMaterial] = useState(0);
    const [cov_a_limit, set_cov_a_limit] = useState(0);
    const [general_cov_a_limit, set_general_cov_a_limit] = useState(0);

    const [newQuoteEmailSent, setNewQuoteEmailSent] = useState(false);

    const namedStormDedInd1Counties = ["Franklin City", "Suffolk City", "Isle of Wight", "Southampton",
        "Newport News City", "Gloucester", "Lancaster", "Mathews", "Northumberland", "York", "Essex", "Richmond", "Westmoreland", "Middlesex"];
    const namedStormDedInd2Counties = ["Hampton City", "Poquoson City", "Norfolk City", "Chesapeake City", "Portsmouth City"];
    const namedStormDedInd3Counties = ["Virginia Beach City", "Accomack", "Northampton"];

    let quote = {
        "ApplicationNumber": '"' + window.localStorage.getItem("quote_number") + '"',
        "Owner": "SalesPortal"
    }


    useEffect(() => {
        if (load === false) {
            async function fetchData() {
                const response = await fetchQuote(JSON.stringify(quote));
                setquote_information({
                    ExternalValidateBeanExist: "No",
                    DTOApplication: [
                        response
                    ]
                })
            }

            fetchData();

            fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/checkNewQuoteEmailSent', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Quote_Number: localStorage.getItem('quote_number'),
                })
            })
                .then(res => res.json())
                .then(result => {

                    if (result[0] === undefined && !newQuoteEmailSent) {
                        setNewQuoteEmailSent(true);
                        let formData = new FormData()
                        formData.append('email', JSON.parse(localStorage.getItem("personal_information")).email);
                        formData.append('pdf_url', null);
                        formData.append('subject', "New SalesPortal Quote - " + localStorage.getItem("quote_number"));
                        formData.append('type', "email_e");
                        formData.append('quote_number', localStorage.getItem("quote_number"));
                        let middle_name = "";
                        if (JSON.parse(localStorage.getItem("personal_information")).mi.length !== 0) {
                            middle_name = JSON.parse(localStorage.getItem("personal_information")).mi + " ";
                        }
                        formData.append('First_name', JSON.parse(localStorage.getItem("personal_information")).first_name + " " + middle_name + JSON.parse(localStorage.getItem("personal_information")).last_name);
                        formData.append('inspection_phone', JSON.parse(localStorage.getItem("personal_information")).phone);
                        formData.append('inspection_email', JSON.parse(localStorage.getItem("personal_information")).email);
                        let agency_name = "";
                        if (JSON.parse(localStorage.getItem("agency_information")) !== null &&
                            Object.keys(JSON.parse(localStorage.getItem("agency_information"))).length !== 0 &&
                            JSON.parse(localStorage.getItem("agency_information")).agency_name !== null &&
                            JSON.parse(localStorage.getItem("agency_information")).agency_name !== "Mutual Assurance Society of Virginia") {
                            agency_name = JSON.parse(localStorage.getItem("agency_information")).agency_name
                                + ";" + JSON.parse(localStorage.getItem("agency_information")).agency_phone
                                + ";" + JSON.parse(localStorage.getItem("agency_information")).agency_email
                                + ";" + JSON.parse(localStorage.getItem("agency_information")).agency_addres
                                + ";" + JSON.parse(localStorage.getItem("agency_information")).agency_city + " " + JSON.parse(localStorage.getItem("agency_information")).agency_zip;
                        }
                        formData.append('inspection_name', agency_name);

                        fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'EmailSender/sendEmail', {
                            method: 'POST',
                            body: formData
                        })
                            .then(res => res.json())
                            .then(result => {
                                createActivityQuoteDB("PropertyInfo", "EMAIL_SENT", "New Quote email sent");
                            })
                    }
                })
        }
    });

    useEffect(() => {

        if (quote_information.ExternalValidateBeanExist != undefined) {

            if (load === false) {

                createActivityQuoteDB("PropertyInfo", "PL", "PropertyInfo page loaded");
                localStorage.setItem('current_page', "PropertyInfo");



                if (quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberOfFamily !== undefined) {
                    set_numberOfFamilies(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberOfFamily);
                } else {
                    set_numberOfFamilies(1);
                }

                window.localStorage.setItem("quote_information", JSON.stringify({
                    firstYearPremium: quote_information.DTOApplication[0].DTOBasicPolicy[0].FinalPremiumAmt,
                    secondYearPremium: quote_information.DTOApplication[0].DTOLine[0].EstimatedSecondYearAssessment,
                    fiveYearPremium: quote_information.DTOApplication[0].DTOLine[0].EstimatedFiveYearAveAnnualCost,
                    num_bath_full: quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberBathrooms,
                    num_bath_part: quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberPartialBathrooms,
                    num_stories: quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumStories,
                    sqft: quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].SqFt,
                    build_year: quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].YearBuilt,
                    found_type: quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].FoundationType,
                    num_family: numberOfFamilies,
                }));


                //basic information
                try {
                    setCount_full(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberBathrooms);
                    setCount_half(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberPartialBathrooms);
                    set_number_of_stories(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumStories);
                    set_square_footage(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].SqFt);
                    set_yearOfConstruction(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].YearBuilt);
                    set_foundationType(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].FoundationType);
                    set_homeConstructionMaterial(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].ConstructionCd);
                    set_cov_a_limit(parseCurrency(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].CovALimit));
/*                    set_cov_a_limit(JSON.parse(localStorage.getItem("cov_a")));
*/
                    if (quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberOfFamily !== undefined) {
                        set_numberOfFamilies(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberOfFamily);
                    } else {
                        set_numberOfFamilies(1);
                    }


                } catch (e) {
                    console.log("Error with fetching basic information");
                }

                //roof construction materials
                try {

                    fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/selectQuoteInformation', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            Quote_Number: localStorage.getItem('quote_number'),
                        })
                    })
                        .then(res => res.json())
                        .then(result => {
                            if (result[1].toString() != "") {

                                setgroup_1_active(document.getElementsByName(result[1].toString())[0]);
                                document.getElementsByName(result[1].toString())[0].classList += " active_button";
                                set_homeConstructionMaterial(result[1].toString());

                            } else {

                                setgroup_1_active(document.getElementsByName(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].ConstructionCd)[0]);
                                document.getElementsByName(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].ConstructionCd)[0].classList += " active_button";
                                set_homeConstructionMaterial(document.getElementsByName(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].ConstructionCd)[0]);
                            }
                        },
                            (error) => {

                            })

                    if (quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].RoofCd === "Other") {
                        setgroup_2_active(document.getElementsByName("Other (Roof)")[0]);
                        document.getElementsByName("Other (Roof)")[0].classList += " active_button";
                        set_RoofMaterial(document.getElementsByName("Other (Roof)")[0]);
                    } else {
                        setgroup_2_active(document.getElementsByName(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].RoofCd)[0]);
                        document.getElementsByName(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].RoofCd)[0].classList += " active_button";
                        set_RoofMaterial(document.getElementsByName(quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].RoofCd)[0]);
                    }

                } catch (e) {
                    console.log("Error with roof/construction materials information information");
                }

                //agency information
                try {
                    window.localStorage.setItem("agency_addres", quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyAddress);
                    window.localStorage.setItem("agency_city", quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyCity);
                    window.localStorage.setItem("agency_state", quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").StateProvCd);
                    window.localStorage.setItem("agency_zip", quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyZip);
                    window.localStorage.setItem("agency_name", quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyName);
                    window.localStorage.setItem("agency_phone", quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyPhoneNumber);
                    window.localStorage.setItem("agency_email", quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyEmail);
                } catch (e) {
                    console.log("Error with agency information information");
                }

                setonLoad(true);
            }

        }

    }, [quote_information]);

    useEffect(() => {
        if (updatedQuote) {
            const timeout = setTimeout(() => {
               // let my_cov_a = cov_a_limit || general_cov_a_limit;
            try {

                quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumStories = number_of_stories;
                quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].SqFt = square_footage;
                quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].YearBuilt = yearOfConstruction;
                quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberBathrooms = count_full;
                quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberPartialBathrooms = count_half;
                quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberOfFamily = numberOfFamilies;
                quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].RoofCd = group_2_active.name;
                quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].FoundationType = foundationType;
                quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].CovALimit = cov_a_limit;

                const county = quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr[0].County;
                let namedStormDedInd = "0";
                if (namedStormDedInd1Counties.indexOf(county) > -1) {
                    namedStormDedInd = "1";
                } else if (namedStormDedInd2Counties.indexOf(county) > -1) {
                    namedStormDedInd = "2";
                } else if (namedStormDedInd3Counties.indexOf(county) > -1) {
                    namedStormDedInd = "3";
                }
                quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NamedStormDedInd = namedStormDedInd;

                if (group_1_active.name != "Masonry (Brick)" && group_1_active.name != "Masonry Veneer") {
                    quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].ConstructionCd = "Frame";
                } else {
                    quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].ConstructionCd = group_1_active.name;
                }


                if (group_2_active.name != "placeholder") {
                    if (group_2_active.name === "Other (Roof)") {
                        quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].RoofCd = "Other";
                    } else {
                        quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].RoofCd = group_2_active.name;
                    }
                }


            } catch (e) {

            }

            fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/updateQuoteInformation', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Quote_Number: localStorage.getItem('quote_number'),
                    Construction_Material: group_1_active.name,
                })
            })

            async function updateData() {
                createActivityQuoteDB("PropertyInfo", "API_REQ", "PropertyInfo update quote request");
                if (quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.length > 2) {
                    quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.splice(0, 1);
                }
                const response = await updateQuote(JSON.stringify(quote_information));
                createActivityQuoteDB("PropertyInfo", "API_RES", "PropertyInfo update quote response -" + response);
                if (response === "SUCCESS") {
                    setsubmitLoading(false);
                    history.push("/Coverages");
                }
            }

            updateData();
        }, 5000);
            return () => {
                clearTimeout(timeout);
            };
        }

    }, [updatedQuote]);



    const onNumberofStoriesChange = (e) => {
        set_number_of_stories(e.target.value);
    }
    const onSquareFootageChange = (e) => {
        const newValue = e.target.value.replace(/\D/g, '');
        if (newValue <= 99999) {
            set_square_footage(newValue);
        }
    }
    const onYearOfConstructionChange = (e) => {
        const newValue = e.target.value.replace(/\D/g, '');
        if (newValue <= 9999) {
            set_yearOfConstruction(newValue);
        }
    }
    const onnumberOfFamiliesChange = (e) => {
        set_numberOfFamilies(e.target.value);
    }
    const onnfoundationTypeChange = (e) => {
        set_foundationType(e.target.value);
    }

    function formatCurrency(num) {
        return num ? "$" + num.toLocaleString("en-US", { minimumFractionDigits: 0 }) : "";
    }

    function parseCurrency(str) {
        return str ? parseInt(str.replace(/\D/g, "")) : null;
    }

    function onCovALimitChange(e) {
        const newValue = parseCurrency(e.target.value.replace(/,/g, ''));
        if (!newValue || newValue <= 99999999) {
            set_cov_a_limit(newValue);
        }
    }

    function handleBlur(e) {
        if (!cov_a_limit || cov_a_limit < 100000) {
            set_cov_a_limit(100000);
        }
    }

    function decrementCount(type) {

        if (type === "full") {
            if (count_full > 0)
                setCount_full(prevCount => parseInt(prevCount) - 1);
        } else if (type === "half") {
            if (count_half > 0)
                setCount_half(prevCount => parseInt(prevCount) - 1);
        }
    }
    function incrementCount(type) {
        if (type === "full") {
            if (count_full < 999) {
                setCount_full(prevCount => parseInt(prevCount) + 1);
            }
        } else if (type === "half") {
            if (count_half < 999) {
                setCount_half(prevCount => parseInt(prevCount) + 1);
            }
        }
    }

    function changeState(event) {

        if (event.target.id === "group_1") {
            if (group_1_active !== null) {
                group_1_active.classList = group_1_active.classList[0] + " " + group_1_active.classList[1];

            }
            setgroup_1_active(event.target);
            event.target.classList = event.target.classList + " active_button";
            set_homeConstructionMaterial(event.target.name);

        } else if (event.target.id === "group_2") {
            if (group_2_active !== null) {
                group_2_active.classList = group_2_active.classList[0] + " " + group_2_active.classList[1];

            }
            setgroup_2_active(event.target);
            event.target.classList = event.target.classList + " active_button";
            if (event.target.name === "Other (Roof)") {
                set_RoofMaterial("Other");
            } else {
                set_RoofMaterial(event.target.name);
            }
        }

    }

    function onButtonClick() {
        createActivityQuoteDB("PropertyInfo", "NAV", "Continue button");
        setsubmitLoading(true);
        setupdatedQuote(true);

    }
    function onBackButtonClick() {
        createActivityQuoteDB("PropertyInfo", "NAV", "Back button");
    }

    if (localStorage.getItem('status') === "COMP") {
        return <Redirect push to={'/QuoteSummary'} />;
    } else {
        return (
            <div>
                <Navigation page={page} progress={3} />
                {
                    submitLoading ?
                        <div className="loader">
                            <div className="loading_icon" style={{ marginBottom: "80px", marginTop: "0" }}>
                                <ClipLoader color={"#003550"} loading={submitLoading} height={20} width={5} radius={10} />
                            </div>
                            <div className="loader_title">
                                <p>Calculating your estimate</p>
                            </div>
                            <div className="loader_text_wrapper">
                                <p className="loader_text1">Did you know?</p><p className="loader_text2">As part of the process, Mutual Assurance<br />conducts a free inspection of your home to<br />ensure you have the right coverage.</p>
                            </div>
                        </div>
                        :
/*                        <div className={load === true ? {} : "d-none"}>
*/                        <div className="propertyinfo_wrapper">

                            <div className="wrapper_p4">

                                <p className="title_div">Property Information</p>
                                <div className="contentBox_p4">
                                    <div className="p4_c1_r0 home_value">
                                        <p className="label_p4 no_wrap">Your Estimated Home Value</p>
                                        <input
                                            className="inp_p3_1"
                                            type="text"
                                            value={formatCurrency(cov_a_limit)}
                                            onChange={onCovALimitChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    <div className="p4_c1_r1">
                                        <p className="label_p4"># of Stories</p>
                                        <select className="selector" name="stories" id="stories" size="1" value={number_of_stories} onChange={onNumberofStoriesChange}>
                                            <option value="0" defaultValue>Please select</option>
                                            <option value="1">1</option>
                                            <option value="1.5">1.5</option>
                                            <option value="2">2</option>
                                            <option value="2.5">2.5</option>
                                            <option value="3">3</option>
                                            <option value="Bi-Level">Bi-Level</option>
                                            <option value="Other">Other</option>
                                            <option value="SplitLevel">Split Level</option>
                                            <option value="Tri-Level">Tri-level</option>
                                        </select>

                                    </div>
                                    <div className="p4_c2_r1">
                                        <p className="label_p4">Square Footage</p>
                                        <div className="info_icon"
                                            data-tooltip="This includes the finished square footage of the home above ground, not including finished basements.">
                                        </div>

                                        <input
                                            name="SquareFoot"
                                            className="inp_p3_1"
                                            type="SquareFoot" id="SquareFoot" placeholder="Enter #" value={square_footage}
                                            onChange={onSquareFootageChange}
                                        />
                                    </div>
                                    <div className="p4_c3_r1">
                                        <p className="label_p4">Year of Construction </p>
                                        <input
                                            name="YearofCons"
                                            className="inp_p3_1"
                                            type="YearofCons" id="YearofCons" placeholder="1900" value={yearOfConstruction}
                                            onChange={onYearOfConstructionChange}
                                        />

                                    </div>
                                    <div className="p4_c1_r2">
                                        <p className="label_p4"># of Bathrooms </p>
                                        <br />
                                        <button className="p4_button" onClick={() => decrementCount('full')}>-</button>
                                        <span className="minDivider">  {count_full}  </span>

                                        <button className="p4_button" onClick={() => incrementCount('full')}>+</button>
                                        <p className="p4_mini_label">Full Baths </p>

                                    </div>
                                    <div className="p4_c2_r2">
                                        <br className="mobile_br" /><br className="mobile_br" />

                                        <button className="p4_button" onClick={() => decrementCount('half')}>-</button>
                                        <span className="minDivider">  {count_half}  </span>

                                        <button className="p4_button" onClick={() => incrementCount('half')}>+</button>
                                        <p className="p4_mini_label">Half Baths </p>


                                    </div>
                                    <div className="p4_c3_r2">
                                        <p className="label_p4"># of Families</p>
                                        <select className="selector" name="families" id="families" size="1" value={numberOfFamilies} onChange={onnumberOfFamiliesChange}>
                                            <option value="0" defaultValue>Please select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4+">4+</option>
                                        </select>
                                    </div>

                                    <div className="p4_c1_r3">

                                        <p className="label_p4">Home Construction Material</p>

                                        <div className="info_icon property_icon"
                                            data-tooltip="The primary construction material on the exterior walls of your home."></div>

                                        <br />
                                        <div className="grouping">
                                            <button className="circle_button wood" id="group_1" onClick={changeState} name="Frame" />
                                            <p className="p4_button_under_label">Wood</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button brick" id="group_1" onClick={changeState} name="Masonry (Brick)" />
                                            <p className="p4_button_under_label">Brick/Stone</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button brick_veneer" id="group_1" onClick={changeState} name="Masonry Veneer" />
                                            <p className="p4_button_under_label">Brick/Stone Veneer</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button vinyl" id="group_1" onClick={changeState} name="Vinyl" />
                                            <p className="p4_button_under_label">Vinyl</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button stucco" id="group_1" onClick={changeState} name="Stucco" />
                                            <p className="p4_button_under_label">Stucco</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button metal_construction" id="group_1" onClick={changeState} name="Metal" />
                                            <p className="p4_button_under_label">Metal</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button fiber_cement" id="group_1" onClick={changeState} name="Fiber Cement" />
                                            <p className="p4_button_under_label">Fiber Cement</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button other" id="group_1" onClick={changeState} name="Other" />
                                            <p className="p4_button_under_label">Other</p>
                                        </div>
                                    </div>
                                    <div className="p4_c1_r4">

                                        <span className="label_p4">Roof Material</span>
                                        <div className="info_icon" data-tooltip="The primary material making up the majority of your roof surface."></div>
                                        <br />
                                        <div className="grouping">
                                            <button className="circle_button asphalt" id="group_2" onClick={changeState} name="Asphalt (Composition)" />
                                            <p className="p4_button_under_label">Asphalt</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button slate" id="group_2" onClick={changeState} name="Slate" />
                                            <p className="p4_button_under_label">Slate</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button wood_shingle" id="group_2" onClick={changeState} name="Wood Shingle" />
                                            <p className="p4_button_under_label">Wood Shingle</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button metal" id="group_2" onClick={changeState} name="Tin (Includes Copper and Steel)" />
                                            <p className="p4_button_under_label">Metal</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button tile" id="group_2" onClick={changeState} name="Tile (Clay and Hendrix)" />
                                            <p className="p4_button_under_label">Tile</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button rubber" id="group_2" onClick={changeState} name="Tar and Gravel (Includes built up or rubber membrane)" />
                                            <p className="p4_button_under_label">Rubber Lining</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button tar_gravel" id="group_2" onClick={changeState} name="Tar and Gravel (Includes built up or rubber membrane)" />
                                            <p className="p4_button_under_label">Tar and Gravel</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button asbestos" id="group_2" onClick={changeState} name="Asbestos" />
                                            <p className="p4_button_under_label">Asbestos</p>
                                        </div>
                                        <div className="grouping">
                                            <button className="circle_button other" id="group_2" onClick={changeState} name="Other (Roof)" />
                                            <p className="p4_button_under_label">Other</p>
                                        </div>
                                    </div>
                                    <div className="p4_c1_r5">

                                        <p className="label_p4">Foundation Type</p>
                                        <div className="info_icon" data-tooltip="This is the type of foundation on which your home sits."></div>
                                        <br />
                                        <select className="selector" name="foundation" id="foundation" size="1" value={foundationType} onChange={onnfoundationTypeChange}>
                                            <option value="0" defaultValue>Please select</option>
                                            <option value="CrawlSpace">Crawl Space</option>
                                            <option value="Slab">Slab</option>
                                            <option value="BasementLessThan25%Finished"> Basement 0-24% Finished</option>
                                            <option value="Basement25-49%Finished">Basement 25-49% Finished</option>
                                            <option value="Basement50-74%Finished">Basement 50-74% Finished</option>
                                            <option value="Basement75-99%Finished">Basement 75-99% Finished</option>
                                            <option value="Basement100%Finished">Basement 100% Finished</option>
                                            <option value="Other">Other</option>
                                        </select>

                                    </div>
                                    <div className="footer">
                                        <div className="btn_p4_wrapper">
                                            <Link to={{
                                                pathname: "/YourInfo",

                                            }}>
                                                <p className="btn_p4_2" onClick={onBackButtonClick}>&lt;<span>Back</span></p>
                                            </Link>
                                        <button type="submit" className="btn_p4" onClick={onButtonClick}>Continue</button>
                                        </div>
                                        <b>
                                            <p className="footer_p4_1"> Questions? (800) 648-1794</p>
                                            <p className="footer_p4_1_5"> <a href="mailto: service@mutual-assurance.com">service@mutual-assurance.com</a></p>
                                            <p className="footer_p4_2">(Monday to Friday 8am – 4:30pm)</p>
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

export default PropertyInfo_page