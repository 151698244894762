import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Page_retrieve_a.css';
import { retrieveQuote, createActivityQuoteDB } from '../API/Model';
import Alert from '../Additional/Alert';
import useWindowDimensions from '../Additional/ScreenProps';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
// qutoe_number

function Page_retrieve_b() {


    let history = useHistory();
    const [quoteNumber, setquoteNumber] = useState('');
    const [lastName, setlastName] = useState('');
    const [zipcode, setzipcode] = useState('');
    const [modalError, setmodalError] = useState(false);
    const [errorMessage, seterrorMessage] = useState('');
    const { height, width } = useWindowDimensions();
    const [ errorHeight, setErrorHeight ] = useState(65);
    const [load, setonLoad] = useState(false);
    const [fetchStatus, setfetchStatus] = useState(null);
    let quote = {
        QuoteNumber: quoteNumber,
        LastName: lastName,
        ZipCode: zipcode,
        SearchCriteria: "QuoteNumber"
    }



    useEffect(() => {

        if (load === false) {

            localStorage.clear();
            async function getIP() {
                const res = await axios.get('https://geolocation-db.com/json/')
                localStorage.setItem('user_ip', res.data.IPv4);
            }
            getIP();
            localStorage.setItem('session_id', uuidv4());

            createActivityQuoteDB("RetriveQuote", "PL", "RetriveQuote page loaded");
            setonLoad(true);
        }

    });

    const onquoteNumberChange = (e) => {
        setquoteNumber(e.target.value);
    }
    const onlastNameChange = (e) => {
        setlastName(e.target.value);
    }
    const onzipcodeChange = (e) => {
        setzipcode(e.target.value);
    }
    const onModalClose = (e) => {
        setmodalError(false);
    }

    async function onButtonClick() {
        setmodalError(false);

        async function fetchData() {
            const response = await retrieveQuote(JSON.stringify(quote));
            createActivityQuoteDB("RetriveQuote", "RECALL", "RetriveQuote quote recall");
            if (response !== "ERROR") {
                setfetchStatus(true);
            } else {

                setErrorHeight(65);
                seterrorMessage("This estimate can not be recovered.");
                setmodalError(true);
            }
        }

        await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'portal/checkCompletedQuote', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Quote_Number: quoteNumber,
            })
        }).then(res => res.json())
            .then(result => {

                if (result[0] === undefined) {
                    localStorage.setItem('status', "RET");
                    fetchData();
                } else {
                    if (width < 758) {
                        setErrorHeight(110);
                    } else {
                        setErrorHeight(85);
                    }

                    seterrorMessage("This estimate has already been submitted and cannot be updated. Please contact customer service at 800-648-1794.");
                    setmodalError(true);
                }
            })

    }


    useEffect(() => {

        if (fetchStatus != null) {
            setfetchStatus(null);
            fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'portal/selectLastPage', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Quote_Number: window.localStorage.getItem("quote_number"),
                })
            }).then((response) => response.json())
                .then((responseJson) => {

                    if (responseJson !== null && responseJson !== undefined && responseJson.length > 0) {
                        
                        if (responseJson[0].toString() != null) {
                            history.push("/" + responseJson[0].toString());
                        } else {
                            history.push("/AdditionalInfo");
                        }
                    } else {

                        if (width < 758) {
                            setErrorHeight(110);
                        } else {
                            setErrorHeight(85);
                        }
                        seterrorMessage("We were unable to obtain RCE information for your estimate and it can’t be retrieved.  Please contact customer service at 800-648-1794.");
                        setmodalError(true);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [fetchStatus]);

    return (

        <div className="retrieve_offset">


            {
                width < 758 &&

                <Alert status={modalError} message={errorMessage} width={width - 30} height={errorHeight} margin={0} color={"#ffb3b3"} />

            }
            {
                width > 758 &&

                <Alert status={modalError} message={errorMessage} width={670} height={errorHeight} margin={220} color={"#ffb3b3"} />
            }



            <p className="retrieve_title">
                Let's Retrieve Your Homeowners Estimate</p>

            <div className="retrieve_box">

                <p className="retrieve_label bol">
                    Pull up your estimate by
                </p>
                <p className="retrieve_label bol smal">
                    <Link to="/RetrieveEmail">
                        <span className="active_link"> Email</span>
                    </Link>
                    <span> </span>
                    or Estimate Number

                </p>

                <p className="retrieve_label">
                    Estimate Number</p>
                <input
                    name="quote"
                    className="inp_retrieve"
                    type="text" id="Quote" placeholder="Estimate number" value={quoteNumber} onChange={onquoteNumberChange}
                />


                <p className="retrieve_label">
                    Last Name</p>

                <input
                    name="Last"
                    className="inp_retrieve"
                    type="text" id="lname" placeholder="Last" value={lastName} onChange={onlastNameChange}
                />



                <p className="retrieve_label">
                    Zip Code</p>
                <input
                    name="Zip"
                    className="inp_retrieve"
                    type="text" id="Zip" placeholder="Zip Code" value={zipcode} onChange={onzipcodeChange}
                />



                <button type="submit" className="retrieve_button" onClick={onButtonClick} >Retrieve</button>

            </div>

        </div>
    );
}

export default Page_retrieve_b
