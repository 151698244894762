import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import Navigation from '../Overlays/Navigation';
import { fetchQuote, updateQuote, createActivityQuoteDB } from '../API/Model';
import useWindowDimensions from '../Additional/ScreenProps';
import './AdditionalInfo_page.css';
import ClipLoader from "react-spinners/FadeLoader";
import Alert from '../Additional/Alert';
import '../styles.css';
import { Redirect } from 'react-router';


function AdditionalInfo_page() {


    let fetch_quote = {
        "ApplicationNumber": '"' + window.localStorage.getItem("quote_number") + '"',
        "Owner": "SalesPortal"
    }

    const page = 6;
    let history = useHistory();
    let radio_options = ["YES", "NO"];
    const { height, width } = useWindowDimensions();
    const [load, setonLoad] = useState(false);
    const [submitLoading, setsubmitLoading] = useState(false);
    const [updateStatus, set_updateStatus] = useState(false);
    const [quote_information, setquote_information] = useState(0);
    const [alert, setAlert] = useState({
        alert_state: false,
        alert_type: 0,
        error_message: "error",
        alert_height: 65,
        alert_color: "#ffb3b3",
    });

    const [member_state, member_setState] = useState("NO");
    const [primary_residence, primary_residence_setState] = useState(null);


    const [existingCustomer, setExistingCustomer] = useState({
        pool_roof: "",
        exterior_walls_asbestos: "",
        dog_state: "",
        dogCount: "",
        trampoline_treehouse_skateboard: "",
        pool_state: "",
        pool_locking_gate: "",
        pool_diving_board: "",
        floors_wather_based_radiant: "",
        central_heating: "",
        buissnes_operations_premises: "",
        airbnb_or_another: "",
    });

    const [noExistingCustomer, setNoExistingCustomer] = useState({

        losses_claims_last_3: "",
        weather_related_loss: "",
        single_family_dwelling_occupied: "",
        property_renovated: "",
        roof_asbestos_rubber_membrane_tar_gravel: "",
        exterior_walls_asbestos: "",
        dog_setState: "",
        dogCount: "",
        trampoline_treehouse_skateboard: "",
        pool_state: "",
        pool_locking_gate: "",
        pool_diving_board: "",
        bankruptcies_foreclosures: "",
        floors_wather_based_radiant: "",
        central_heating: "",
        buissnes_operations_premises: "",
        over_70_years: "",
        major_systems: "",
        insurance_in_place_last_3: "",
        first_homeowner: "",
        national_historic: "",
        airbnb_or_another: "",
    });



    useEffect(() => {

        if (load === false) {
            async function fetchData() {

                const response = await fetchQuote(JSON.stringify(fetch_quote));
                setquote_information({
                    ExternalValidateBeanExist: "No",
                    DTOApplication: [
                        response
                    ]
                })
            }

            fetchData();
        }

    });


    useEffect(() => {

        if (quote_information.ExternalValidateBeanExist != undefined) {

            if (load === false) {
                createActivityQuoteDB("AdditionalInfo", "PL", "AdditionalInfo page loaded");
                localStorage.setItem('current_page', "AdditionalInfo");
                try {

                    fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'portal/selectQuoteInformation', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            Quote_Number: localStorage.getItem('quote_number'),
                        })
                    })
                        .then(res => res.json())
                        .then(result => {
                            if (result[0].toString() != "")
                                primary_residence_setState(result[0].toString());
                        },
                            (error) => {

                            })


/*                    if (quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "ExistingCustomer").Value)
                        member_setState(quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "ExistingCustomer").Value);*/
                    if (localStorage.getItem("existing_customer"))
                        member_setState(localStorage.getItem("existing_customer"));

                    setExistingCustomer({
                        ...existingCustomer,
                        pool_roof: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "RoofTypeExistingCustomer").Value,
                        exterior_walls_asbestos: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "ExteriorWallsAsbestosExistingCustomer").Value,
                        dog_state: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "DogsExistingCustomer").Value,
                        dogCount: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "numberOfDogsExistingCustomer").Value,
                        trampoline_treehouse_skateboard: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "trampolineTreehouseSkateRampExistingCustomer").Value,
                        pool_state: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "PoolExistingCustomer").Value,
                        pool_locking_gate: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "lockedFenceExistingCustomer").Value,
                        pool_diving_board: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "DivingBoardExistingCustomer").Value,
                        floors_wather_based_radiant: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "FloorRadiantHeatExistingCustomer").Value,
                        central_heating: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "CentralHeatExistingCustomer").Value,
                        buissnes_operations_premises: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "BusinessInHomeExistingCustomer").Value,
                        airbnb_or_another: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "HomeSharingExistingCustomer").Value,
                    })
                
                    setNoExistingCustomer({
                        ...noExistingCustomer,
                        losses_claims_last_3: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "LossesClaimsinLast3YearsNewCustomer").Value,
                        weather_related_loss: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "WeatherRelatedLossUnder5000").Value,
                        single_family_dwelling_occupied: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "SingleFamilyDwelling").Value,
                        property_renovated: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "RenovatedProperty").Value,
                        roof_asbestos_rubber_membrane_tar_gravel: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "RoofType").Value,
                        exterior_walls_asbestos: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "ExteriorWallsAsbestos").Value,
                        dog_state: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "Dogs").Value,
                        dogCount: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "numberOfDogs").Value,
                        trampoline_treehouse_skateboard: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "trampolineTreehouseSkateRamp").Value,
                        pool_state: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "PoolLockedFencedGate").Value,
                        pool_locking_gate: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "lockedFence").Value,
                        pool_diving_board: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "DivingBoard").Value,
                        bankruptcies_foreclosures: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "BankruptcyForeclosures").Value,
                        floors_wather_based_radiant: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "FloorRadiantHeat").Value,
                        central_heating: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "CentralHeat").Value,
                        buissnes_operations_premises: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "BusinessInHome").Value,
                        over_70_years: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "HomeAge").Value,
                        major_systems: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "MajorSystemsUpdated").Value,
                        insurance_in_place_last_3: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "InsuranceInPlaceLast3YearsNewCustomer").Value,
                        first_homeowner: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "FirstTimeHomeowner").Value,
                        national_historic: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "NatlHistoryRegistry").Value,
                        airbnb_or_another: quote_information.DTOApplication[0].QuestionReplies[0].QuestionReply.find((e) => e.Name === "HomeSharing").Value,
                    });
                
                } catch (e) {
                  
                }


                setonLoad(true);

            }

        }

    }, [quote_information]);



/*    function validateForm() {
        let status = 0;

        if (alert.alert_type === 3) { // soft stop error (1 time)
            status = 0;
        } else { // hard stop error

            if (primary_residence === "NO" && member_state === "NO") {
                status = 3;
            }

            if (member_state === "YES") {

                if (existingCustomer.trampoline_treehouse_skateboard === "YES" ||
                    existingCustomer.buissnes_operations_premises === "YES" ||
                    existingCustomer.airbnb_or_another === "YES" ||
                    existingCustomer.exterior_walls_asbestos === "YES" ||
                    existingCustomer.floors_wather_based_radiant === "YES" ||
                    existingCustomer.central_heating === "NO") {
                    //status = 2;
                    status = 3;
                }

                if (existingCustomer.dog_state === "YES" &&
                    (
                        existingCustomer.dogCount !== undefined &&
                        existingCustomer.dogCount.length !== 0 &&
                        parseInt(existingCustomer.dogCount) > 4
                    )) {
                    status = 3;
                }

                if (existingCustomer.pool_state === "YES" &&
                    (
                        existingCustomer.pool_locking_gate === "NO" ||
                        existingCustomer.pool_diving_board === "YES"
                    )) {
                    status = 3;
                }

                if (existingCustomer.pool_roof === undefined || existingCustomer.pool_roof.length === 0) {
                    status = 1;
                } else if (existingCustomer.exterior_walls_asbestos === undefined || existingCustomer.exterior_walls_asbestos.length === 0) {
                    status = 1;
                } else if (existingCustomer.dog_state === undefined || existingCustomer.dog_state.length === 0) {
                    status = 1;
                } else if (existingCustomer.buissnes_operations_premises === undefined || existingCustomer.buissnes_operations_premises.length === 0) {
                    status = 1;
                } else if (existingCustomer.pool_state === undefined || existingCustomer.pool_state.length === 0) {
                    status = 1;
                } else if (existingCustomer.floors_wather_based_radiant === undefined || existingCustomer.floors_wather_based_radiant.length === 0) {
                    status = 1;
                } else if (existingCustomer.central_heating === undefined || existingCustomer.central_heating.length === 0) {
                    status = 1;
                } else if (existingCustomer.airbnb_or_another === undefined || existingCustomer.airbnb_or_another.length === 0) {
                    status = 1;
                } else if (primary_residence === undefined || primary_residence.length === 0) {
                    status = 1;
                }

                if (existingCustomer.dog_state === "YES" &&
                    (
                        existingCustomer.dogCount === undefined ||
                        existingCustomer.dogCount.length === 0
                    )) {
                    status = 1;
                }

                if (existingCustomer.pool_state === "YES" &&
                    (
                        existingCustomer.pool_locking_gate === undefined ||
                        existingCustomer.pool_locking_gate.length === 0 ||
                        existingCustomer.pool_diving_board === undefined ||
                        existingCustomer.pool_diving_board.length === 0
                    )) {
                    status = 1;
                }
            }
            else {

                if (noExistingCustomer.property_renovated === "YES" ||
                    noExistingCustomer.trampoline_treehouse_skateboard === "YES" ||
                    noExistingCustomer.bankruptcies_foreclosures === "YES" ||
                    noExistingCustomer.buissnes_operations_premises === "YES" ||
                    noExistingCustomer.airbnb_or_another === "YES" || 
                    noExistingCustomer.single_family_dwelling_occupied === "NO" ||
                    noExistingCustomer.exterior_walls_asbestos === "YES" ||
                    noExistingCustomer.floors_wather_based_radiant === "YES" ||
                    noExistingCustomer.central_heating === "NO") {

                    //status = 2;
                    
                    = 3;
                }

                if (noExistingCustomer.dog_state === "YES" &&
                    (
                        noExistingCustomer.dogCount !== undefined &&
                        noExistingCustomer.dogCount.length !== 0 &&
                        parseInt(noExistingCustomer.dogCount) > 4
                    )) {
                    status = 3;
                }

                if (noExistingCustomer.losses_claims_last_3 === "YES" &&
                    noExistingCustomer.weather_related_loss === "NO") {
                    status = 3;
                }

                if (noExistingCustomer.pool_state === "YES" &&
                    (
                        noExistingCustomer.pool_locking_gate === "NO" ||
                        noExistingCustomer.pool_diving_board === "YES"
                    )) {
                    status = 3;
                }

                if (noExistingCustomer.over_70_years === "YES" &&
                    (
                        noExistingCustomer.national_historic === "YES" ||
                        noExistingCustomer.major_systems === "NO"
                    )) {
                    status = 3;
                }

                if (noExistingCustomer.insurance_in_place_last_3 === "NO" &&
                    noExistingCustomer.first_homeowner === "NO") {
                    status = 3;
                }


                if (noExistingCustomer.losses_claims_last_3 === undefined || noExistingCustomer.losses_claims_last_3.length === 0) {
                    status = 1;
                } else if (noExistingCustomer.single_family_dwelling_occupied === undefined || noExistingCustomer.single_family_dwelling_occupied.length === 0) {
                    status = 1;
                } else if (noExistingCustomer.property_renovated === undefined || noExistingCustomer.property_renovated.length === 0) {
                    status = 1;
                } else if (noExistingCustomer.roof_asbestos_rubber_membrane_tar_gravel === undefined || noExistingCustomer.roof_asbestos_rubber_membrane_tar_gravel.length === 0) {
                    status = 1;
                } else if (noExistingCustomer.exterior_walls_asbestos === undefined || noExistingCustomer.exterior_walls_asbestos.length === 0) {
                    status = 1;
                } else if (noExistingCustomer.dog_state === undefined || noExistingCustomer.dog_state.length === 0) {
                    status = 1;
                } else if (noExistingCustomer.buissnes_operations_premises === undefined || noExistingCustomer.buissnes_operations_premises.length === 0) {
                    status = 1;
                } else if (noExistingCustomer.pool_state === undefined || noExistingCustomer.pool_state.length === 0) {
                    status = 1;
                } else if (noExistingCustomer.bankruptcies_foreclosures === undefined || noExistingCustomer.bankruptcies_foreclosures.length === 0) {
                    status = 1;
                } else if (noExistingCustomer.floors_wather_based_radiant === undefined || noExistingCustomer.floors_wather_based_radiant.length === 0) {
                    status = 1;
                } else if (noExistingCustomer.central_heating === undefined || noExistingCustomer.central_heating.length === 0) {
                    status = 1;
                } else if (noExistingCustomer.over_70_years === undefined || noExistingCustomer.over_70_years.length === 0) {
                    status = 1;
                } else if (noExistingCustomer.insurance_in_place_last_3 === undefined || noExistingCustomer.insurance_in_place_last_3.length === 0) {
                    status = 1;
                } else if (noExistingCustomer.airbnb_or_another === undefined || noExistingCustomer.airbnb_or_another.length === 0) {
                    status = 1;
                } else if (primary_residence === undefined || primary_residence.length === 0) {
                    status = 1;
                }

                if (noExistingCustomer.losses_claims_last_3 === "YES" &&
                    (
                        noExistingCustomer.weather_related_loss === undefined ||
                        noExistingCustomer.weather_related_loss.length === 0
                    )) {
                    status = 1;
                }
               
                if (noExistingCustomer.dog_state === "YES" &&
                    (
                        noExistingCustomer.dogCount === undefined ||
                        noExistingCustomer.dogCount.length === 0
                    )) {
                    status = 1;
                }

                if (noExistingCustomer.pool_state === "YES" &&
                    (
                        noExistingCustomer.pool_locking_gate === undefined ||
                        noExistingCustomer.pool_locking_gate.length === 0 ||
                        noExistingCustomer.pool_diving_board === undefined ||
                        noExistingCustomer.pool_diving_board.length === 0
                    )) {
                    status = 1;
                }

                if (noExistingCustomer.over_70_years === "YES" &&
                    (
                        noExistingCustomer.national_historic === undefined ||
                        noExistingCustomer.national_historic.length === 0
                    )) {
                    status = 1;
                }

                if (noExistingCustomer.national_historic !== undefined &&
                    noExistingCustomer.national_historic === "NO" &&
                    (
                        noExistingCustomer.major_systems === undefined ||
                        noExistingCustomer.major_systems.length === 0
                    )) {
                    status = 1;
                }

                if (noExistingCustomer.insurance_in_place_last_3 !== undefined &&
                    noExistingCustomer.insurance_in_place_last_3 === "NO" &&
                    (
                        noExistingCustomer.first_homeowner === undefined ||
                        noExistingCustomer.first_homeowner.length === 0
                    )) {
                    status = 1;
                }
            }
        }
        return status;
    }*/

    useEffect(() => {


        if (updateStatus) {

            //let status = validateForm();

            //if (status === 0) {

                let UpadatedQuestionReplies = {
                    QuestionReply: [
                        {
                            Name: "ExistingCustomer",
                            Value: member_state
                        },
                        {
                            Name: "RoofTypeExistingCustomer",
                            Value: existingCustomer.pool_roof,
                        },
                        {
                            Name: "ExteriorWallsAsbestosExistingCustomer",
                            Value: existingCustomer.exterior_walls_asbestos,
                        },
                        {
                            Name: "DogsExistingCustomer",
                            Value: existingCustomer.dog_state,
                        },
                        {
                            Name: "numberOfDogsExistingCustomer",
                            Value: existingCustomer.dogCount,
                            VisibleInd: "Yes"
                        },
                        {
                            Name: "BusinessInHomeExistingCustomer",
                            Value: existingCustomer.buissnes_operations_premises,
                        },
                        {
                            Name: "trampolineTreehouseSkateRampExistingCustomer",
                            Value: existingCustomer.trampoline_treehouse_skateboard,
                        },
                        {
                            Name: "PoolExistingCustomer",
                            Value: existingCustomer.pool_state,
                            VisibleInd: "Yes"
                        },
                        {
                            Name: "lockedFenceExistingCustomer",
                            Value: existingCustomer.pool_locking_gate,
                        },
                        {
                            Name: "DivingBoardExistingCustomer",
                            Value: existingCustomer.pool_diving_board,
                        },
                        {
                            Name: "FloorRadiantHeatExistingCustomer",
                            Value: existingCustomer.floors_wather_based_radiant,
                        },
                        {
                            Name: "CentralHeatExistingCustomer",
                            Value: existingCustomer.central_heating,
                        },
                        {
                            Name: "HomeSharingExistingCustomer",
                            Value: existingCustomer.airbnb_or_another,
                        },

                        {
                            Name: "LossesClaimsinLast3YearsNewCustomer",
                            Value: noExistingCustomer.losses_claims_last_3,
                        },
                        {
                            Name: "SingleFamilyDwelling",
                            Value: noExistingCustomer.single_family_dwelling_occupied,
                        },
                        {
                            Name: "RenovatedProperty",
                            Value: noExistingCustomer.property_renovated
                        },
                        {
                            Name: "RoofType",
                            Value: noExistingCustomer.roof_asbestos_rubber_membrane_tar_gravel,
                        },
                        {
                            Name: "ExteriorWallsAsbestos",
                            Value: noExistingCustomer.exterior_walls_asbestos,
                        },
                        {
                            Name: "Dogs",
                            Value: noExistingCustomer.dog_state,
                        },
                        {
                            Name: "numberOfDogs",
                            Value: noExistingCustomer.dogCount,
                            VisibleInd: "Yes"
                        },
                        {
                            Name: "BusinessInHome",
                            Value: noExistingCustomer.buissnes_operations_premises,
                        },

                        {
                            Name: "trampolineTreehouseSkateRamp",
                            Value: noExistingCustomer.trampoline_treehouse_skateboard,
                        },
                        {
                            Name: "PoolLockedFencedGate", // Pool ???
                            Value: noExistingCustomer.pool_state,
                            VisibleInd: "Yes"
                        },
                        {
                            Name: "lockedFence",
                            Value: noExistingCustomer.pool_locking_gate,
                        },

                        {
                            Name: "DivingBoard",
                            Value: noExistingCustomer.pool_diving_board,
                        },
                        {
                            Name: "BankruptcyForeclosures",
                            Value: noExistingCustomer.bankruptcies_foreclosures,
                        },
                        {
                            Name: "FloorRadiantHeat",
                            Value: noExistingCustomer.floors_wather_based_radiant,
                        },
                        {
                            Name: "CentralHeat",
                            Value: noExistingCustomer.central_heating,
                        },
                        {
                            Name: "HomeAge",
                            Value: noExistingCustomer.over_70_years,
                        },
                        {
                            Name: "InsuranceInPlaceLast3YearsNewCustomer",
                            Value: noExistingCustomer.insurance_in_place_last_3,
                        },
                        {
                            Name: "WeatherRelatedLossUnder5000",
                            Value: noExistingCustomer.weather_related_loss,
                        },
                        {
                            Name: "NatlHistoryRegistry",
                            Value: noExistingCustomer.national_historic,
                        },
                        {
                            Name: "MajorSystemsUpdated",
                            Value: noExistingCustomer.major_systems,
                        },
                        {
                            Name: "FirstTimeHomeowner",
                            Value: noExistingCustomer.first_homeowner,
                        },
                        {
                            Name: "HomeSharing",
                            Value: noExistingCustomer.airbnb_or_another,
                        }

                    ]
                }


                fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'portal/updateQuoteInformation', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Quote_Number: localStorage.getItem('quote_number'),
                        Primary_Residence: primary_residence,
                    })
                })

                quote_information.DTOApplication[0].QuestionReplies = UpadatedQuestionReplies;

                if (member_state === "YES") {
                    quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].PoolInd = existingCustomer.pool_state;
                    quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberOfDogs = existingCustomer.dogCount;
                    quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].ExistingMemberDiscount = "Yes";
                } else {
                    quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].PoolInd = noExistingCustomer.pool_state;
                    quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberOfDogs = noExistingCustomer.dogCount;
                    quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].ExistingMemberDiscount = "No";
                }

                if (primary_residence === "NO") {
                    quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].Description = "Secondary Dwelling";
                } else {
                    quote_information.DTOApplication[0].DTOLine[0].DTORisk[0].Description = "Primary Dwelling";
                }



                async function updateData() {
                    createActivityQuoteDB("AdditionalInfo", "API_REQ", "Additional questions update request");
                    const response = await updateQuote(JSON.stringify(quote_information));

                    if (response === "SUCCESS") {
                        createActivityQuoteDB("AdditionalInfo", "API_RES", "Additional questions update response - " + response);
                        localStorage.setItem('primary_residence', primary_residence);
                        setsubmitLoading(false);
                        history.push("/QuoteSummary");
                    }
                }
                setAlert({
                    ...alert,
                    alert_type: 0,
                });

                updateData();

            /*} else if (status === 1) {

                set_updateStatus(false);
                setsubmitLoading(false);

                let h = 0;
                if (width < 758) {
                    h = 105;
                } else {
                    h = 65;
                }

                setAlert({
                    ...alert,
                    alert_state: true,
                    alert_type: 1,
                    alert_height: h,
                    alert_color: "#ffb3b3",
                    error_message: "All Underwriting questions must be answered before proceeding with your quote."
                });

                window.scrollTo(0, 0);

            } else if (status === 2) {

                set_updateStatus(false);
                setsubmitLoading(false);

                let h = 0;
                if (width < 758) {
                    h = 155;
                } else {
                    h = 65;
                }

                setAlert({
                    ...alert,
                    alert_state: true,
                    alert_type: 2,
                    alert_height: h,
                    alert_color: "#ffb3b3",
                    error_message: "Your home may not be eligible for membership.  Please contact our Underwriting Department at 800-648-1794 to review."
                });

                window.scrollTo(0, 0);

            } else if (status === 3) {

                set_updateStatus(false);
                setsubmitLoading(false);


                let h = 0;
                if (width < 758) {
                    h = 165;
                } else {
                    h = 85;
                }

                setAlert({
                    ...alert,
                    alert_state: true,
                    alert_type: 3,
                    alert_height: h,
                    alert_color: "#F2CF30",
                    error_message: "Your home has some unique characteristics that we need to review with you. Speak with an underwriter now (800-648-1794, Monday to Friday 8am to 4:30pm), or continue and review the details later."
                });

                window.scrollTo(0, 0);
            }*/
        }
    }, [updateStatus]);


    function onButtonClick() {

        createActivityQuoteDB("AdditionalInfo", "NAV", "Continue button");
        set_updateStatus(true);
        setsubmitLoading(true);

    }

    useEffect(() => {

        if (existingCustomer.pool_state === "NO") {

            setExistingCustomer({
                ...existingCustomer,
                pool_locking_gate: "",
                pool_diving_board: "",
            })
        }

    }, [existingCustomer.pool_state]);


    useEffect(() => {

        if (existingCustomer.dog_state === "NO") {

            setExistingCustomer({
                ...existingCustomer,
                dogCount: 0,
            })
        }

    }, [existingCustomer.dog_state]);



    useEffect(() => {

        if (noExistingCustomer.pool_state === "NO") {

            setNoExistingCustomer({
                ...noExistingCustomer,
                pool_locking_gate: "",
                pool_diving_board: "",
            })
        }

    }, [noExistingCustomer.pool_state]);


    useEffect(() => {

        if (noExistingCustomer.dog_state === "NO") {

            setNoExistingCustomer({
                ...noExistingCustomer,
                dogCount: 0,
            })
        }

    }, [noExistingCustomer.dog_state]);



    useEffect(() => {

        if (noExistingCustomer.losses_claims_last_3 === "NO") {

            setNoExistingCustomer({
                ...noExistingCustomer,
                weather_related_loss: "",
            })
        }

    }, [noExistingCustomer.losses_claims_last_3]);


    useEffect(() => {

        if (noExistingCustomer.over_70_years === "NO") {

            setNoExistingCustomer({
                ...noExistingCustomer,
                national_historic: "",
            })

        }

    }, [noExistingCustomer.over_70_years]);

    useEffect(() => {

        if (noExistingCustomer.national_historic === "YES") {


            setNoExistingCustomer({
                ...noExistingCustomer,
                major_systems: "",
            })
        }

    }, [noExistingCustomer.national_historic]);


    useEffect(() => {

        if (noExistingCustomer.insurance_in_place_last_3 === "YES") {

            setNoExistingCustomer({
                ...noExistingCustomer,
                first_homeowner: "",
            })
        }

    }, [noExistingCustomer.insurance_in_place_last_3]);


    if (localStorage.getItem('status') === "COMP") {
        return <Redirect push to={'/QuoteSummary'} />;
    } else {
        return (
            <div>
                <Navigation page={page} progress={6} />
                {
                    load === false || submitLoading ?
                        <div className="loading">
                            <div className="loading_icon">
                                <ClipLoader color={"#003550"} loading={true} height={20} width={5} radius={10} margin={10} />
                            </div>
                        </div>
                        :
/*                        <div className={load === true ? {} : "d-none"}>
*/                        <div className="additionalinfo_box_wrapper">

                            {/*<NavigationMobile page={page} />*/}
                            <div className="offset"></div>

                            {width < 758 &&

                                <Alert status={alert.alert_state} message={alert.error_message} width={width - 30} height={alert.alert_height} margin={0} color={alert.alert_color} />

                            }
                            {width > 758 &&

                                <Alert status={alert.alert_state} message={alert.error_message} width={990} height={alert.alert_height} margin={120} color={alert.alert_color} />
                            }

                            <p className="p5_title">Thank you! We will contact <b>{JSON.parse(localStorage.getItem("personal_information")).inspection_contact_name}</b> at
                                <b> {JSON.parse(localStorage.getItem("personal_information")).perf_contact}</b> within 3-5 business days to schedule your inspection.</p>
                            <p className="p5_2_title">Just five quick questions to wrap things up:</p>
                            <div className="contentBox_p5">

{/*                                <div className="p5_c1_r0">

                                    <p className="p5_label_normal">Are you a current member of Mutual Assurance?</p>
                                </div>

                                <div className="p5_c2_r0">

                                    {radio_options.map(result => (
                                        <>
                                            <input className="p4_radio_button" type="radio" value={result} name="member" checked={member_state === result}
                                                onChange={(e) => member_setState(e.target.value)
                                                } />
                                            <b className="radio_button_text light">{result}</b>
                                        </>


                                    ))}
                                    <div className="vDivider_p6" />
                                </div>*/}

                                <div className="p5_c1_r1">

                                    <p className="p5_label_normal">Is this your primary residence?</p>
                                </div>

                                <div className="p5_c2_r1">

                                    {radio_options.map(result => (
                                        <>
                                            <input className="p4_radio_button" type="radio" value={result} name="primary_address" checked={primary_residence === result}
                                                onChange={(e) => primary_residence_setState(e.target.value)
                                                } />
                                            <b className="radio_button_text light">{result}</b>
                                        </>


                                    ))}
                                    <div className="vDivider_p6" />
                                </div>

{/*                                {member_state === "YES" &&
*/}                                    <>
                                    {/*<div className="p5_c1_r2">
                                        <p className="p5_label_normal">Are any parts of the roof asbestos, rubber membrane, or tar and gravel?</p>
                                        </div>
                                        <div className="p5_c2_r2">
                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="gravel"
                                                        checked={existingCustomer.pool_roof === result} onChange={(e) =>
                                                            setExistingCustomer({
                                                                ...existingCustomer,
                                                                pool_roof: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>


                                        <div className="p5_c1_r3">
                                            <p className="p5_label_normal">Are any parts of the exterior walls asbestos?</p>
                                        </div>
                                        <div className="p5_c2_r3">
                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="asbestos"
                                                        checked={existingCustomer.exterior_walls_asbestos === result} onChange={(e) =>
                                                            setExistingCustomer({
                                                                ...existingCustomer,
                                                                exterior_walls_asbestos: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>

                                        <div className="p5_c1_r4">
                                            <p className="p5_label_normal">Do you have dogs?</p>
                                        </div>
                                        <div className="p5_c2_r4">
                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="dogs"
                                                        checked={existingCustomer.dog_state === result}
                                                        onChange={(e) =>
                                                            setExistingCustomer({
                                                                ...existingCustomer,
                                                                dog_state: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>
                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>

                                        {existingCustomer.dog_state === "YES" &&
                                            <>
                                                <div className="p5_c1_r5">
                                                    <p className="p5_label_hide">How many dogs do you have?</p>
                                                </div>
                                                <div className="p5_c2_r5">
                                                    <input
                                                        name="dogs_count"
                                                        className="p6_input"
                                                        type="text" id="dogcount" placeholder="Enter #" value={existingCustomer.dogCount}
                                                        onChange={(e) =>
                                                            setExistingCustomer({
                                                                ...existingCustomer,
                                                                dogCount: e.target.value.replace(/\D/g, '')
                                                            })} />


                                                    <div className="vDivider_p6 thin" />
                                                </div>
                                            </>
                                        }


                                        <div className="p5_c1_r6">
                                            <p className="p5_label_normal">Do you have a trampoline treehouse, or skateboard ramp?</p>
                                        </div>
                                        <div className="p5_c2_r6">
                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="ramp" defaultChecked="No"
                                                        checked={existingCustomer.trampoline_treehouse_skateboard === result}
                                                        onChange={(e) =>
                                                            setExistingCustomer({
                                                                ...existingCustomer,
                                                                trampoline_treehouse_skateboard: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>


                                        <div className="p5_c1_r7">
                                            <p className="p5_label_normal">Do you have a Pool?</p>
                                        </div>

                                        <div className="p5_c2_r7">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="pool2" checked={existingCustomer.pool_state === result}
                                                        onChange={(e) =>
                                                            setExistingCustomer({
                                                                ...existingCustomer,
                                                                pool_state: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>
                                        {existingCustomer.pool_state === "YES" &&
                                            <>
                                                <div className="p5_c1_r8">

                                                    <p className="p5_label_hide">Is the pool fenced with a locking gate?</p>
                                                </div>
                                                <div className="p5_c2_r8">
                                                    {radio_options.map(result => (
                                                        <>
                                                            <input className="p4_radio_button" type="radio" value={result} name="gate2"
                                                                checked={existingCustomer.pool_locking_gate === result}
                                                                onChange={(e) =>
                                                                    setExistingCustomer({
                                                                        ...existingCustomer,
                                                                        pool_locking_gate: e.target.value
                                                                    })} />
                                                            <b className="radio_button_text light ">{result}</b>
                                                        </>


                                                    ))}


                                                    <div className="vDivider_p6 thin" />
                                                </div>

                                                <div className="p5_c1_r9">

                                                    <p className="p5_label_hide">Is there a diving board?</p>
                                                </div>
                                                <div className="p5_c2_r9">
                                                    {radio_options.map(result => (
                                                        <>
                                                            <input className="p4_radio_button" type="radio" value={result} name="diving_board2"
                                                                checked={existingCustomer.pool_diving_board === result}
                                                                onChange={(e) =>
                                                                    setExistingCustomer({
                                                                        ...existingCustomer,
                                                                        pool_diving_board: e.target.value
                                                                    })} />
                                                            <b className="radio_button_text light ">{result}</b>
                                                        </>
                                                    ))}
                                                    <div className="vDivider_p6 thin" />
                                                </div>
                                            </>}

                                        <div className="p5_c1_r10">
                                            <p className="p5_label_normal">Do any floors have water based radiant heat?</p>
                                        </div>
                                        <div className="p5_c2_r10">
                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="heat"
                                                        checked={existingCustomer.floors_wather_based_radiant === result}
                                                        onChange={(e) =>
                                                            setExistingCustomer({
                                                                ...existingCustomer,
                                                                floors_wather_based_radiant: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>

                                        <div className="p5_c1_r11">
                                            <p className="p5_label_normal">Do you have a central heating system?</p>
                                        </div>
                                        <div className="p5_c2_r11">
                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="system"
                                                        checked={existingCustomer.central_heating === result}
                                                        onChange={(e) =>
                                                            setExistingCustomer({
                                                                ...existingCustomer,
                                                                central_heating: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>


                                        <div className="p5_c1_r12">
                                            <p className="p5_label_normal">Do you have any buisness operations on the premises?</p>
                                        </div>
                                        <div className="p5_c2_r12">
                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="premises"
                                                        checked={existingCustomer.buissnes_operations_premises === result}
                                                        onChange={(e) =>
                                                            setExistingCustomer({
                                                                ...existingCustomer,
                                                                buissnes_operations_premises: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>


                                        <div className="p5_c1_r13">
                                            <p className="p5_label_normal">Is your home listed on Airbnb or another home sharing site?</p>
                                        </div>
                                        <div className="p5_c2_r13">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="airbnb"
                                                        checked={existingCustomer.airbnb_or_another === result} onChange={(e) =>
                                                            setExistingCustomer({
                                                                ...existingCustomer,
                                                                airbnb_or_another: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>*/}
 {/*                               </>
                               }
                                {member_state === "NO" &&
                                   <>
*/} 
                                        <div className="p5_c1_r2">
                                            <p className="p5_label_normal">Have you had any losses/claims in the last 3 years?</p>
                                        </div>
                                        <div className="p5_c2_r2">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="losses"
                                                        checked={noExistingCustomer.losses_claims_last_3 === result}
                                                        onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                losses_claims_last_3: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>
                                        {noExistingCustomer.losses_claims_last_3 === "YES" &&
                                            <>
                                                <div className="p5_c1_r3">
                                                    <p className="p5_label_hide">Was it a weather-related loss under $5,000?</p>
                                                </div>
                                                <div className="p5_c2_r3">
                                                    {radio_options.map(result => (
                                                        <>
                                                            <input className="p4_radio_button" type="radio" value={result} name="weather_related"
                                                                checked={noExistingCustomer.weather_related_loss === result}
                                                                onChange={(e) =>
                                                                    setNoExistingCustomer({
                                                                        ...noExistingCustomer,
                                                                        weather_related_loss: e.target.value
                                                                    })} />
                                                            <b className="radio_button_text light ">{result}</b>
                                                        </>
                                                    ))}
                                                    <div className="vDivider_p6 thin" />
                                                </div>
                                            </>
                                        }

                                        {/*<div className="p5_c1_r4">
                                            <p className="p5_label_normal">Is this a single family dwelling occupied by you?</p>
                                        </div>
                                        <div className="p5_c2_r4">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="single_family"
                                                        checked={noExistingCustomer.single_family_dwelling_occupied === result} onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                single_family_dwelling_occupied: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>


                                        <div className="p5_c1_r5">
                                            <p className="p5_label_normal">Is the property currently being renovated?</p>
                                        </div>
                                        <div className="p5_c2_r5">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="renovated"
                                                        checked={noExistingCustomer.property_renovated === result} onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                property_renovated: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>*/}


                                    {member_state === "NO" ?
                                        <>
                                            <div className="p5_c1_r6">
                                                <p className="p5_label_normal">Are any parts of the roof flat?</p> {/*asbestos,rubber membrane, or tar and gravel*/}
                                            </div>
                                            <div className="p5_c2_r6">

                                                {radio_options.map(result => (
                                                    <>
                                                        <input className="p4_radio_button" type="radio" value={result} name="roof"
                                                            checked={noExistingCustomer.roof_asbestos_rubber_membrane_tar_gravel === result} onChange={(e) =>
                                                                setNoExistingCustomer({
                                                                    ...noExistingCustomer,
                                                                    roof_asbestos_rubber_membrane_tar_gravel: e.target.value
                                                                })} />
                                                        <b className="radio_button_text light ">{result}</b>
                                                    </>


                                                ))}
                                                <div className="vDivider_p6" />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="p5_c1_r6">
                                                <p className="p5_label_normal">Are any parts of the roof flat?</p>
                                            </div>
                                            <div className="p5_c2_r6">
                                                {radio_options.map(result => (
                                                    <>
                                                        <input className="p4_radio_button" type="radio" value={result} name="gravel"
                                                            checked={existingCustomer.pool_roof === result} onChange={(e) =>
                                                                setExistingCustomer({
                                                                    ...existingCustomer,
                                                                    pool_roof: e.target.value
                                                                })} />
                                                        <b className="radio_button_text light">{result}</b>
                                                    </>


                                                ))}
                                                <div className="vDivider_p6" />
                                            </div>
                                        </>
                                    }


                                       {/* <div className="p5_c1_r7">
                                            <p className="p5_label_normal">Are any parts of the exterior walls asbestos?</p>
                                        </div>
                                        <div className="p5_c2_r7">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="asbestos"
                                                        checked={noExistingCustomer.exterior_walls_asbestos === result} onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                exterior_walls_asbestos: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>


                                        <div className="p5_c1_r8">
                                            <p className="p5_label_normal">Do you have dogs?</p>
                                        </div>
                                        <div className="p5_c2_r8">
                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="dogs" checked={noExistingCustomer.dog_state === result}
                                                        onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                dog_state: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>
                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>

                                        {noExistingCustomer.dog_state === "YES" &&
                                            <>
                                                <div className="p5_c1_r9">
                                                    <p className="p5_label_hide">How many dogs do you have?</p>
                                                </div>
                                                <div className="p5_c2_r9">
                                                    <input
                                                        name="dogs_count"
                                                        className="p6_input"
                                                        type="text" id="dogcount" placeholder="Enter #" value={noExistingCustomer.dogCount}
                                                        onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                dogCount: e.target.value.replace(/\D/g, '')
                                                            })} />

                                                    <div className="vDivider_p6 thin" />
                                                </div>
                                            </>
                                        }


                                        <div className="p5_c1_r10">
                                            <p className="p5_label_normal">Do you have a trampoline, treehouse, or skateboard ramp?</p>
                                        </div>
                                        <div className="p5_c2_r10">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="trampoline"
                                                        checked={noExistingCustomer.trampoline_treehouse_skateboard === result} onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                trampoline_treehouse_skateboard: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>

                                        <div className="p5_c1_r11">
                                            <p className="p5_label_normal">Do you have a Pool?</p>
                                        </div>

                                        <div className="p5_c2_r11">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="pool" checked={noExistingCustomer.pool_state === result}
                                                        onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                pool_state: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>
                                        {noExistingCustomer.pool_state === "YES" &&
                                            <>
                                                <div className="p5_c1_r12">

                                                    <p className="p5_label_hide">Is the pool fenced with a locking gate?</p>
                                                </div>
                                                <div className="p5_c2_r12">
                                                    {radio_options.map(result => (
                                                        <>
                                                            <input className="p4_radio_button" type="radio" value={result} name="gate"
                                                                checked={noExistingCustomer.pool_locking_gate === result} onChange={(e) =>
                                                                    setNoExistingCustomer({
                                                                        ...noExistingCustomer,
                                                                        pool_locking_gate: e.target.value
                                                                    })} />
                                                            <b className="radio_button_text light ">{result}</b>
                                                        </>
                                                    ))}
                                                    <div className="vDivider_p6 thin" />
                                                </div>
                                                <div className="p5_c1_r13">

                                                    <p className="p5_label_hide">Is there a diving board?</p>
                                                </div>
                                                <div className="p5_c2_r13">
                                                    {radio_options.map(result => (
                                                        <>
                                                            <input className="p4_radio_button" type="radio" value={result} name="diving_board"
                                                                checked={noExistingCustomer.pool_diving_board === result} onChange={(e) =>
                                                                    setNoExistingCustomer({
                                                                        ...noExistingCustomer,
                                                                        pool_diving_board: e.target.value
                                                                    })} />
                                                            <b className="radio_button_text light ">{result}</b>
                                                        </>


                                                    ))}


                                                    <div className="vDivider_p6 thin" />
                                                </div>

                                            </>}

                                        <div className="p5_c1_r14">
                                            <p className="p5_label_normal">Have you had any bankruptcies or foreclosures within the last 5 years?</p>
                                        </div>
                                        <div className="p5_c2_r14">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="bankruptcies"
                                                        checked={noExistingCustomer.bankruptcies_foreclosures === result} onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                bankruptcies_foreclosures: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>
                                        <div className="p5_c1_r15">
                                            <p className="p5_label_normal">Do any floors have water based radiant heat?</p>
                                        </div>
                                        <div className="p5_c2_r15">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="radiant"
                                                        checked={noExistingCustomer.floors_wather_based_radiant === result} onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                floors_wather_based_radiant: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>

                                        <div className="p5_c1_r16">
                                            <p className="p5_label_normal">Do you have a central heating system?</p>
                                        </div>
                                        <div className="p5_c2_r16">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="heating_system"
                                                        checked={noExistingCustomer.central_heating === result} onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                central_heating: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>


                                        <div className="p5_c1_r17">
                                            <p className="p5_label_normal">Do you have any business operations on the premises?</p>
                                        </div>
                                        <div className="p5_c2_r17">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="business"
                                                        checked={noExistingCustomer.buissnes_operations_premises === result} onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                buissnes_operations_premises: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>


                                        <div className="p5_c1_r18">
                                            <p className="p5_label_normal">Is the home over 70 years old?</p>
                                        </div>
                                        <div className="p5_c2_r18">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="home_year"
                                                        checked={noExistingCustomer.over_70_years === result} onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                over_70_years: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>
                                        {noExistingCustomer.over_70_years === "YES" &&
                                            <>
                                                <div className="p5_c1_r19">

                                                    <p className="p5_label_hide">Is the home on the National Historic Registry?</p>
                                                </div>
                                                <div className="p5_c2_r19">
                                                    {radio_options.map(result => (
                                                        <>
                                                            <input className="p4_radio_button" type="radio" value={result} name="national_historic"
                                                                checked={noExistingCustomer.national_historic === result} onChange={(e) =>
                                                                    setNoExistingCustomer({
                                                                        ...noExistingCustomer,
                                                                        national_historic: e.target.value
                                                                    })} />
                                                            <b className="radio_button_text light ">{result}</b>
                                                        </>
                                                    ))}
                                                    <div className="vDivider_p6 thin" />
                                                </div>
                                                {noExistingCustomer.national_historic === "NO" &&
                                                    <>
                                                        <div className="p5_c1_r20">

                                                            <p className="p5_label_hide">Have all major systems (electrical, plumbing, heating) been updated?</p>
                                                        </div>
                                                        <div className="p5_c2_r20">
                                                            {radio_options.map(result => (
                                                                <>
                                                                    <input className="p4_radio_button" type="radio" value={result} name="major_systems"
                                                                        checked={noExistingCustomer.major_systems === result} onChange={(e) =>
                                                                            setNoExistingCustomer({
                                                                                ...noExistingCustomer,
                                                                                major_systems: e.target.value
                                                                            })} />
                                                                    <b className="radio_button_text light ">{result}</b>
                                                                </>
                                                            ))}
                                                            <div className="vDivider_p6 thin" />
                                                        </div>


                                                    </>}

                                            </>}*/}

                                        <div className="p5_c1_r21">
                                            <p className="p5_label_normal">Have you had insurance in place for the last 3 years with no lapse?</p>
                                        </div>
                                        <div className="p5_c2_r21">

                                            {radio_options.map(result => (
                                                <>
                                                    <input className="p4_radio_button" type="radio" value={result} name="old"
                                                        checked={noExistingCustomer.insurance_in_place_last_3 === result} onChange={(e) =>
                                                            setNoExistingCustomer({
                                                                ...noExistingCustomer,
                                                                insurance_in_place_last_3: e.target.value
                                                            })} />
                                                    <b className="radio_button_text light ">{result}</b>
                                                </>


                                            ))}
                                            <div className="vDivider_p6" />
                                        </div>

                                        {noExistingCustomer.insurance_in_place_last_3 === "NO" &&
                                            <>
                                                <div className="p5_c1_r22">
                                                    <p className="p5_label_hide">Are you a first time homeowner?</p>
                                                </div>
                                                <div className="p5_c2_r22">
                                                    {radio_options.map(result => (
                                                        <>
                                                            <input className="p4_radio_button" type="radio" value={result} name="first_homeowner"
                                                                checked={noExistingCustomer.first_homeowner === result} onChange={(e) =>
                                                                    setNoExistingCustomer({
                                                                        ...noExistingCustomer,
                                                                        first_homeowner: e.target.value
                                                                    })} />
                                                            <b className="radio_button_text light ">{result}</b>
                                                        </>
                                                    ))}
                                                    <div className="vDivider_p6 " />
                                                    <br />
                                                </div>
                                            </>
                                        }

                                    {member_state === "NO" ?
                                        <>
                                            <div className="p5_c1_r23">
                                                <p className="p5_label_normal">Is your home listed on Airbnb or another home sharing site?</p>
                                            </div>
                                            <div className="p5_c2_r23">

                                                {radio_options.map(result => (
                                                    <>
                                                        <input className="p4_radio_button" type="radio" value={result} name="airbnb"
                                                            checked={noExistingCustomer.airbnb_or_another === result} onChange={(e) =>
                                                                setNoExistingCustomer({
                                                                    ...noExistingCustomer,
                                                                    airbnb_or_another: e.target.value
                                                                })} />
                                                        <b className="radio_button_text light ">{result}</b>
                                                    </>


                                                ))}
                                                <div className="vDivider_p6" />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="p5_c1_r23">
                                                <p className="p5_label_normal">Is your home listed on Airbnb or another home sharing site?</p>
                                            </div>
                                            <div className="p5_c2_r23">

                                                {radio_options.map(result => (
                                                    <>
                                                        <input className="p4_radio_button" type="radio" value={result} name="airbnb"
                                                            checked={existingCustomer.airbnb_or_another === result} onChange={(e) =>
                                                                setExistingCustomer({
                                                                    ...existingCustomer,
                                                                    airbnb_or_another: e.target.value
                                                                })} />
                                                        <b className="radio_button_text light ">{result}</b>
                                                    </>


                                                ))}
                                                <div className="vDivider_p6" />
                                            </div>
                                        </>}

                                </>
{/*                                }
*/}                                <div className="footer_p5_1">

                                    <div >
                                        <Link to="/HomeInspection">
                                            <p type="submit" className="footer_p5_1b">&lt;<span>Back</span></p>
                                        </Link>
                                    </div>
                                    <div >
                                        <button type="submit" className="footer_p5_1a" onClick={onButtonClick}>Continue</button>
                                    </div>
                                </div>

                                <div className="footer_p5_2">
                                    <b>
                                        <p className="footer_p6_1"> Questions? (800) 648-1794</p>
                                        <p className="footer_p6_1_5"><a href="mailto: service@mutual-assurance.com">service@mutual-assurance.com</a></p>
                                        <p className="footer_p6_2">(Monday to Friday 8am – 4:30pm)</p>
                                    </b>
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

export default AdditionalInfo_page