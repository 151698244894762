import React from 'react';
import './Alert.css';

function Alert(props) {



    return (
        <div>

            {props.status === true &&
                <div>
                    <div className="alert_box" style={{ width: props.width, height: props.height, marginLeft: props.margin, marginRight: props.marginRight, backgroundColor: props.color }}>
                    <p className="alert_message">
                       
                        <div dangerouslySetInnerHTML={{ __html:  props.message }}>
                        </div>
                        </p>
                    </div>
                </div>
            }
        </div>
    );
}

export default Alert


