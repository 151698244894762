import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Page_retrieve_a_2.css';
import { createQuote, updateQuote, retrieveQuote, retrieveQuoteNumbers, createActivityQuoteDB } from '../API/Model';
import Alert from '../Additional/Alert';
import useWindowDimensions from '../Additional/ScreenProps';
import { useEffect } from 'react';
import ClipLoader from "react-spinners/FadeLoader";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'

function Page_retrieve_a_2() {


    let history = useHistory();
    const [modalError, setmodalError] = useState(false);
    const [errorMessage, seterrorMessage] = useState('');
    const { height, width } = useWindowDimensions();
    const [errorHeight, setErrorHeight] = useState(65);
    const [load, setonLoad] = useState(false);
    const [loading, setLoading] = useState(true);
    const [fetchStatus, setfetchStatus] = useState(null);
    const [quoteNumbers, setQuoteNumbers] = useState([]);
    const [quoteStatus, setQuoteStatus] = useState(null);
    const [selectedQuote, setSelectedQuote] = useState(null)

    useEffect(() => {

        if (load === false) {
            if (localStorage.getItem('personal_information') == undefined && localStorage.getItem('retrieve_data') == undefined) {
                history.push("/");
            }

            async function fetchQuoteNumbers(quote) {

                const response = await retrieveQuoteNumbers(JSON.stringify(quote));
                createActivityQuoteDB("RetrieveEmailList", "RECALL", "RetrieveQuoteNumbers quote recall");
                if (response !== "ERROR") {
                    setQuoteNumbers(response);
                    setSelectedQuote(response[0].number);
                } else {
                    setErrorHeight(65);
                    seterrorMessage("Service temporarily unavailable. Please try again later.");
                    setmodalError(true);
                }
                setLoading(false);
            }
            let quote = {}
            if (localStorage.getItem('retrieve_data') != undefined) {
                const retrieveData = JSON.parse(localStorage.getItem("retrieve_data"));
                quote = {
                    Email: retrieveData.email,
                    LastName: retrieveData.lastName,
                    ZipCode: retrieveData.zipcode,
                    SearchCriteria: "Email"
                }
            } else if (localStorage.getItem('personal_information') != undefined) {
                quote = {
                    Email: JSON.parse(localStorage.getItem("personal_information")).email,
                    LastName: JSON.parse(localStorage.getItem("personal_information")).last_name,
                    ZipCode: JSON.parse(localStorage.getItem("personal_information")).main_addr_zip,
                    SearchCriteria: "Email"
                }
            }

            fetchQuoteNumbers(quote);

            createActivityQuoteDB("RetrieveEmailList", "PL", "RetrieveEmailList page loaded");

            setonLoad(true);
        }

    });

    useEffect(() => {

        if (quoteStatus === "SUCCESS") {
            if (JSON.parse(localStorage.getItem("quote_information")).num_stories === undefined ||
                JSON.parse(localStorage.getItem("quote_information")).sqft === undefined ||
                JSON.parse(localStorage.getItem("quote_information")).num_bath_full === undefined ||
                JSON.parse(localStorage.getItem("quote_information")).build_year === undefined) {


                createActivityQuoteDB("RetrieveEmailList", "RCE_ERROR", "RCE information retrieve error");

                fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/updateQuoteDB', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Quote_Number: localStorage.getItem('quote_number'),
                        Quote_Status_code: "RCE",
                    })
                })

                seterrorMessage("We are unable complete your estimate at this time. Please contact customer service at 800-648-1794.");
                if (width < 758) {
                    setErrorHeight(105);
                } else {
                    setErrorHeight(65);
                }
                setmodalError(true);
                setQuoteStatus(null);
                window.scrollTo(0, 0);
                setLoading(false);
            } else {
                history.push("/PropertyInfo");
            }

        } else if (quoteStatus != null) {

            seterrorMessage(quoteStatus);
            setmodalError(true);
            setQuoteStatus(null);
        }

    }, [quoteStatus]);

    async function databaseUpdate() {
        const personalInfo = JSON.parse(localStorage.getItem("personal_information"));
        await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/createQuoteDB', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Quote_Number: localStorage.getItem('quote_number'),
                Email: personalInfo.email,
                Insured_First_Name: personalInfo.first_name,
                Insured_Last_Name: personalInfo.last_name,
                CoApplicant_First_Name: personalInfo.co_first_name,
                CoApplicant_Last_Name: personalInfo.co_last_name,
                Address: personalInfo.main_addr_street,
                Zip_code: personalInfo.main_addr_zip,
                Quote_Status_code: "STR",
                Session_id: localStorage.getItem('session_id'),
            })
        })

        await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/createQuoteInformation', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Quote_Number: localStorage.getItem('quote_number'),
            })
        })
    }

    async function onStartNewClick() {
        setmodalError(false);
        const quote = JSON.parse(localStorage.getItem("quote_to_send"))

        setLoading(true);

        async function fetchData() {
            const response = await createQuote(JSON.stringify(quote));
            setQuoteStatus(response);
            if (response !== "Service temporarily unavailable. Please try again later.") {
                databaseUpdate();
            }

            createActivityQuoteDB("RetrieveEmailList", "API_RES", "Create quote response - " + response);
        }
        createActivityQuoteDB("RetrieveEmailList", "API_REQ", "Create quote request");
        fetchData();

    }

    function formatDate(date) {
        return date.substr(4, 2) + "/" + date.substr(6, 2) + "/" + date.substr(0, 4);
    }

    function handleQuoteChange(e) {
        setSelectedQuote(e.target.value);
    }

    async function onContinueClick(quoteNumber) {
        setmodalError(false);
        setLoading(true);
        let quote = {};

        if (localStorage.getItem('retrieve_data') != undefined) {
            const retrieveData = JSON.parse(localStorage.getItem("retrieve_data"));
            quote = {
                QuoteNumber: quoteNumber,
                LastName: retrieveData.lastName,
                ZipCode: retrieveData.zipcode,
                SearchCriteria: "QuoteNumber"
            }
        } else if (localStorage.getItem('personal_information') != undefined) {
            quote = {
                QuoteNumber: quoteNumber,
                LastName: JSON.parse(localStorage.getItem("personal_information")).last_name,
                ZipCode: JSON.parse(localStorage.getItem("personal_information")).main_addr_zip,
                SearchCriteria: "QuoteNumber"
            }
        }

        async function fetchData() {
            const response = await retrieveQuote(JSON.stringify(quote));
            createActivityQuoteDB("RetrieveEmailList", "RECALL", "RetrieveQuote quote recall");
            if (response !== "ERROR") {
                setfetchStatus(true);

            } else {
                setErrorHeight(65);
                seterrorMessage("This estimate can not be recovered.");
                setmodalError(true);
                setLoading(false);
            }
        }

        await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/checkCompletedQuote', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Quote_Number: quoteNumber,
            })
        }).then(res => res.json())
            .then(result => {

                if (result[0] === undefined) {
                    localStorage.setItem('status', "RET");
                    fetchData();
                } else {
                    if (width < 758) {
                        setErrorHeight(110);
                    } else {
                        setErrorHeight(85);
                    }
                    setLoading(false);
                    seterrorMessage("This estimate has already been submitted and cannot be updated. Please contact customer service at 800-648-1794.");
                    setmodalError(true);
                }
            })
    }

    useEffect(() => {

        if (fetchStatus != null) {

            fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/selectLastPage', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Quote_Number: window.localStorage.getItem("quote_number"),
                })
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.length != 0) {
                        if (responseJson[0].toString() != null) {
                            history.push("/" + responseJson[0].toString());
                        } else {
                            history.push("/AdditionalInfo");
                        }
                    } else {
                        seterrorMessage("This estimate cannot be recovered. Please contact customer service at 800-648-1794.");
                        if (width < 758) {
                            setErrorHeight(85);
                        } else {
                            setErrorHeight(65);
                        }
                        setQuoteStatus(null);
                        setfetchStatus(null);
                        window.scrollTo(0, 0);
                        setmodalError(true);
                        setLoading(false);
                    }
                    
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [fetchStatus]);

    return (<>
        {
            loading ?
                <div className="loading">
                    < div className="loading_icon" >
                        <ClipLoader color={"#003550"} loading={loading} height={20} width={5} radius={10} margin={10} />

                    </div >
                </div >
                :
                <div className="retrieve_list_offset">

                    {
                        width < 758 &&

                        <Alert status={modalError} message={errorMessage} width={width - 30} height={errorHeight} margin={0} color={"#ffb3b3"} />

                    }
                    {
                        width > 758 &&

                        <Alert status={modalError} message={errorMessage} width={750} height={errorHeight} margin={180} color={"#ffb3b3"} />
                    }

                    <p className="retrieve_list_title">
                        We noticed that you have existing estimate(s). Please select from the option(s) below:</p>

                    <div className="retrieve_list_box">

                        <table className='table table-striped' aria-labelledby="tabelLabel">
                            <thead >
                                <tr>
                                    <th className="pt-0" style={{ width: "75px", borderTopWidth: "0", borderBottomWidth: "0" }}></th>
                                    <th className="pt-0" style={{ width: "250px", fontWeight: "normal", borderTopWidth: "0", borderBottomWidth: "0" }}>Estimate Number</th>
                                    <th className="pt-0" style={{ width: "100px", fontWeight: "normal", borderTopWidth: "0", borderBottomWidth: "0"}}>Date</th>
                                    <th className="pt-0" style={{ borderTopWidth: "0", borderBottomWidth: "0" }}></th>
                                </tr>
                            </thead>
                            <tbody className="tbody_retrieve_a2">
                                {quoteNumbers.map(quote =>
                                    <tr key={quote.number}  >
                                        <td className='align-middle'>
                                            <input type="radio" value={quote.number} name="quote" checked={selectedQuote === quote.number} onChange={handleQuoteChange} />
                                        </td>
                                        <td className='align-middle' style={{ fontWeight: 'bold' }}>
                                            {quote.number}
                                        </td>
                                        <td className='align-middle' style={{ fontWeight: 'bold' }}>
                                            {formatDate(quote.date)}
                                        </td>
                                        <td className='align-right text-right py-0 align-middle'>
                                            {selectedQuote === quote.number &&
                                                <button
                                                    className='continue_list_button'
                                                    onClick={() => onContinueClick(quote.number)}
                                                >
                                                    Continue
                                            </button>
                                            }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {localStorage.getItem('quote_to_send') && <>
                            <hr style={{ opacity: '1' }} />
                            <button type="submit" className="retrieve_list_button" onClick={onStartNewClick} >Start a New Estimate</button>
                        </>}
                    </div>
                </div>
        }
    </>);
}

export default Page_retrieve_a_2
