import React, { useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import NumberFormat from 'react-number-format';
import ClipLoader from "react-spinners/FadeLoader";
import useWindowDimensions from '../../Additional/ScreenProps';
import Alert from '../../Additional/Alert';
import { updateNewLandingPageQuote, fetchQuote, createActivityQuoteDB } from '../../API/Model';
import './Inspection_Info_Blacksburg.css';
import '../../styles.css';
import { Redirect } from 'react-router';
import Banner from '../../Additional/Banner';

function Inspection_Info_Blacksburg() {

    let history = useHistory();

    const [onLoad, setOnLoad] = useState(true);
    const [isSubmit, setIsSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [error_message, setError_message] = useState("error");
    const [alert_state, setAlert_state] = useState(false);
    const [quoteStatus, setQuoteStatus] = useState(null);
    const [quote_information, setquote_information] = useState(0);
    const { height, width } = useWindowDimensions();
    const [customerRef, setCustomerRef] = useState("");
    const [covA, setCovA] = useState(0);


    const [addres, setAddres] = useState({
        Street: "",
        City: "",
        Zip: "",
        State: ""
    });

    const [personal_info, setPersonalInfo] = useState({
        First: "",
        Last: "",
        Email: "",
        Phone: ""
    });

    const onChangeUser = (e) => {
        setPersonalInfo({ ...personal_info, [e.target.name]: e.target.value });
    }

    function validateForm() {

        const errors = {};
        const regex_email = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const regex_phone = /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/;

        //Applicant Personal Information

        if (!personal_info.First) {
            errors.Applicant_First = "First name is required!";
        }
        if (!personal_info.Last) {
            errors.Applicant_Last = "Last name is required!";
        }
        if (!personal_info.Email) {
            errors.Applicant_Email = "Email is required!";
        }
        else if (!regex_email.test(personal_info.Email)) {
            errors.Applicant_Email = "This is not a valid email format";
        }
        if (!personal_info.Phone) {
            errors.Applicant_Phone = "Phone is required!";
        }
        else if (!regex_phone.test(personal_info.Phone)) {
            errors.Applicant_Phone = "This is not a valid phone format.";
        }

        return errors;
    }


    async function databaseUpdate() {

        await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/updateInspectionQuoteDB', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Email: personal_info.Email,
                Insured_First_Name: personal_info.First,
                Insured_Last_Name: personal_info.Last,
                Quote_Number: localStorage.getItem('quote_number'),
            })
        })

        createActivityQuoteDB("BlacksburgInfo", "NAV", "Continue button");
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validateForm());
    };

    useEffect(() => {
        if (onLoad) {
            createActivityQuoteDB("BlacksburgInfo", "PL", "BlacksburgInfo page loaded");
            localStorage.setItem('current_page', "BlacksburgInfo");

            setCustomerRef(JSON.parse(localStorage.getItem("personal_information")).customer_ref)
            setCovA(JSON.parse(localStorage.getItem("cov_a")))

            setOnLoad(false);

        }

    }, [onLoad, addres]);

    useEffect(() => {
        if (!onLoad) {
            if (Object.keys(formErrors).length === 0 && isSubmit) {
                setLoading(true);
                async function fetchData() {
                    const response = await fetchQuote(JSON.stringify({
                        "ApplicationNumber": '"' + window.localStorage.getItem("quote_number") + '"',
                        "Owner": "SalesPortal"
                    }));
                    let quote = {
                        ExternalValidateBeanExist: "No",
                        DTOApplication: [
                            response
                        ]
                    };
                    quote.DTOApplication[0].DTOInsured[0].PartyInfo[0].NameInfo[0].GivenName = personal_info.First;
                    quote.DTOApplication[0].DTOInsured[0].PartyInfo[0].NameInfo[0].Surname = personal_info.Last;
                    quote.DTOApplication[0].DTOInsured[0].PartyInfo[0].NameInfo[0].CommercialName = personal_info.First + " " + personal_info.Last;
                    quote.DTOApplication[0].DTOInsured[0].PartyInfo[0].EmailInfo[0].EmailAddr = personal_info.Email;
                    quote.DTOApplication[0].DTOInsured[0].PartyInfo[0].PhoneInfo[0].PhoneNumber = personal_info.Phone;
                    quote.DTOApplication[0].DTOInsured[0].PartyInfo[0].PhoneInfo[0].PhoneName = "Mobile";

                    async function updateData() {
                        if (quote.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.length > 2) {
                            quote.DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.splice(0, 1);
                        }
                        const response = await updateNewLandingPageQuote(JSON.stringify(quote));
                        setQuoteStatus(response);

                        createActivityQuoteDB("BlacksburgInfo", "API_RES", "Update quote response - " + response);
                    }
                    createActivityQuoteDB("BlacksburgInfo", "API_REQ", "Update quote request");
                    updateData();
                }
                fetchData();

            } else {
                setIsSubmit(false);
                window.scrollTo(0, 0);
            }
        }

    }, [isSubmit]);

    useEffect(() => {

        window.localStorage.setItem("personal_information", JSON.stringify({
            ...JSON.parse(localStorage.getItem("personal_information")),
            first_name: personal_info.First,
            last_name: personal_info.Last,
            email: personal_info.Email,
            phone: personal_info.Phone,
        }));
    });

    useEffect(() => {
        if (!onLoad) {
            setIsSubmit(true);
        }

    }, [formErrors]);

    useEffect(() => {
        setLoading(false);

        if (quoteStatus === "SUCCESS") {

            databaseUpdate();
            history.push("/EstimateSummary");

        } else if (quoteStatus != null) {

            setIsSubmit(false);
            setError_message(quoteStatus);
            setAlert_state(true);
            setQuoteStatus(null);
        }

    }, [quoteStatus]);

    function formatNumber(number) {

        if (number != null) {

            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
            });
            return formatter.format(number);
        }
    }

    if (localStorage.getItem('status') === "COMP") {
        return <Redirect push to={'/EstimateSummary'} />;
    } else {
        return (
            <div>
                {
                    loading ?
                        <div className="loading">
                            <div className="loading_icon">
                                <ClipLoader color={"#003550"} loading={loading} height={20} width={5} radius={10} margin={10} />

                            </div>
                        </div>
                        :
                        <div className="yourinfo_text_wrapper">

                            <div className="offset"></div>

                            {width < 758 &&
                                <Alert status={alert_state} message={error_message} width={width - 30} height={105} margin={0} color={"#ffb3b3"} />
                            }
                            {width > 758 &&

                                <Alert status={alert_state} message={error_message} width={1110} height={65} margin={"auto"} marginRight={"auto"} color={"#ffb3b3"} />
                            }
                            <div className="wrapper pt-0" id="content">
                                <Banner covA={formatNumber(covA.toString())} skipTitle removeMarginLeft />
                                {/*<Banner covA={formatNumber(1000)} skipTitle removeMarginLeft />*/}

                                <form onSubmit={handleSubmit} className="yourinfo_form box w-100 mt-2 mt-sm-4">
                                    <p className="p1_txt text-muted text-center mb-1 mb-sm-4 pl-0 mx-4">Want to learn more?<br />
                            Tell us about yourself and we’ll contact you.</p>


                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <input
                                                name="First"
                                                className="inp_p3_1"
                                                placeholder="First"
                                                value={personal_info.First}
                                                onChange={onChangeUser}
                                                autocomplete="off"
                                            />

                                            <p className="p3_error_txt">{formErrors.Applicant_First}</p>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input
                                                name="Last"
                                                className="inp_p3_1"
                                                placeholder="Last"
                                                value={personal_info.Last}
                                                onChange={onChangeUser}
                                                autocomplete="off"
                                            />
                                            <p className="p3_error_txt">{formErrors.Applicant_Last}</p>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input
                                                name="Email"
                                                className="inp_p3_1"
                                                placeholder="Email"
                                                value={personal_info.Email}
                                                onChange={onChangeUser}
                                                autocomplete="off"
                                            />
                                            <p className="p3_error_txt">{formErrors.Applicant_Email}</p>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <NumberFormat
                                                name="Phone"
                                                type="tel"
                                                format="(###) ###-####"
                                                mask="_"
                                                onChange={onChangeUser}
                                                value={personal_info.Phone}
                                                className="inp_p3_1"
                                                placeholder="Phone"
                                            />
                                            <p className="p3_error_txt">{formErrors.Applicant_Phone}</p>
                                        </div>
                                    </div>

                                    {/*<GooglePlacesPropertyAddress setAddres={setAddres} addres={addres} onChangeAddres={onChangeAddres} />*/}

                                    <div className="section_12_a">
                                        <div className="btn_p3_wrapper">
                                            <button type="submit" className="btn_p3">Go</button>
                                        </div>

                                    </div>

                                    <div className="footer">
                                        <b>
                                            <p className="footer_1"> Or call (800) 648-1794</p>
                                            <p className="footer_2"> (Monday to Friday 8am – 4:30pm)</p>
                                        </b>
                                    </div>

                                </form>
                            </div>
                        </div>
                }
            </div >
        );
    }
}

export default Inspection_Info_Blacksburg
