import React, { useState, useEffect } from 'react';
import Banner from '../Additional/Banner';
import ClipLoader from "react-spinners/FadeLoader";
import { fetchQuote, createActivityQuoteDB, getToken,getApplicationCorrespondence } from '../API/Model';
import * as htmlToImage from 'html-to-image';
import jsPDF from "jspdf";
import './New_Landing_Page_3.css';
import '../styles.css';


function New_Landing_Page_3() {

    const page = 8;
    let quote = {
        "ApplicationNumber": '"' + window.localStorage.getItem("quote_number") + '"',
        "Owner": "SalesPortal"
    }
    const [url, setURL] = useState(null);
    const [flag, set_flag] = useState(false);
    const [load, setonLoad] = useState(false);
    const [loading, setLoading] = useState(true);
    const [coverage, setCoverage] = useState({
        covA: 0,
    });
    const [newQuoteEmailSent, setNewQuoteEmailSent] = useState(false);

    useEffect(() => {
        if (load === false) {

            fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/checkNewQuoteEmailSent', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Quote_Number: localStorage.getItem('quote_number'),
                })
            })
                .then(res => res.json())
                .then(result => {

                    if (result[0] === undefined && !newQuoteEmailSent) {
                        setNewQuoteEmailSent(true);
                        let formData = new FormData()
                        formData.append('email', JSON.parse(localStorage.getItem("personal_information")).email);
                        formData.append('pdf_url', null);
                        formData.append('subject', "New SalesPortal Quote - " + localStorage.getItem("quote_number"));
                        formData.append('type', "email_e");
                        formData.append('quote_number', localStorage.getItem("quote_number"));
                        formData.append('First_name', JSON.parse(localStorage.getItem("personal_information")).first_name + " " + JSON.parse(localStorage.getItem("personal_information")).last_name);
                        formData.append('inspection_phone', JSON.parse(localStorage.getItem("personal_information")).phone);
                        formData.append('inspection_email', JSON.parse(localStorage.getItem("personal_information")).email);
                        let agency_name = "";
                        if (JSON.parse(localStorage.getItem("agency_information")) !== null &&
                            Object.keys(JSON.parse(localStorage.getItem("agency_information"))).length !== 0 &&
                            JSON.parse(localStorage.getItem("agency_information")).agency_name !== null &&
                            JSON.parse(localStorage.getItem("agency_information")).agency_name !== "Mutual Assurance Society of Virginia") {
                            agency_name = JSON.parse(localStorage.getItem("agency_information")).agency_name
                                + ";" + JSON.parse(localStorage.getItem("agency_information")).agency_phone
                                + ";" + JSON.parse(localStorage.getItem("agency_information")).agency_email
                                + ";" + JSON.parse(localStorage.getItem("agency_information")).agency_addres
                                + ";" + JSON.parse(localStorage.getItem("agency_information")).agency_city + " " + JSON.parse(localStorage.getItem("agency_information")).agency_zip;
                        }
                        formData.append('inspection_name', agency_name);

                        fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'EmailSender/sendEmail', {
                            method: 'POST',
                            body: formData
                        })
                            .then(res => res.json())
                            .then(result => {
                                createActivityQuoteDB("EstimateSummary", "EMAIL_SENT", "New Quote email sent");
                            })
                    }
                })
        }
    });

   
    useEffect(() => {
        if (load === false) {

            setonLoad(true);
            localStorage.setItem('status', "COMP");

            createActivityQuoteDB("EstimateSummary", "PL", "EstimateSummary page loaded");
            localStorage.setItem('current_page', "QuoteSummary");

            async function fetchData() {
                const response = await fetchQuote(JSON.stringify(quote));

                window.localStorage.setItem("quote_information", JSON.stringify({
                    firstYearPremium: response.DTOBasicPolicy[0].FinalPremiumAmt,
                    secondYearPremium: response.DTOLine[0].EstimatedSecondYearAssessment,
                    fiveYearPremium: response.DTOLine[0].EstimatedFiveYearAveAnnualCost,
                }));

                setCoverage({
                    ...coverage,
                    covA: response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovALimit,
                });

                setLoading(false);
                set_flag(true);
            }
            fetchData();
        }
    });

    useEffect(() => {

        if (flag === true) {
     
            async function fetchToken() {
                const response = await getToken(JSON.stringify({}));
                if (response !== "ERROR") {
                    getURL(response)
                }
            }

            async function getURL(token) {
                const response = await getApplicationCorrespondence(JSON.stringify({
                    ApplicationNumber: window.localStorage.getItem("quote_number"),
                    CorrespondenceTemplateId: "HO5Estimate",
                    Token: token
                }));
                if (response !== "ERROR") {
                    setURL(response);
                }
            }

            set_flag(false);
            fetchToken();
        }
    }, [flag]);


 
    useEffect(() => {

        if (url != null) {
            fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'portal/checkCompletedQuote', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Quote_Number: localStorage.getItem('quote_number'),
                })
            })
                .then(res => res.json())
                .then(result => {
                    if (result[0] === undefined) {

                        let formData = new FormData()
                        formData.append('pdf_url', url);
                        formData.append('email', JSON.parse(localStorage.getItem("personal_information")).email);
                        formData.append('subject', "Your Mutual Assurance Estimate");
                        formData.append('first_name', JSON.parse(localStorage.getItem("personal_information")).first_name);
                        formData.append('last_name', JSON.parse(localStorage.getItem("personal_information")).last_name);
                        formData.append('type', "email_a");
                        formData.append('inspection_name', JSON.parse(localStorage.getItem("personal_information")).first_name + " " + JSON.parse(localStorage.getItem("personal_information")).last_name);
                        

                        fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'EmailSender/sendEmail', {
                            method: 'POST',
                            body: formData
                        })
                            .then(res => res.json())
                            .then(result => {
                                createActivityQuoteDB("EstimateSummary", "EMAIL_SENT", "Summary email sent");

                                fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'portal/updateQuoteDB', {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        Quote_Number: localStorage.getItem('quote_number'),
                                        Quote_Status_code: "COMP",
                                    })
                                })
                            })
                    }
                })
        }
    }, [url]);

    function formatNumber(number) {

        if (number != null) {

            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
            });
            return formatter.format(number);
        }
    }

    return (

        <div >
            {
                loading === true ?
                    <div className="loading">
                        <div className="loading_icon">
                            <ClipLoader color={"#003550"} loading={true} height={20} width={5} radius={10} margin={10} />
                        </div>
                    </div>
                    :
                    <div className="wrapper pt-0" id="content">
{/*                        <div className={load === true ? {} : "d-none"}>
*/}                        <div className="quotesummary_box_wrapper w-100">

                            <div className="baner" >
                                <p className="p1_txt text-muted text-center mb-1 mb-sm-4 pl-0 mx-4 mt-2 mt-sm-0">We’ll be in touch within 1-2 business days</p>
                                <Banner covA={formatNumber(coverage.covA.toString())} removeMarginLeft skipTitle />
                            </div>

                            <p className="p1_txt text-muted text-center mb-4 mb-sm-4 pl-0 mx-4">Your Estimate # is<br/> {window.localStorage.getItem("quote_number")}</p>
                            
                            <div className="p6_footer pl-0 ml-0">

                                <div className="wrap">
                                    <b>
                                        <p className="footer_p8_1 ">(800) 648-1794</p>
                                        <p className="footer_p8_2 ">(Monday to Friday 8am – 4:30pm)</p>
                                    </b>
                                    <p className="p1_txt text-muted text-center mb-4 mb-sm-4 pl-0 mx-4">The way home insurance was meant to be.</p>
                                    <a href="https://www.mutual-assurance.com/faqs2">
                                        <button type="submit" className="btn_p7" >About Mututal Assurance</button>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default New_Landing_Page_3