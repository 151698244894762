import React, { useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import NumberFormat from 'react-number-format';
import ClipLoader from "react-spinners/FadeLoader";
import useWindowDimensions from '../Additional/ScreenProps';
import Alert from '../Additional/Alert';
import { createQuote, updateQuote, fetchQuote, createActivityQuoteDB } from '../API/Model';
import './YourInfo_page.css';
import '../styles.css';
import { Redirect } from 'react-router';
import GooglePlacesMailingAddress from './GooglePlacesMailingAddress';
import GooglePlacesPriorAddress from './GooglePlacesPriorAddress';
import GooglePlacesPropertyAddress from './GooglePlacesPropertyAddress';
import Navigation from '../Overlays/Navigation';
import moment from "moment";

function YourInfo() {

    let history = useHistory();

    const [onLoad, setOnLoad] = useState(true);
    const [isSubmit, setIsSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [error_message, setError_message] = useState("error");
    const [alert_state, setAlert_state] = useState(false);
    const [mailing_address_status, setMailing_address_status] = useState(false);
    const [prior_address_status, setPrior_address_status] = useState(false);
    const [quoteStatus, setQuoteStatus] = useState(null);
    const [quote_information, setquote_information] = useState(0);
    const { height, width } = useWindowDimensions();
    const [customerRef, setCustomerRef] = useState("");
    const [covA, setCovA] = useState(0);
    const [existingCustomer, setExistingCustomer] = useState("");

    const current_date = new Date();
    const eff_date = moment().format("YYYYMMDD");
    const exp_date = moment().add(1, "years").format("YYYYMMDD");

    const [joint, setJoint_state] = useState({
        status: false,
        message: "+ Add co-applicant"

    });

    const [addres, setAddres] = useState({
        Street: "",
        City: "",
        Zip: "",
        State: ""
    });

    const [mailing_addres, mailing_setAddres] = useState({
        Street: "",
        City: "",
        Zip: "",
        State: ""
    });

    const [prior_addres, prior_setAddres] = useState({
        Street: "",
        City: "",
        Zip: "",
        State: ""
    });

    const [personal_info, setPersonalInfo] = useState({
        First: "",
        MI: "",
        Last: "",
        Email: "",
        Phone: ""
    });

    const [personal_info_2, setPersonalInfo_2] = useState({
        First: "",
        MI: "",
        Last: "",
        Email: "",
        Phone: ""
    });
    let quote_DTOInsured = [
        {
            EntityTypeCd: (joint.status === true ? "Joint" : "Individual"),
            PreferredDeliveryMethod: "Email",
            PartyInfo: [
                {
                    PartyTypeCd: "InsuredParty",
                    Status: "Active",
                    PersonInfo: [
                        {
                            BestWayToContact: "Email",
                        }
                    ],
                    NameInfo: [
                        {
                            NameTypeCd: "InsuredName",
                            GivenName: personal_info.First,
                            OtherGivenName: personal_info.MI,
                            Surname: personal_info.Last,
                            CommercialName: (joint.status === true
                                ? (personal_info.Last === personal_info_2.Last
                                    ? personal_info.Last + ", " + personal_info.First + (personal_info.MI !== "" ? " " + personal_info.MI : "")
                                    + " and " + personal_info_2.First + (personal_info_2.MI !== "" ? " " + personal_info_2.MI : "")
                                    : personal_info.Last + ", " + personal_info.First + (personal_info.MI !== "" ? " " + personal_info.MI : "")
                                    + " and " + personal_info_2.Last + ", " + personal_info_2.First + (personal_info_2.MI !== "" ? " " + personal_info_2.MI : "")
                                )
                                : personal_info.First + (personal_info.MI !== "" ? " " + personal_info.MI : "") + " " + personal_info.Last),
                        }
                    ],
                    EmailInfo: {
                        EmailTypeCd: "InsuredEmail",
                        EmailAddr: personal_info.Email,
                        PreferredInd: "No",
                        EmailOption: "PrimaryEmailOnly"
                    },
                    PhoneInfo: {
                        PhoneTypeCd: "InsuredPhonePrimary",
                        PhoneNumber: personal_info.Phone,
                        PreferredInd: "No",
                        PhoneName: "Mobile"
                    },
                    Addr: [
                        {
                            AddrTypeCd: "InsuredMailingAddr",
                            Addr1: (mailing_address_status === true ? mailing_addres.Street : addres.Street),
                            PostalCode: (mailing_address_status === true ? mailing_addres.Zip : addres.Zip),
                            City: (mailing_address_status === true ? mailing_addres.City : addres.City),
                            StateProvCd: "VA"
                        },
                        {
                            AddrTypeCd: "InsuredBillingAddr",
                            Addr1: addres.Street,
                            PostalCode: addres.Zip,
                            City: addres.City,
                            StateProvCd: "VA"
                        },
                        {
                            AddrTypeCd: "InsuredLookupAddr",
                            Addr1: addres.Street,
                            PostalCode: addres.Zip,
                            City: addres.City,
                            StateProvCd: "VA"
                        },
                        {
                            AddrTypeCd: "InsuredResidentAddr",
                            Addr1: addres.Street,
                            PostalCode: addres.Zip,
                            City: addres.City,
                            StateProvCd: "VA"
                        },
                        {
                            AddrTypeCd: "InsuredPriorAddr",
                            Addr1: prior_addres.Street,
                            PostalCode: prior_addres.Zip,
                            City: prior_addres.City,
                            StateProvCd: "VA"
                        }
                    ]
                },
                (joint.status === true ? {

                    PartyTypeCd: "InsuredPartyJoint",
                    Status: "Active",
                    NameInfo: [
                        {
                            NameTypeCd: "InsuredNameJoint",
                            GivenName: personal_info_2.First,
                            OtherGivenName: personal_info_2.MI,
                            Surname: personal_info_2.Last,
                            CommercialName: personal_info_2.First + (personal_info_2.MI !== "" ? " " + personal_info_2.MI : "") + " " + personal_info_2.Last,
                        }
                    ],
                }
                    : null),
            ],
            IndexName: (joint.status === true
                ? (personal_info.Last === personal_info_2.Last
                    ? personal_info.Last + ", " + personal_info.First + (personal_info.MI !== "" ? " " + personal_info.MI : "")
                    + " and " + personal_info_2.First + (personal_info_2.MI !== "" ? " " + personal_info_2.MI : "")
                    : personal_info.Last + ", " + personal_info.First + (personal_info.MI !== "" ? " " + personal_info.MI : "")
                    + " and " + personal_info_2.Last + ", " + personal_info_2.First + (personal_info_2.MI !== "" ? " " + personal_info_2.MI : "")
                )
                : personal_info.First + (personal_info.MI !== "" ? " " + personal_info.MI : "") + " " + personal_info.Last)
        }
    ]



    let quote = {
        ExternalValidateBeanExist: "No",
        ByPassCustomerCreation: "No",
        DTOApplication: [{
            Status: "In Process",
            TypeCd: "Quote",
            CustomerRef: customerRef,
            DTOTransactionInfo: {
                TransactionCd: "New Business",
                SourceCd: "Innovation",
                TransactionShortDescription: "New Business",
                TransactionLongDescription: "New Business",
                TransactionEffectiveDt: "20220411",
                "TransactionNumber": "0"
            },
            DTOLine: [{
                StatusCd: "Active",
                LineCd: "Homeowners",
                RatingService: "ManualRate",
                "DTORisk": [
                    {
                        "TypeCd": "Homeowners",
                        "DTOBuilding": [
                            {
                                "NamedStormDedInd": 1,
                                "CovALimit": covA,
                                "ExistingMemberDiscount": existingCustomer == "YES" ? "Yes" : "No",
                            },
                        ],
                    },
                ],
            }],
            QuestionReplies: {
                "QuestionReply": [
                    {
                        "Name": "ExistingCustomer",
                        "Value": ""
                    },
                    {
                        "Name": "RoofTypeExistingCustomer",
                        "Value": ""
                    },
                    {
                        "Name": "ExteriorWallsAsbestosExistingCustomer",
                        "Value": ""
                    },
                    {
                        "Name": "DogsExistingCustomer",
                        "Value": ""
                    },
                    {
                        "Name": "numberOfDogsExistingCustomer",
                        "Value": "0"
                    },
                    {
                        "Name": "trampolineTreehouseSkateRampExistingCustomer",
                        "Value": ""
                    },
                    {
                        "Name": "PoolExistingCustomer",
                        "Value": ""
                    },
                    {
                        "Name": "FloorRadiantHeatExistingCustomer",
                        "Value": ""
                    },
                    {
                        "Name": "CentralHeatExistingCustomer",
                        "Value": ""
                    },
                    {
                        "Name": "BusinessInHomeExistingCustomer",
                        "Value": ""
                    },
                    {
                        "Name": "HomeSharingExistingCustomer",
                        "Value": ""
                    }
                ]
            },
            DTOInsured: quote_DTOInsured,
            DTOBasicPolicy: [{
                EffectiveDt: (
                    eff_date
                ),
                ExpirationDt: (
                    exp_date
                ),
                PolicyVersion: "1",
                RenewalTermCd: "1 Year",
                SubTypeCd: "HO5",
                PolicyForm: "HO5",
                PolicySource: "New Business Application",
                RecentMoveIn: (prior_address_status === true ? "Yes" : "No"),
                TransactionNumber: "1",
                Description: "Preferred Homeowners",
                ProviderNumber: "001",
                ControllingStateCd: "VA",
                ProductVersionIdRef: "masov-VA-PreferredHomeowners-v01-00-00",
                CarrierGroupCd: "MASOV",
                BusinessSourceCd: "DirectPortal",
                TransactionCd: "New Business"
            }]
        }]
    }

    const onChangeUser = (e) => {
        setPersonalInfo({ ...personal_info, [e.target.name]: e.target.value });
    }
    const onChangeUser_2 = (e) => {
        setPersonalInfo_2({ ...personal_info_2, [e.target.name]: e.target.value });
    }
    const onChangeAddres = (e) => {
        setAddres({ ...addres, [e.target.name]: e.target.value });
    }
    const onChangeMailingAddres = (e) => {
        mailing_setAddres({ ...mailing_addres, [e.target.name]: e.target.value });
    }
    const onChangePriorAddres = (e) => {
        prior_setAddres({ ...prior_addres, [e.target.name]: e.target.value });
    }
    const handleChange_mailing_address = () => {
        setMailing_address_status(!mailing_address_status);
    };

    const handleChange_prior_address = () => {
        setPrior_address_status(!prior_address_status);
    };
    const copyAddreses = () => {
        prior_setAddres({
            ...addres,
            Street: mailing_addres.Street,
            City: mailing_addres.City,
            Zip: mailing_addres.Zip,
            State: mailing_addres.State
        });
    };


    function validateForm() {

        const errors = {};
        const regex_email = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const regex_phone = /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/;

        //Applicant Personal Information

        if (!personal_info.First) {
            errors.Applicant_First = "First name is required!";
        }
        if (!personal_info.Last) {
            errors.Applicant_Last = "Last name is required!";
        }
        if (!personal_info.Email) {
            errors.Applicant_Email = "Email is required!";
        }
        else if (!regex_email.test(personal_info.Email)) {
            errors.Applicant_Email = "This is not a valid email format";
        }
        if (!personal_info.Phone) {
            errors.Applicant_Phone = "Phone is required!";
        }
        else if (!regex_phone.test(personal_info.Phone)) {
            errors.Applicant_Phone = "This is not a valid phone format.";
        }

        //CoApplicant Personal Information

        if (joint.status === true) {

            if (!personal_info_2.First) {
                errors.CoApplicant_First = "First name is required!";
            }
            if (!personal_info_2.Last) {
                errors.CoApplicant_Last = "Last name is required!";
            }
        }

        //Main Address

        if (!addres.Street) {
            errors.MainStreet = "Street is required!";
        }
        if (!addres.City) {
            errors.MainCity = "City is required!";
        }
        if (!addres.Zip) {
            errors.MainZip = "Zip is required!";
        }

        //Mailing Address
        if (mailing_address_status === true) {
            if (!mailing_addres.Street) {
                errors.MailStreet = "Street is required!";
            }
            if (!mailing_addres.City) {
                errors.MailCity = "City is required!";
            }
            if (!mailing_addres.Zip) {
                errors.MailZip = "Zip is required!";
            }
        }
        //Prior Address
        if (prior_address_status === true) {
            if (!prior_addres.Street) {
                errors.PriorStreet = "Street is required!";
            }
            if (!prior_addres.City) {
                errors.PriorCity = "City is required!";
            }
            if (!prior_addres.Zip) {
                errors.PriorZip = "Zip is required!";
            }
        }
        return errors;
    }


    async function databaseUpdate() {

        await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'portal/createQuoteDB', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Quote_Number: localStorage.getItem('quote_number'),
                Email: personal_info.Email,
                Insured_First_Name: personal_info.First,
                Insured_Last_Name: personal_info.Last,
                CoApplicant_First_Name: personal_info_2.First,
                CoApplicant_Last_Name: personal_info_2.Last,
                Address: addres.Street,
                Zip_code: addres.Zip,
                Quote_Status_code: "STR",
                Session_id: localStorage.getItem('session_id'),
            })
        })

        await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'portal/createQuoteInformation', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Quote_Number: localStorage.getItem('quote_number'),
            })
        })


        createActivityQuoteDB("YourInfo", "NAV", "Continue button");
    }

    const handleSubmit = (e) => {

        e.preventDefault();
        setFormErrors(validateForm());
    };

    useEffect(() => {
        if (!onLoad) {
            if (window.localStorage.getItem("quote_number") === null) {
                fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'portal/checkEmail', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Email: personal_info.Email,
                        Insured_Last_Name: personal_info.Last,
                        Zip_code: addres.Zip
                    })
                })
                    .then(res => res.json())
                    .then(result => {

                        if (result[0] === undefined) {
                            setIsSubmit(true);
                        } else if (Object.keys(formErrors).length === 0) {
                            window.localStorage.setItem("quote_to_send", JSON.stringify(quote));
                            setInspectionInfo();
                            history.push("/RetrieveEmailList");
                            //let retrive_link = window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ "RetrieveQuote";
                            //setError_message(
                            //    "This email address is already assigned to an existing Estimate. You can retrieve your estimate <a href="+ retrive_link + ">here</a>");
                            //setAlert_state(true);
                            //setIsSubmit(false);
                            //window.scrollTo(0, 0);
                        }
                    })
            } else {
                setIsSubmit(true);
            }
        }

    }, [formErrors]);

    useEffect(() => {
        if (onLoad) {
            createActivityQuoteDB("YourInfo", "PL", "YourInfo page loaded");
            localStorage.setItem('current_page', "YourInfo");

            setAddres({
                ...addres,
                Street: JSON.parse(localStorage.getItem("personal_information")).main_addr_street,
                City: JSON.parse(localStorage.getItem("personal_information")).main_addr_city,
                Zip: JSON.parse(localStorage.getItem("personal_information")).main_addr_zip,
                State: JSON.parse(localStorage.getItem("personal_information")).main_addr_state
            });

            setPersonalInfo({
                ...personal_info,
                First: JSON.parse(localStorage.getItem("personal_information")).first_name,
                Last: JSON.parse(localStorage.getItem("personal_information")).last_name,
                MI: JSON.parse(localStorage.getItem("personal_information")).mi,
                Email: JSON.parse(localStorage.getItem("personal_information")).email,
                Phone: JSON.parse(localStorage.getItem("personal_information")).phone
            });

            setPersonalInfo_2({
                ...personal_info_2,
                First: JSON.parse(localStorage.getItem("personal_information")).co_first_name,
                Last: JSON.parse(localStorage.getItem("personal_information")).co_last_name,
                MI: JSON.parse(localStorage.getItem("personal_information")).co_mi,
                Email: JSON.parse(localStorage.getItem("personal_information")).email,
                Phone: JSON.parse(localStorage.getItem("personal_information")).phone
            });

            prior_setAddres({
                ...prior_addres,
                Street: JSON.parse(localStorage.getItem("personal_information")).prior_addr_street,
                City: JSON.parse(localStorage.getItem("personal_information")).prior_addr_city,
                Zip: JSON.parse(localStorage.getItem("personal_information")).prior_addr_zip,
                State: JSON.parse(localStorage.getItem("personal_information")).prior_addr_state
            });

            setCustomerRef( JSON.parse(localStorage.getItem("personal_information")).customer_ref)
            setCovA(JSON.parse(localStorage.getItem("cov_a")))
            setExistingCustomer(localStorage.getItem("existing_customer"))

            if (JSON.parse(localStorage.getItem("personal_information")).mail_addr === true) {

                mailing_setAddres({
                    ...mailing_addres,
                    Street: JSON.parse(localStorage.getItem("personal_information")).mail_addr_street,
                    City: JSON.parse(localStorage.getItem("personal_information")).mail_addr_city,
                    Zip: JSON.parse(localStorage.getItem("personal_information")).mail_addr_zip,
                    State: JSON.parse(localStorage.getItem("personal_information")).mail_addr_state
                });

                setMailing_address_status(true);
            } else {

                mailing_setAddres({
                    ...mailing_addres,
                    Street: "",
                    City: "",
                    Zip: "",
                    State: JSON.parse(localStorage.getItem("personal_information")).main_addr_state
                });
            }

            if (JSON.parse(localStorage.getItem("personal_information")).co_applicant === true) {
                setJoint_state({
                    status: true,
                    message: "- Remove co-applicant."
                });
            }
            if (JSON.parse(localStorage.getItem("personal_information")).mail_addr === true) {
                setMailing_address_status(true);
            }
            if (JSON.parse(localStorage.getItem("personal_information")).prior_addr === true) {
                setPrior_address_status(true);
            }
            setOnLoad(false);
        }

    }, [onLoad, addres]);


    useEffect(() => {

        window.localStorage.setItem("personal_information", JSON.stringify({
            ...JSON.parse(localStorage.getItem("personal_information")),
            first_name: personal_info.First,
            last_name: personal_info.Last,
            mi: personal_info.MI,
            co_applicant: joint.status,
            co_first_name: personal_info_2.First,
            co_last_name: personal_info_2.Last,
            co_mi: personal_info_2.MI,
            email: personal_info.Email,
            phone: personal_info.Phone,
            perf_contact: "Email",
            main_addr_street: addres.Street,
            main_addr_city: addres.City,
            main_addr_zip: addres.Zip,
            main_addr_state: addres.State,
            mail_addr: mailing_address_status,
            mail_addr_street: mailing_addres.Street,
            mail_addr_city: mailing_addres.City,
            mail_addr_zip: mailing_addres.Zip,
            mail_addr_state: mailing_addres.State,
            prior_addr: prior_address_status,
            prior_addr_street: prior_addres.Street,
            prior_addr_city: prior_addres.City,
            prior_addr_zip: prior_addres.Zip,
            prior_addr_state: prior_addres.State,

        }));

    });

    useEffect(() => {
        if (!onLoad) {

            if (addres.State === "Virginia") {
                if (Object.keys(formErrors).length === 0 && isSubmit) {
                    setLoading(true);

                    if (window.localStorage.getItem("quote_number") === null) {

                        async function fetchData() {
                            const response = await createQuote(JSON.stringify(quote));
                            setQuoteStatus(response);
                            if (response !== "Service temporarily unavailable. Please try again later.") {
                                databaseUpdate();
                            } 

                            createActivityQuoteDB("YourInfo", "API_RES", "Create quote response - " + response);
                        }
                        createActivityQuoteDB("YourInfo", "API_REQ", "Create quote request");
                        fetchData();
                    }
                    else {

                        async function fetchData() {
                            const response = await fetchQuote(JSON.stringify({
                                "ApplicationNumber": '"' + window.localStorage.getItem("quote_number") + '"',
                                "Owner": "SalesPortal"
                            }));
                            setquote_information({
                                ExternalValidateBeanExist: "No",
                                DTOApplication: [
                                    response
                                ]
                            })
                        }
                        fetchData();
                    }
                } else {
                    setIsSubmit(false);
                    window.scrollTo(0, 0);
                }
            } else {

                setError_message("To provide a quote, the property must reside in the state of Virginia.");
                setAlert_state(true);
                setIsSubmit(false);
                window.scrollTo(0, 0);
            }
        }

    }, [isSubmit]);



    useEffect(() => {
        if (!onLoad) {
            quote_information.DTOApplication[0].DTOInsured = quote_DTOInsured;
            quote_information.DTOApplication[0].DTOBasicPolicy[0].RecentMoveIn = (prior_address_status === true ? "Yes" : "No");

            async function updateData() {

                const response = await updateQuote(JSON.stringify(quote_information));
                setQuoteStatus(response);

                createActivityQuoteDB("YourInfo", "API_RES", "Update quote response - " + response);
            }
            createActivityQuoteDB("YourInfo", "API_REQ", "Update quote request");
            updateData();

        }

    }, [quote_information]);


    useEffect(() => {
        setLoading(false);
        
        if (quoteStatus === "SUCCESS") {
            if (JSON.parse(localStorage.getItem("quote_information")).num_stories === undefined ||
                JSON.parse(localStorage.getItem("quote_information")).sqft === undefined ||
                JSON.parse(localStorage.getItem("quote_information")).num_bath_full === undefined ||
                JSON.parse(localStorage.getItem("quote_information")).build_year === undefined) {
             

                createActivityQuoteDB("YourInfo", "RCE_ERROR", "RCE information retrieve error");

                fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/updateQuoteDB', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Quote_Number: localStorage.getItem('quote_number'),
                        Quote_Status_code: "RCE",
                    })
                })

                setError_message("We are unable complete your estimate at this time. Please contact customer service at 800-648-1794.");
                setAlert_state(true);
                setIsSubmit(false);
                setQuoteStatus(null);
                window.scrollTo(0, 0);


            } else {
                setInspectionInfo();
                history.push("/PropertyInfo");
            }

        } else if (quoteStatus != null) {

            setIsSubmit(false);
            setError_message(quoteStatus);
            setAlert_state(true);
            setQuoteStatus(null);
        }

    }, [quoteStatus]);


    function setInspectionInfo() {

        let inspection_contact_name = JSON.parse(localStorage.getItem("personal_information")).inspection_contact_name

        if (inspection_contact_name === undefined) {
            let middle_name = "";
            if (personal_info.MI.length !== 0) {
                middle_name = personal_info.MI + " ";
            }
            inspection_contact_name = personal_info.First + " " + middle_name + personal_info.Last;
        }

        let inspection_contact_type = JSON.parse(localStorage.getItem("personal_information")).inspection_contact_type
        if (inspection_contact_type === undefined) {
            inspection_contact_type = "Assured";
        }

        let inspection_pref_contact_type = JSON.parse(localStorage.getItem("personal_information")).inspection_pref_contact_type
        if (inspection_pref_contact_type === undefined) {

            inspection_pref_contact_type = "Email";
        }
        let inspection_number = JSON.parse(localStorage.getItem("personal_information")).inspection_number
        if (inspection_number === undefined) {
            inspection_number = personal_info.Phone;
        }

        let inspection_email = JSON.parse(localStorage.getItem("personal_information")).inspection_email
        if (inspection_email === undefined) {
            inspection_email = personal_info.Email;
        }

        window.localStorage.setItem("personal_information", JSON.stringify({
            ...JSON.parse(localStorage.getItem("personal_information")),
            inspection_contact_name,
            inspection_contact_type,
            inspection_pref_contact_type,
            inspection_number,
            inspection_email,
        }));
    }


    function showJoint() {

        if (joint.status === false) {
            setJoint_state({
                status: true,
                message: "- Remove co-applicant."
            });
        } else {
            setJoint_state({
                status: false,
                message: "+ Add co-applicant"
            });
        }
    };
    function onBackButtonClick() {
        createActivityQuoteDB("YourInfo", "NAV", "Back button");
    }

    if (localStorage.getItem('status') === "COMP") {
        return <Redirect push to={'/QuoteSummary'} />;
    } else {
        return (
            <div>
                <Navigation page={1} progress={2} />

                {
                    loading ?
                        <div className="loading">
                            <div className="loading_icon">
                                <ClipLoader color={"#003550"} loading={loading} height={20} width={5} radius={10} margin={10} />

                            </div>
                        </div>
                        :
                        <div className="yourinfo_text_wrapper">

                            <div className="offset"></div>

                            {width < 758 &&
                                <Alert status={alert_state} message={error_message} width={width - 30} height={105} margin={0} color={"#ffb3b3"} />
                            }
                            {width > 758 &&

                                <Alert status={alert_state} message={error_message} width={1110} height={65} margin={"auto"} marginRight={"auto"} color={"#ffb3b3"} />
                            }


                            <p className="p3_title">Tell us about yourself</p>

                            <form onSubmit={handleSubmit} className="yourinfo_form">
                                <div className="content_box">

                                    <p className="section_1">Your name</p>
                                    <div className="section_2_a">

                                        <input
                                            name="First"
                                            className="inp_p3_1"
                                            placeholder="First"
                                            value={personal_info.First}
                                            onChange={onChangeUser}
                                            autocomplete="off"
                                        />

                                        <p className="p3_error_txt">{formErrors.Applicant_First}</p>

                                    </div>

                                    <div className="section_2_b">
                                        <input
                                            name="MI"
                                            className="inp_p3_2"
                                            placeholder="MI"
                                            value={personal_info.MI}
                                            onChange={onChangeUser}
                                            autocomplete="off"
                                        />
                                        <p className="p3_error_txt">{formErrors.Applicant_MI}</p>

                                    </div>
                                    <div className="section_2_c">
                                        <input
                                            name="Last"
                                            className="inp_p3_1"
                                            placeholder="Last"
                                            value={personal_info.Last}
                                            onChange={onChangeUser}
                                            autocomplete="off"
                                        />
                                        <p className="p3_error_txt">{formErrors.Applicant_Last}</p>


                                    </div>
                                    <div className="section_2_d">
                                        <p className="label_p3"> <span className="p3_link" onClick={showJoint} >{joint.message}</span></p>

                                        {joint.status === true && <>
                                            <p className="label_p3"> Co-application Name </p>
                                        </>}
                                    </div>

                                    {joint.status === true && <>

                                        <div className="section_2_5_a">


                                            <input
                                                name="First"
                                                className="inp_p3_1"
                                                placeholder="First"
                                                value={personal_info_2.First}
                                                onChange={onChangeUser_2}
                                                autocomplete="off"
                                            />

                                            <p className="p3_error_txt">{formErrors.CoApplicant_First}</p>

                                        </div>

                                        <div className="section_2_5_b">


                                            <input
                                                name="MI"
                                                className="inp_p3_2"
                                                placeholder="MI"
                                                value={personal_info_2.MI}
                                                onChange={onChangeUser_2}
                                                autocomplete="off"
                                            />
                                            <p className="p3_error_txt">{formErrors.CoApplicant_MI}</p>


                                        </div>

                                        <div className="section_2_5_c">


                                            <input
                                                name="Last"
                                                className="inp_p3_1"
                                                placeholder="Last"
                                                value={personal_info_2.Last}
                                                onChange={onChangeUser_2}
                                                autocomplete="off"
                                            />
                                            <p className="p3_error_txt">{formErrors.CoApplicant_Last}</p>

                                        </div>


                                    </>}


                                    <div className="divider_1">
                                        <div className="vDivider"></div>

                                    </div>
                                    <div className="section_3_a">
                                        <p className="label_p3">Email</p>

                                        <input
                                            name="Email"
                                            className="inp_p3_1"
                                            placeholder="Email"
                                            value={personal_info.Email}
                                            onChange={onChangeUser}
                                            autocomplete="off"
                                        />
                                        <p className="p3_error_txt">{formErrors.Applicant_Email}</p>
                                    </div>
                                    <div className="section_3_b">

                                        <p className="label_p3">Phone</p>


                                        <NumberFormat
                                            name="Phone"
                                            type="tel"
                                            format="(###) ###-####"
                                            mask="_"
                                            onChange={onChangeUser}
                                            value={personal_info.Phone}
                                            className="inp_p3_1"
                                            placeholder="Phone"
                                        />

                                        <p className="p3_error_txt">{formErrors.Applicant_Phone}</p>

                                    </div>

                                    <div className="section_4_a">

                                        <label>
                                            <input
                                                className="p3_checkbox"
                                                type="checkbox"
                                                checked={mailing_address_status}
                                                onChange={handleChange_mailing_address}
                                                autocomplete="off"
                                            />
                                        </label>
                                        <span className="p3_checkbox_message"> Different mailing address?</span>

                                        {mailing_address_status === true &&
                                            <>
                                                <p className="p3_subtittle">Mailing Address</p>
                                                <GooglePlacesMailingAddress mailing_setAddres={mailing_setAddres} mailing_addres={mailing_addres} onChangeMailingAddres={onChangeMailingAddres}/>
                                                <p className="p3_error_txt">{formErrors.MailStreet}</p>
                                            </>}
                                    </div>
                                    {mailing_address_status === true &&
                                        <>

                                            <div className="section_5_a">

                                                <input
                                                    name="City"
                                                    type="text"
                                                    className="inp_p3_1"
                                                    placeholder="City"
                                                    value={mailing_addres.City}
                                                    onChange={onChangeMailingAddres}
                                                    autocomplete="off"
                                                />
                                                <p className="p3_error_txt">{formErrors.MailCity}</p>
                                            </div>
                                            <div className="section_5_b">
                                                <select className="selector" name="State" id="state" size="1" value={mailing_addres.State} onChange={onChangeMailingAddres}>
                                                    <option value="Alabama">Alabama</option>
                                                    <option value="Alaska">Alaska</option>
                                                    <option value="Arizona">Arizona</option>
                                                    <option value="Arkansas">Arkansas</option>
                                                    <option value="California">California</option>
                                                    <option value="Colorado">Colorado</option>
                                                    <option value="Connecticut">Connecticut</option>
                                                    <option value="Delaware">Delaware</option>
                                                    <option value="Florida">Florida</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Hawaii">Hawaii</option>
                                                    <option value="Idaho">Idaho</option>
                                                    <option value="Illinois">Illinois</option>
                                                    <option value="Indiana">Indiana</option>
                                                    <option value="Iowa">Iowa</option>
                                                    <option value="Kansas">Kansas</option>
                                                    <option value="Kentucky">Kentucky</option>
                                                    <option value="Louisiana">Louisiana</option>
                                                    <option value="Maine">Maine</option>
                                                    <option value="Maryland">Maryland</option>
                                                    <option value="Massachusetts">Massachusetts</option>
                                                    <option value="Michigan">Michigan</option>
                                                    <option value="Minnesota">Minnesota</option>
                                                    <option value="Mississippi">Mississippi</option>
                                                    <option value="Missouri">Missouri</option>
                                                    <option value="Montana">Montana</option>
                                                    <option value="Nebraska">Nebraska</option>
                                                    <option value="Nevada">Nevada</option>
                                                    <option value="New Hampshire">New Hampshire</option>
                                                    <option value="New Jersey">New Jersey</option>
                                                    <option value="New Mexico">New Mexico</option>
                                                    <option value="New York">New York</option>
                                                    <option value="North Carolina">North Carolina</option>
                                                    <option value="Mississippi">Mississippi</option>
                                                    <option value="North Dakota">North Dakota</option>
                                                    <option value="Ohio">Ohio</option>
                                                    <option value="Oklahoma">Oklahoma</option>
                                                    <option value="Oregon">Oregon</option>
                                                    <option value="Pennsylvania">Pennsylvania</option>
                                                    <option value="Rhode Island">Rhode Island</option>
                                                    <option value="South Carolina">South Carolina</option>
                                                    <option value="South Dakota">South Dakota</option>
                                                    <option value="Tennessee">Tennessee</option>
                                                    <option value="Texas">Texas</option>
                                                    <option value="Utah">Utah</option>
                                                    <option value="Vermont">Vermont</option>
                                                    <option value="Virginia">Virginia</option>
                                                    <option value="Washington">Washington</option>
                                                    <option value="West Virginia">West Virginia</option>
                                                    <option value="Wisconsin">Wisconsin</option>
                                                    <option value="Wyoming">Wyoming</option>
                                                </select>
                                            </div>
                                            <div className="section_5_c">
                                                <input
                                                    name="Zip"
                                                    type="text"
                                                    className="inp_p3_1"
                                                    placeholder="Zip"
                                                    value={mailing_addres.Zip}
                                                    onChange={onChangeMailingAddres}
                                                    autocomplete="off"
                                                />
                                                <p className="p3_error_txt">{formErrors.MailZip}</p>
                                            </div>
                                        </>
                                    }

                                    <div className="section_7_a">
                                        <div className="vDivider"></div>
                                        <p className="p3_subtittle">Property Address</p>
                                        <GooglePlacesPropertyAddress setAddres={setAddres} addres={addres} onChangeAddres={onChangeAddres} />
                                        <p className="p3_error_txt">{formErrors.MainStreet}</p>

                                    </div>
                                    <div className="section_8_a">

                                        <input
                                            name="City"
                                            type="text"
                                            className="inp_p3_1"
                                            placeholder="City"
                                            value={addres.City}
                                            onChange={onChangeAddres}
                                            autocomplete="off"
                                        />
                                        <p className="p3_error_txt">{formErrors.MainCity}</p>
                                    </div>
                                    <div className="section_8_b">
                                        <select className="selector" name="State" id="state" size="1" value={addres.State} onChange={onChangeAddres}>
                                            <option value="Alabama">Alabama</option>
                                            <option value="Alaska">Alaska</option>
                                            <option value="Arizona">Arizona</option>
                                            <option value="Arkansas">Arkansas</option>
                                            <option value="California">California</option>
                                            <option value="Colorado">Colorado</option>
                                            <option value="Connecticut">Connecticut</option>
                                            <option value="Delaware">Delaware</option>
                                            <option value="Florida">Florida</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Hawaii">Hawaii</option>
                                            <option value="Idaho">Idaho</option>
                                            <option value="Illinois">Illinois</option>
                                            <option value="Indiana">Indiana</option>
                                            <option value="Iowa">Iowa</option>
                                            <option value="Kansas">Kansas</option>
                                            <option value="Kentucky">Kentucky</option>
                                            <option value="Louisiana">Louisiana</option>
                                            <option value="Maine">Maine</option>
                                            <option value="Maryland">Maryland</option>
                                            <option value="Massachusetts">Massachusetts</option>
                                            <option value="Michigan">Michigan</option>
                                            <option value="Minnesota">Minnesota</option>
                                            <option value="Mississippi">Mississippi</option>
                                            <option value="Missouri">Missouri</option>
                                            <option value="Montana">Montana</option>
                                            <option value="Nebraska">Nebraska</option>
                                            <option value="Nevada">Nevada</option>
                                            <option value="New Hampshire">New Hampshire</option>
                                            <option value="New Jersey">New Jersey</option>
                                            <option value="New Mexico">New Mexico</option>
                                            <option value="New York">New York</option>
                                            <option value="North Carolina">North Carolina</option>
                                            <option value="Mississippi">Mississippi</option>
                                            <option value="North Dakota">North Dakota</option>
                                            <option value="Ohio">Ohio</option>
                                            <option value="Oklahoma">Oklahoma</option>
                                            <option value="Oregon">Oregon</option>
                                            <option value="Pennsylvania">Pennsylvania</option>
                                            <option value="Rhode Island">Rhode Island</option>
                                            <option value="South Carolina">South Carolina</option>
                                            <option value="South Dakota">South Dakota</option>
                                            <option value="Tennessee">Tennessee</option>
                                            <option value="Texas">Texas</option>
                                            <option value="Utah">Utah</option>
                                            <option value="Vermont">Vermont</option>
                                            <option value="Virginia">Virginia</option>
                                            <option value="Washington">Washington</option>
                                            <option value="West Virginia">West Virginia</option>
                                            <option value="Wisconsin">Wisconsin</option>
                                            <option value="Wyoming">Wyoming</option>
                                        </select>
                                    </div>
                                    <div className="section_8_c">
                                        <input
                                            name="Zip"
                                            type="text"
                                            className="inp_p3_1"
                                            placeholder="Zip"
                                            value={addres.Zip}
                                            onChange={onChangeAddres}
                                            autocomplete="off"
                                        />
                                        <p className="p3_error_txt">{formErrors.MainZip}</p>
                                    </div>

                                    <div className="section_10_a">


                                        <>
                                            <div className="vDivider"></div>
                                        </>


                                        <label>
                                            <input
                                                className="p3_checkbox"
                                                type="checkbox"
                                                checked={prior_address_status}
                                                onChange={handleChange_prior_address}
                                                autocomplete="off"
                                            />
                                        </label>
                                        <span className="p3_checkbox_message"> New purchase?</span>

                                        {prior_address_status === true &&
                                            <>

                                                <p className="p3_subtittle">Prior Address <span className="p3_link ml-3" onClick={copyAddreses} >(Copy from mailing address)</span></p>
                                                <GooglePlacesPriorAddress prior_setAddres={prior_setAddres} prior_addres={prior_addres} onChangePriorAddres={onChangePriorAddres} />
                                                <p className="p3_error_txt">{formErrors.PriorStreet}</p>
                                            </>}
                                    </div>

                                    {prior_address_status === true &&
                                        <>

                                            <div className="section_11_a">

                                                <input
                                                    name="City"
                                                    type="text"
                                                    className="inp_p3_1"
                                                    placeholder="City"
                                                    value={prior_addres.City}
                                                    onChange={onChangePriorAddres}
                                                    autocomplete="off"
                                                />
                                                <p className="p3_error_txt">{formErrors.PriorCity}</p>

                                            </div>
                                            <div className="section_11_b">
                                                <select className="selector" name="State" id="state" size="1" value={prior_addres.State} onChange={onChangePriorAddres}>
                                                    <option value="Alabama">Alabama</option>
                                                    <option value="Alaska">Alaska</option>
                                                    <option value="Arizona">Arizona</option>
                                                    <option value="Arkansas">Arkansas</option>
                                                    <option value="California">California</option>
                                                    <option value="Colorado">Colorado</option>
                                                    <option value="Connecticut">Connecticut</option>
                                                    <option value="Delaware">Delaware</option>
                                                    <option value="Florida">Florida</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Hawaii">Hawaii</option>
                                                    <option value="Idaho">Idaho</option>
                                                    <option value="Illinois">Illinois</option>
                                                    <option value="Indiana">Indiana</option>
                                                    <option value="Iowa">Iowa</option>
                                                    <option value="Kansas">Kansas</option>
                                                    <option value="Kentucky">Kentucky</option>
                                                    <option value="Louisiana">Louisiana</option>
                                                    <option value="Maine">Maine</option>
                                                    <option value="Maryland">Maryland</option>
                                                    <option value="Massachusetts">Massachusetts</option>
                                                    <option value="Michigan">Michigan</option>
                                                    <option value="Minnesota">Minnesota</option>
                                                    <option value="Mississippi">Mississippi</option>
                                                    <option value="Missouri">Missouri</option>
                                                    <option value="Montana">Montana</option>
                                                    <option value="Nebraska">Nebraska</option>
                                                    <option value="Nevada">Nevada</option>
                                                    <option value="New Hampshire">New Hampshire</option>
                                                    <option value="New Jersey">New Jersey</option>
                                                    <option value="New Mexico">New Mexico</option>
                                                    <option value="New York">New York</option>
                                                    <option value="North Carolina">North Carolina</option>
                                                    <option value="Mississippi">Mississippi</option>
                                                    <option value="North Dakota">North Dakota</option>
                                                    <option value="Ohio">Ohio</option>
                                                    <option value="Oklahoma">Oklahoma</option>
                                                    <option value="Oregon">Oregon</option>
                                                    <option value="Pennsylvania">Pennsylvania</option>
                                                    <option value="Rhode Island">Rhode Island</option>
                                                    <option value="South Carolina">South Carolina</option>
                                                    <option value="South Dakota">South Dakota</option>
                                                    <option value="Tennessee">Tennessee</option>
                                                    <option value="Texas">Texas</option>
                                                    <option value="Utah">Utah</option>
                                                    <option value="Vermont">Vermont</option>
                                                    <option value="Virginia">Virginia</option>
                                                    <option value="Washington">Washington</option>
                                                    <option value="West Virginia">West Virginia</option>
                                                    <option value="Wisconsin">Wisconsin</option>
                                                    <option value="Wyoming">Wyoming</option>
                                                </select>
                                            </div>
                                            <div className="section_11_c">
                                                <input
                                                    name="Zip"
                                                    type="text"
                                                    className="inp_p3_1"
                                                    placeholder="Zip"
                                                    value={prior_addres.Zip}
                                                    onChange={onChangePriorAddres}
                                                    autocomplete="off"
                                                />
                                                <p className="p3_error_txt">{formErrors.PriorZip}</p>

                                            </div>
                                        </>
                                    }


                                    <div className="section_12_a">
                                        <div className="vDivider"></div>
                                        <div className="btn_p3_wrapper">
                                        <Link to={{
                                            pathname: "/",

                                        }}>
                                            <p className="btn_p3_2" onClick={onBackButtonClick}>&lt;<span>Back</span></p>
                                        </Link>
                                            <button type="submit" className="btn_p3">Continue</button>
                                        </div>
                                      
                                    </div>
                                    <div className="footer">
                                        <b>

                                            <p className="footer_1"> Questions? (800) 648-1794</p>
                                            <p className="footer_1_5"><a href="mailto: service@mutual-assurance.com">service@mutual-assurance.com</a></p>
                                            <p className="footer_2"> (Monday to Friday 8am – 4:30pm)</p>
                                        </b>
                                    </div>

                                </div>

                            </form>
                        </div>
                }
            </div>
        );
    }
}

export default YourInfo
