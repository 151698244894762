import React from 'react';
import './Navigation.css';
import { Link } from "react-router-dom";


function Navigation(props) {

    let quote_number = 'Estimate number: ' + window.localStorage.getItem("quote_number");
    const done = 100;
    const [style, setStyle] = React.useState({});

    setTimeout(() => {
        const newStyle = {
            opacity: 1,
            height: `${done}%`

        }
        setStyle(newStyle);
    }, 0);

    let active_option = 0;

    let navBar_css = 'nav_bar';
    let property_css = 'nav_bar property_bar';
    let coverages_css = 'nav_bar coverages_bar';
    let add_info_css = 'nav_bar add_info_bar';
    let nextSteps_css = 'nav_bar nextstep_bar';

    let nav_icon = "/images/empty_step.png";
    let property_icon = "/images/empty_step.png";
    let coverages_icon = "/images/empty_step.png";
    let add_info_icon = "/images/empty_step.png";
    let summary_icon = "/images/empty_step.png";
    let inspection_icon = "/images/empty_step.png";

    if (props.page === 0) {
        active_option = 0;
        navBar_css += ' inActive';
        property_css += ' inActive';
        coverages_css += ' inActive';
        add_info_css += ' inActive';
        nextSteps_css += ' inActive';
    }
    if (props.page === 1) {
        active_option = 4;
        navBar_css += ' progress-done';
        property_css += ' inActive';
        coverages_css += ' inActive';
        add_info_css += ' inActive';
        nextSteps_css += ' inActive';

        nav_icon = "/images/filled_step.png";
    }
    if (props.page === 4) {
        active_option = 5;
        property_css += ' progress-done';
        coverages_css += ' inActive';
        add_info_css += ' inActive';
        nextSteps_css += ' inActive';

        nav_icon = "/images/filled_step.png";
        property_icon = "/images/filled_step.png";
    }
    if (props.page === 5) {
        active_option = 6;
        coverages_css += ' progress-done';
        add_info_css += ' inActive';
        nextSteps_css += ' inActive';

        nav_icon = "/images/filled_step.png";
        property_icon = "/images/filled_step.png";
        coverages_icon = "/images/filled_step.png";
    }
    if (props.page === 7) {
        active_option = 7;
        add_info_css += ' progress-done';
        nextSteps_css += ' inActive';

        nav_icon = "/images/filled_step.png";
        property_icon = "/images/filled_step.png";
        coverages_icon = "/images/filled_step.png";
        add_info_icon = "/images/filled_step.png";
        summary_icon = "/images/empty_step.png";
        inspection_icon = "/images/empty_step.png";
    }
    if (props.page === 6) {
        active_option = 8;
        nextSteps_css += ' inActive';

        nav_icon = "/images/filled_step.png";
        property_icon = "/images/filled_step.png";
        coverages_icon = "/images/filled_step.png";
        add_info_icon = "/images/filled_step.png";
        inspection_icon = "/images/filled_step.png";
        summary_icon = "/images/empty_step.png";
    }
    if (props.page === 8) {
        active_option = 8;
        nextSteps_css += ' inActive';

        nav_icon = "/images/filled_step.png";
        property_icon = "/images/filled_step.png";
        coverages_icon = "/images/filled_step.png";
        add_info_icon = "/images/filled_step.png";
        inspection_icon = "/images/filled_step.png";
        summary_icon = "/images/empty_step.png";
    }
    return (

        <div className="navigationMenu">
            {props.page === 0 || props.page === 1?
                <p className="quote_header" style={{ visibility: 'hidden' }}>{quote_number}</p>
                :
                <p className="quote_header">{quote_number}</p>
            }
            <ul>
                <li className="list" >
                    <Link to="/" className="link" style={props.progress <= 1 ? { pointerEvents: "none" } : { textDecoration: 'none' }}>

                        <div className={navBar_css} style={style}>

                        </div>
                        <img alt="" className="nav_icon" src={nav_icon} />

                        {/* <span className="icon"><ion-icon name="information-circle-outline"></ion-icon></span>*/}
                        <span className={active_option === 0 ? "nav_option font-weight-bold" : "nav_option"}>Property Address</span>

                    </Link>
                </li>
            </ul>
            
            <ul>
                <li className="list">
                    <Link to="/YourInfo" style={props.progress <= 2 ? { pointerEvents: "none" } : { textDecoration: 'none' }}>

                        <div className={property_css} style={style}> </div>
                        <img alt="" className="nav_icon" src={property_icon} />
                        {/* <span className="icon"><ion-icon name="home-outline"></ion-icon></span>*/}
                        <span className={active_option === 4 ? "nav_option font-weight-bold" : "nav_option"}>Your Information</span>

                    </Link>
                </li>
            </ul>
            <ul>
                <li className="list">
                    <Link to="/PropertyInfo" style={props.progress <= 3 ? { pointerEvents: "none" } : { textDecoration: 'none' }}>

                        <div className={coverages_css} style={style} />
                        <img alt="" className="nav_icon" src={coverages_icon} />
                        {/*<span className="icon"><ion-icon name="add-circle-outline"></ion-icon></span>*/}
                        <span className={active_option === 5 ? "nav_option font-weight-bold" : "nav_option"}>Property Information</span>

                    </Link>
                </li>
            </ul>

            <ul>
                <li className="list">
                    <Link to="/Coverages" style={props.progress <= 4 ? { pointerEvents: "none" } : { textDecoration: 'none' }}>

                        <div className={add_info_css} style={style} />
                        <img alt="" className="nav_icon" src={add_info_icon} />
                        {/*   <span className="icon"><ion-icon name="document-text-outline"></ion-icon></span>*/}
                        <span className={active_option === 6 ? "nav_option font-weight-bold" : "nav_option"}>Your Estimate</span>

                    </Link>
                </li>
            </ul>

            <ul>
                <li className="list">
                    <Link to="/HomeInspection" style={props.progress <= 5 ? { pointerEvents: "none" } : { textDecoration: 'none' }}>

                        <div className={nextSteps_css} style={style} />
                        <img alt="" className="nav_icon" src={inspection_icon} />
                        {/* <span className="icon"><ion-icon name="thumbs-up-outline"></ion-icon></span>*/}
                        <span className={active_option === 7 ? "nav_option font-weight-bold" : "nav_option"}>Schedule Your Inspection</span>

                    </Link>
                </li>
            </ul>
{/*            <ul>
                <li className="list">
                    <Link to="/QuoteSummary" style={props.progress <= 6 ? { pointerEvents: "none" } : { textDecoration: 'none' }}>

                        <img alt="" className="nav_icon mb-0" src={summary_icon} />
                        */}{/* <span className="icon"><ion-icon name="thumbs-up-outline"></ion-icon></span>*/}{/*
                        <span className={active_option === 8 ? "nav_option font-weight-bold" : "nav_option"}>Estimate Summary</span>

                    </Link>
                </li>
            </ul>*/}
            {JSON.parse(localStorage.getItem("agency_information")) !== null &&
                Object.keys(JSON.parse(localStorage.getItem("agency_information"))).length !== 0 && 
                JSON.parse(localStorage.getItem("agency_information")).agency_name !== null &&
                JSON.parse(localStorage.getItem("agency_information")).agency_name !== "Mutual Assurance Society of Virginia" &&
                <>
                    <img alt="" className="icon_small" src="/images/person_icon.png" />
                    <span className="font-weight-bold ml-1 position-relative">Your Local Agent:</span>
                    <p className="ml-5 mt-0 mb-0">{window.localStorage.getItem("agent")}</p>
                    <p className="ml-5 mt-0 mb-0">{JSON.parse(localStorage.getItem("agency_information")).agency_name}</p>
                    <p className="ml-5 mt-0 mb-0">{JSON.parse(localStorage.getItem("agency_information")).agency_phone}</p>
                    <p className="ml-5 mt-0 mb-0 text-decoration-underline">{JSON.parse(localStorage.getItem("agency_information")).agency_email}</p>
                    <p className="ml-5 mt-0 mb-0">{JSON.parse(localStorage.getItem("agency_information")).agency_addres}</p>
                    <p className="ml-5 mt-0 mb-0">{JSON.parse(localStorage.getItem("agency_information")).agency_city},
                        {JSON.parse(localStorage.getItem("agency_information")).agency_state} {JSON.parse(localStorage.getItem("agency_information")).agency_zip}</p>
                </>

            }

        </div>
    );
}

export default Navigation
