import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './Page_save.css';
import { createActivityQuoteDB, getToken, getApplicationCorrespondence } from '../API/Model';

function Page_save() {

    let link = window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ "RetrieveEmail";
    const [load, onLoad] = useState(true);
    const [url, setURL] = useState({
        pdf: "",
        State: false,
    });

    useEffect(() => {

        if (load === true) {

            createActivityQuoteDB("SaveQuote", "PL", "SaveQuote page loaded");

            onLoad(false);

            async function fetchToken() {
                const response = await getToken(JSON.stringify({}));
                if (response !== "ERROR") {
                    getURL(response)
                }
            }

            async function getURL(token) {
                const response = await getApplicationCorrespondence(JSON.stringify({
                    ApplicationNumber: window.localStorage.getItem("quote_number"),
                    CorrespondenceTemplateId: "HO5Estimate",
                    Token: token
                }));
                if (response !== "ERROR") {

                    setURL({
                        ...url,
                        pdf: response,
                        State: true,
                    })
                }
            }

            fetchToken();
        }
    });



    useEffect(() => {

        if (url.State === true) {

            setURL({
                ...url,
                State: false,
            })

            let current_page = localStorage.getItem('current_page');

            const formData = new FormData();
            formData.append('email', JSON.parse(localStorage.getItem("personal_information")).email);
            formData.append('first_name', JSON.parse(localStorage.getItem("personal_information")).first_name);
            formData.append('last_name', JSON.parse(localStorage.getItem("personal_information")).last_name);
            formData.append('inspection_name', JSON.parse(localStorage.getItem("personal_information")).inspection_contact_name);

/*            if (current_page === "AdditionalInfo" || current_page === "PropertyInfo" || current_page === "Coverages") {
                formData.append('subject', "Complete Your Mutual Assurance Estimate");
                formData.append('type', "email_b");
                formData.append('pdf_url', null);
            } else {*/
                formData.append('subject', "Schedule Your Home Inspection with Mutual Assurance");
                formData.append('type', "email_c");
                formData.append('pdf_url', url.pdf);
/*            }
*/
            fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'EmailSender/sendEmail', {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then(result => {
                    createActivityQuoteDB("SaveQuote", "EMAIL_SENT", "SaveQuote email sent");
                    createActivityQuoteDB("SaveQuote", "SAVE", current_page);
                },
                    (error) => {

                    })
        }
    }, [url]);



    return (
        <div>
            <p className="save_title">Your Homeowners Estimate Has Been Saved</p>
            <div className="save_box">
                <p className="q_numb"> Estimate Number: {window.localStorage.getItem("quote_number")}</p>
                <p className="def_text">An email has been sent to the email record on file with
                    a link to retrieve this at a later time</p>
                <p className="def_text">You may also click the following link below to continue
                    with your estimate by entering a few details tied to your account</p>
                <Link to="/RetrieveEmail">
                    <p className="link">{link}</p>
                </Link>
            </div>
        </div>

    );
}
export default Page_save