import React, { useEffect, useRef, useState } from 'react';
import './GooglePlaces.css';
import { Search } from "@material-ui/icons";
import { Link } from 'react-router-dom';
import { getGoogleApiKey } from '../API/Model';

const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
//const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

function loadAsyncScript(src) {
    return new Promise(resolve => {
        const script = document.createElement("script");
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src
        })
        script.addEventListener("load", () => resolve(script));
        document.head.appendChild(script);
    })
}

function GooglePlaces({ setAdressInfo }) {

    const searchInput = useRef(null);
    const [address, setAddress] = useState({});

    //load map script
    const initMapScript = (apiKey) => {
        // if script already loaded
        if (window.google) {
            return Promise.resolve();
        }
        debugger;
        const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    }

    const extractAddress = (place) => {

        const address = {
            city: "",
            state: "",
            zip: "",
            country: "",
            plain() {
                const city = this.city ? this.city + ", " : "";
                const zip = this.zip ? this.zip + ", " : "";
                const state = this.state ? this.state + ", " : "";
                return city + zip + state + this.country;
            }
        }

        if (!Array.isArray(place?.address_components)) {
            return address;
        }

        place.address_components.forEach(component => {
            const types = component.types;
            const value = component.long_name;


            if (types.includes("locality")) {
                address.city = value;
                //window.localStorage.setItem("city", value);
            }

            if (types.includes("administrative_area_level_1")) {
                address.state = value;
                //window.localStorage.setItem("state", value);
            }

            if (types.includes("postal_code")) {
                address.zip = value;
                //window.localStorage.setItem("zip", value);
            }

            if (types.includes("country")) {
                address.country = value;
                //window.localStorage.setItem("country", value);
            }
            if (types.includes("route")) {
                address.street = value;
               // window.localStorage.setItem("street", address.street_number + " " +  address.street);
            }
            if (types.includes("street_number")) {
                address.street_number = value;
               // window.localStorage.setItem("street", address.street_number);
            }

        });


        window.localStorage.setItem("personal_information", JSON.stringify({
            first_name: "",
            last_name: "",
            mi: "",
            co_applicant: false,
            co_first_name: "",
            co_last_name: "",
            co_mi: "",
            email: "",
            phone: "",
            perf_contact: "Email",
            main_addr_street: address.street_number + " " + address.street,
            main_addr_city: address.city,
            main_addr_zip: address.zip,
            main_addr_state: address.state,
            mail_addr: false,
            mail_addr_street: "",
            mail_addr_city: "",
            mail_addr_zip: "",
            mail_addr_state: address.state,
            prior_addr: false,
            prior_addr_street: "",
            prior_addr_city: "",
            prior_addr_zip: "",
            prior_addr_state: address.state,
            customer_ref: "",
        }));

        return address;
    }

    const onChangeAddress = (autocomplete) => {
        const place = autocomplete.getPlace();
        setAddress(extractAddress(place));
        setAdressInfo(extractAddress(place));
       
    }

    const initAutocomplete = () => {
        if (!searchInput.current) return;

        const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
        autocomplete.setFields(["address_component"]);
        autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
    }

    useEffect( () => {
        getGoogleApiKey().then(res => initMapScript(res).then(() => initAutocomplete()))
    }, []);

    return (
        <div>
        <div className="search">
            <span><Search /></span>
                <input ref={searchInput} type="text" placeholder="Your address..." />
            </div>
        </div>
    )
}


export default GooglePlaces;