import React from 'react';
import ClipLoader from "react-spinners/FadeLoader";
import './Banner.css';

function Banner(props) {

    const tooltip = "Estimates are based on data obtained from public records and the value of your home. After your home inspection we will provide a firm quote for your home. The Estimated Annual Assessment is based on the current assessment rate of 22% which is approved by our board of directors each year.";

    function formatNumber(number) {

        if (number != null) {

            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
            });

            return formatter.format(number).substring(1);
        }
    }

    const bannerBoxClass = props.removeMarginLeft
        ? "banner_box ml-0"
        : "banner_box";

    return (
        <div>

            <div className={bannerBoxClass} data-tooltip={tooltip}>
                {!props.skipTitle && <div className="baner_header">
                    <p className="title">Your Estimate</p>
                </div>}

                <div className="baner_p1">
                    <p className="m-0 banner_p1_text">Estimated Savings<span className="baner_icon_info2 baner_icon" data-tooltip="Estimated savings are based on typical insurance premiums in your area for homes similar to yours." /></p>
                    {/*<p className="m-0 ml-2"> <span className="text-left text_normal">Estimated*/}
                    {/*    <span className="text_bold"> 5-Year</span> average annual cost:</span></p>*/}

                </div>
                <div className="baner_p2">
                    <span className="banner_p1_text_wrapper">
                        <p className="m-0 ml-2"> <span className="text-left text_normal">Over 5 years:</span></p>
                        <p className="m-0 ml-2"> <span className="text-left text_normal">Over 10 years:</span></p>
                    </span>
                    <span>
                        <p className="m-0"><span className="price_text_1">
                            <span className="text_small_4">$</span>
                            {formatNumber((JSON.parse(localStorage.getItem("fiveYearSavingsLow"))))} - <span className="text_small_4">$</span>{formatNumber((JSON.parse(localStorage.getItem("fiveYearSavingsHigh"))))}</span></p>


                        <p className="m-0"> <span className="price_text_1">
                            <span className="text_small_4">$</span
                            >{formatNumber((JSON.parse(localStorage.getItem("tenYearSavingsLow"))))} - <span className="text_small_4">$</span>{formatNumber((JSON.parse(localStorage.getItem("tenYearSavingsHigh"))))}</span></p>
                    </span>
                    {/*<p className="m-0"> <span className="price_text_3">*/}
                    {/*    <span className="text_small_4">$</span>{formatNumber((JSON.parse(localStorage.getItem("quote_information")).fiveYearPremium))}</span>*/}
                    {/*    <span className="font-weight-bold text_small_3 ">/yr.</span></p>*/}
                </div>
                <div className="baner_p3">
                    <p className="m-0"> <span className="text-left text_color">Estimated Annual Assessment:</span></p>
                    <p className="m-0"> <span className="text-left text_color">Estimated First Year Premium:</span></p>
                </div>
                <div className="baner_p4">
                    <p className="m-0"><span className="price_text_2">
                        <span className="text_small_5">$</span>
                        {formatNumber((JSON.parse(localStorage.getItem("annualAssessment"))))}</span></p>

                    <p className="m-0"> <span className="price_text_2">
                        <span className="text_small_5">$</span
                        >{formatNumber((JSON.parse(localStorage.getItem("estimatedPremium"))))}</span></p>
                </div>
                <div className="baner_p5">
                    <p className=""> <span className="">These are estimates based on a cost of {props.covA} to replace your home. Mutual Assurance conducts a free
                    inspection of your home to determine the right level of coverage for you.</span></p>
                </div>
                <div className="baner_sub_header">
                    <p className="banner_sub_title">
                        How Our Pricing Works  <span className="baner_icon_info baner_icon " data-tooltip={tooltip} />
                    </p>
                </div>
                <div className="baner_footer">
                    <p className="footer_content">
                        With our unique perpetual Homeowner policy, you pay a one-time premium to become a member<br />and
                        then a low annual assessment that is a fraction of the premium each year after
                        that.<br />This means signifcantly lower costs over the life of the policy.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Banner


