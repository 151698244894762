

export async function createQuote(quote) {

    const formData = new FormData()
    formData.append('request', quote);
    let response;
    await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/createQuote', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(result => {

            response = result;

            if (result[0].toString() === "ERROR") {

                response = "Service temporarily unavailable. Please try again later.";

            } else {

                window.localStorage.setItem("quote_number", result[0].DTOApplication[0].ApplicationNumber);

                window.localStorage.setItem("agency_information", JSON.stringify({
                    agency_name: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyName,
                    agency_phone: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyPhoneNumber,
                    agency_email: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyEmail,
                    agency_addres: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyAddress,
                    agency_city: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyCity,
                    agency_state: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyState,
                    agency_zip: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyZip,
                }));

                window.localStorage.setItem("quote_information", JSON.stringify({
                    firstYearPremium: result[0].DTOApplication[0].DTOBasicPolicy[0].FinalPremiumAmt,
                    secondYearPremium: result[0].DTOApplication[0].DTOLine[0].EstimatedSecondYearAssessment,
                    fiveYearPremium: result[0].DTOApplication[0].DTOLine[0].EstimatedFiveYearAveAnnualCost,
                    num_bath_full: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberBathrooms,
                    num_bath_part: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberPartialBathrooms,
                    num_stories: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumStories,
                    sqft: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].SqFt,
                    build_year: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].YearBuilt,
                    num_family: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberOfFamily,
                    found_type: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].FoundationType,
                }));

                response = "SUCCESS";
            }

        },
            (error) => {
                response = "Service temporarily unavailable. Please try again later.";
            })

    return response;

}

export async function retrieveQuote(quote) {

    const formData = new FormData()
    formData.append('request', quote);
    let response;
    await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/retrieveQuote', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(result => {

            response = result;
            let index = 0;

            if (result[0].toString() === "ERROR") {

                response = "ERROR";

            } else if (result[0].DTOApplication.length > 1 && quote.QuoteNumber == undefined) {

                response = "MULTIPLE_QUOTES";

            } else if (result[0].DTOApplication.length === 0) {

                response = "ERROR";

            } else {

                if (result[0].DTOApplication.length > 1) {
                    result[0].DTOApplication((app, i) => {
                        if (app.ApplicationNumber === quote.QuoteNumber) {
                            index = i;
                        }
                    })
                }

                window.localStorage.setItem("quote_number", result[0].DTOApplication[index].ApplicationNumber);

                window.localStorage.setItem("agency_information", JSON.stringify({
                    agency_name: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyName,
                    agency_phone: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyPhoneNumber,
                    agency_email: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyEmail,
                    agency_addres: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyAddress,
                    agency_city: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyCity,
                    agency_state: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyState,
                    agency_zip: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyZip,
                }));

                window.localStorage.setItem("quote_information", JSON.stringify({
                    firstYearPremium: result[0].DTOApplication[index].DTOBasicPolicy[0].FinalPremiumAmt,
                    secondYearPremium: result[0].DTOApplication[index].DTOLine[0].EstimatedSecondYearAssessment,
                    fiveYearPremium: result[0].DTOApplication[index].DTOLine[0].EstimatedFiveYearAveAnnualCost,
                    num_bath_full: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberBathrooms,
                    num_bath_part: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberPartialBathrooms,
                    num_stories: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].NumStories,
                    sqft: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].SqFt,
                    build_year: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].YearBuilt,
                    num_family: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberOfFamily,
                    found_type: result[0].DTOApplication[index].DTOLine[0].DTORisk[0].DTOBuilding[0].FoundationType,
                }));


                let middle_name = "";
                if (result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].NameInfo.find((e) => e.NameTypeCd === "InsuredName").OtherGivenName !== undefined) {
                    middle_name = result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].NameInfo.find((e) => e.NameTypeCd === "InsuredName").OtherGivenName + " ";
                }
                const inspection_contact_name =
                    result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].NameInfo.find((e) => e.NameTypeCd === "InsuredName").GivenName
                    + " " + middle_name
                    + result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].NameInfo.find((e) => e.NameTypeCd === "InsuredName").Surname;

                window.localStorage.setItem("personal_information", JSON.stringify({
                    first_name: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].NameInfo.find((e) => e.NameTypeCd === "InsuredName").GivenName,
                    last_name: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].NameInfo.find((e) => e.NameTypeCd === "InsuredName").Surname,
                    mi: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].NameInfo.find((e) => e.NameTypeCd === "InsuredName").OtherGivenName,
                    co_applicant: false,
                    co_first_name: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[1].NameInfo.find((e) => e.NameTypeCd === "InsuredNameJoint").GivenName,
                    co_last_name: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[1].NameInfo.find((e) => e.NameTypeCd === "InsuredNameJoint").Surname,
                    co_mi: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[1].NameInfo.find((e) => e.NameTypeCd === "InsuredNameJoint").OtherGivenName,
                    email: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].EmailInfo[0].EmailAddr,
                    phone: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].PhoneInfo[0].PhoneNumber,
                    main_addr_street: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].Addr.find((e) => e.AddrTypeCd === "InsuredBillingAddr").Addr1,
                    main_addr_city: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].Addr.find((e) => e.AddrTypeCd === "InsuredBillingAddr").City,
                    main_addr_zip: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].Addr.find((e) => e.AddrTypeCd === "InsuredBillingAddr").PostalCode,
                    main_addr_state: "Virginia",
                    mail_addr: false,
                    mail_addr_street: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].Addr.find((e) => e.AddrTypeCd === "InsuredMailingAddr").Addr1,
                    mail_addr_city: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].Addr.find((e) => e.AddrTypeCd === "InsuredMailingAddr").City,
                    mail_addr_zip: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].Addr.find((e) => e.AddrTypeCd === "InsuredMailingAddr").PostalCode,
                    mail_addr_state: "Virginia",
                    prior_addr: false,
                    prior_addr_street: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].Addr.find((e) => e.AddrTypeCd === "InsuredPriorAddr").Addr1,
                    prior_addr_city: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].Addr.find((e) => e.AddrTypeCd === "InsuredPriorAddr").City,
                    prior_addr_zip: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].Addr.find((e) => e.AddrTypeCd === "InsuredPriorAddr").PostalCode,
                    prior_addr_state: "Virginia",
                    inspection_contact_name,
                    inspection_contact_type: "Assured",
                    inspection_number: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].PhoneInfo[0].PhoneNumber,
                    inspection_email: result[0].DTOApplication[index].DTOInsured[0].PartyInfo[0].EmailInfo[0].EmailAddr,
                    inspection_pref_contact_type: "Email",
                }));


                response = "SUCCESS";
            }

        },
            (error) => {
                response = "ERROR";
            })

    return response;

}

export async function retrieveQuoteNumbers(quote) {

    const formData = new FormData()
    formData.append('request', quote);
    let response;
    await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/retrieveQuoteNumbers', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(result => {

            response = result;

            if (result[0].toString() === "ERROR") {

                response = "ERROR";

            } else {

                response = result[0];

            } 

        },
            (error) => {
                response = "ERROR";
            })

    return response;

}

export async function retrieveCustomer(myData) {

    const formData = new FormData()
    formData.append('request', myData);
    let response;
    await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/retrieveCustomer', {
        method: 'POST',
        body: formData
    })
        .then(response => response.json())
        .then(data => {
            response = data;
        })
        .catch((error) => {
            response = "Error";
        });

    return response;

}


export async function fetchQuote(quote) {

    const formData = new FormData()
    formData.append('request', quote);
    let response;
    await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/fetchQuote', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(result => {

            response = result.DTOApplication[0];

        },
            (error) => {
                response = "Error";
            })

    return response;

}



export async function updateQuote(quote) {

    const formData = new FormData()
    formData.append('request', quote);
    let response;
    await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/updateQuote', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(result => {

            response = result[0];

            window.localStorage.setItem("quote_information", JSON.stringify({
                firstYearPremium: result[1].DTOApplication[0].DTOBasicPolicy[0].FinalPremiumAmt,
                secondYearPremium: result[1].DTOApplication[0].DTOLine[0].EstimatedSecondYearAssessment,
                fiveYearPremium: result[1].DTOApplication[0].DTOLine[0].EstimatedFiveYearAveAnnualCost,
                num_bath_full: result[1].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberBathrooms,
                num_bath_part: result[1].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberPartialBathrooms,
                num_stories: result[1].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumStories,
                sqft: result[1].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].SqFt,
                build_year: result[1].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].YearBuilt,
                num_family: result[1].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].NumberOfFamily,
                found_type: result[1].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].FoundationType,
            }));
        },
            (error) => {
                response = "Error";
            })

    return response;

}

export async function getToken(quote) {

    const formData = new FormData()
    formData.append('request', quote);
    let response;
    await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/getToken', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(result => {

            if (result[0] === "SUCCESS") {
                response = result[1].toString();
            } else {
                response = result[0]; // ERROR
            }

        },
            (error) => {
                response = "Error";
            })

    return response;

}

export async function getGoogleApiKey() {

    let response;
    await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/getGoogleAPIKey', {
        method: 'GET'
    })
        .then(res => res.json())
        .then(result => {
            response = result;
        },
            (error) => {
                response = "Error";
            })

    return response;

}


export async function getApplicationCorrespondence(quote) {

    const formData = new FormData()
    formData.append('request', quote);
    let response;
    await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/getApplicationCorrespondence', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(result => {

            if (result[0] === "SUCCESS") {
                response = result[1].toString();
            } else {
                response = result[0]; // ERROR
            }

        },
            (error) => {
                response = "Error";
            })

    return response;

}



export function createActivityQuoteDB(page_name, activity_code, description) {

    fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/createActivityQuoteDB', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            Quote_number: localStorage.getItem('quote_number'),
            Page_Name: page_name,
            Activity_Code: activity_code,
            Source_IP: localStorage.getItem('user_ip'),
            Session_ID: localStorage.getItem('session_id'),
            Description: description,
        })
    })
}

export async function createNewLandingPageQuote(quote) {

    const formData = new FormData()
    formData.append('request', quote);
    let response;
    await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/createQuote', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(result => {

            response = result;

            if (result[0].toString() === "ERROR") {

                response = "Service temporarily unavailable. Please try again later.";

            } else {
                window.localStorage.setItem("quote_number", result[0].DTOApplication[0].ApplicationNumber);
                window.localStorage.setItem("cov_a", result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].CovALimit);


                try {
                    window.localStorage.setItem("agency_information", JSON.stringify({
                        agency_name: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyName,
                        agency_phone: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyPhoneNumber,
                        agency_email: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyEmail,
                        agency_addres: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyAddress,
                        agency_city: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyCity,
                        agency_state: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyState,
                        agency_zip: result[0].DTOApplication[0].DTOLine[0].DTORisk[0].DTOBuilding[0].Addr.find((e) => e.AddrTypeCd === "RiskLookupAddr").CountyAgencyZip,
                    }));
                } catch (e) {
                    console.log("Error with fetching agency information");
                }
                
                try {
                    window.localStorage.setItem("fiveYearSavingsLow", (result[0].DTOApplication[0].DTOLine[0].EstimatedFiveYearSavingsLow).toString());
                    window.localStorage.setItem("fiveYearSavingsHigh", (result[0].DTOApplication[0].DTOLine[0].EstimatedFiveYearSavingsHigh).toString());
                    window.localStorage.setItem("tenYearSavingsLow", (result[0].DTOApplication[0].DTOLine[0].EstimatedTenYearSavingsLow).toString());
                    window.localStorage.setItem("tenYearSavingsHigh", (result[0].DTOApplication[0].DTOLine[0].EstimatedTenYearSavingsHigh).toString());
                    window.localStorage.setItem("annualAssessment", (result[0].DTOApplication[0].DTOLine[0].EstimatedAnnualAssessment).toString());
                    window.localStorage.setItem("estimatedPremium", (result[0].DTOApplication[0].DTOLine[0].EstimatedPremium).toString());

                } catch (e) {
                    console.log("Error with fetching premium information");
                }

                window.localStorage.setItem("quote_information", JSON.stringify({
                    firstYearPremium: result[0].DTOApplication[0].DTOBasicPolicy[0].FinalPremiumAmt,
                    secondYearPremium: result[0].DTOApplication[0].DTOLine[0].EstimatedSecondYearAssessment,
                    fiveYearPremium: result[0].DTOApplication[0].DTOLine[0].EstimatedFiveYearAveAnnualCost,
                }));

                window.localStorage.setItem("personal_information", JSON.stringify({
                    customer_ref: result[0].DTOApplication[0].CustomerRef,
                }));

                response = "SUCCESS";
            }

        },
            (error) => {
                response = "Service temporarily unavailable. Please try again later.";
            })

    return response;

}

export async function updateNewLandingPageQuote(quote) {

    const formData = new FormData()
    formData.append('request', quote);
    let response;
    await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/updateQuote', {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(result => {

            response = result[0];

            window.localStorage.setItem("quote_information", JSON.stringify({
                firstYearPremium: result[1].DTOApplication[0].DTOBasicPolicy[0].FinalPremiumAmt,
                secondYearPremium: result[1].DTOApplication[0].DTOLine[0].EstimatedSecondYearAssessment,
                fiveYearPremium: result[1].DTOApplication[0].DTOLine[0].EstimatedFiveYearAveAnnualCost,
            }));
        },
            (error) => {
                response = "Error";
            })

    return response;

}
