import React, { Component } from 'react';
import './TopBar.css';
import { useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";

function TopBar() {

    const loc = useLocation();
    let save_page_link = null;
    

    if (loc.pathname === "/PropertyInfo" || loc.pathname === "/Coverages" || loc.pathname === "/HomeInspection") {

        save_page_link = <div>
            <Link to="/saveforlater">

                <img alt="" className="download_icon" src="/images/download_icon.png" />
                <div className="save_link">Save for Later</div>

            </Link>
        </div>
    }

    let logo = <Link to="/">
        <img className="logo_icon" alt="" src="/images/MASOV_logo.png" />
    </Link>

    if (loc.pathname.toLowerCase() === "/inspection" || loc.pathname.toLowerCase() === "/inspectioninfo" || loc.pathname.toLowerCase() === "/estimatesummary") {
        logo = <Link to="/Inspection">
            <img className="logo_icon" alt="" src="/images/MASOV_logo.png" />
        </Link>
    }

    if (loc.pathname.toLowerCase() === "/estimatesummary") {
        if (localStorage.getItem('current_landing_page') === "Blacksburg") {
            logo = <Link to="/Blacksburg">
                <img className="logo_icon" alt="" src="/images/MASOV_logo.png" />
            </Link>
        } else if (localStorage.getItem('current_landing_page') === "Botetourt") {
            logo = <Link to="/Botetourt">
                <img className="logo_icon" alt="" src="/images/MASOV_logo.png" />
            </Link>
        } else if (localStorage.getItem('current_landing_page') === "Culpeper") {
            logo = <Link to="/Culpeper">
                <img className="logo_icon" alt="" src="/images/MASOV_logo.png" />
            </Link>
        } else if (localStorage.getItem('current_landing_page') === "Harrisonburg") {
            logo = <Link to="/Harrisonburg">
                <img className="logo_icon" alt="" src="/images/MASOV_logo.png" />
            </Link>
        }
    }

    if (loc.pathname.toLowerCase().includes("blacksburg")) {
        logo = <Link to="/Blacksburg">
            <img className="logo_icon" alt="" src="/images/MASOV_logo.png" />
        </Link>
    } else if (loc.pathname.toLowerCase().includes("Botetourt")) {
        logo = <Link to="/Botetourt">
            <img className="logo_icon" alt="" src="/images/MASOV_logo.png" />
        </Link>
    } else if (loc.pathname.toLowerCase().includes("culpeper")) {
        logo = <Link to="/Culpeper">
            <img className="logo_icon" alt="" src="/images/MASOV_logo.png" />
        </Link>
    } else if (loc.pathname.toLowerCase().includes("harrisonburg")) {
        logo = <Link to="/Harrisonburg">
            <img className="logo_icon" alt="" src="/images/MASOV_logo.png" />
        </Link>
    }

    return (
        <div>
            <nav>
                {logo}
            </nav>
            {save_page_link}
        </div>
    );

}

export default TopBar
