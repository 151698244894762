import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Page_retrieve_a.css';
import { retrieveQuote, createActivityQuoteDB } from '../API/Model';
import Alert from '../Additional/Alert';
import useWindowDimensions from '../Additional/ScreenProps';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'

function Page_retrieve_a() {


    let history = useHistory();
    const [email, setEmail] = useState('');
    const [lastName, setlastName] = useState('');
    const [zipcode, setzipcode] = useState('');
    const [modalError, setmodalError] = useState(false);
    const [errorMessage, seterrorMessage] = useState('');
    const { height, width } = useWindowDimensions();
    const [errorHeight, setErrorHeight] = useState(65);
    const [load, setonLoad] = useState(false);
    const [fetchStatus, setfetchStatus] = useState(null);
    let quote = {
        Email: email,
        LastName: lastName,
        ZipCode: zipcode,
        SearchCriteria: "Email"
    }

    useEffect(() => {

        if (load === false) {

            localStorage.clear();

            async function getIP() {
                const res = await axios.get('https://geolocation-db.com/json/')
                localStorage.setItem('user_ip', res.data.IPv4);
            }
            getIP();
            localStorage.setItem('session_id', uuidv4());
            createActivityQuoteDB("RetriveEmail", "PL", "RetriveEmail page loaded");

            setonLoad(true);
        }

    });

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }
    const onlastNameChange = (e) => {
        setlastName(e.target.value);
    }
    const onzipcodeChange = (e) => {
        setzipcode(e.target.value);
    }
    const onModalClose = (e) => {
        setmodalError(false);
    }

    async function onButtonClick() {
        setmodalError(false);

        async function fetchData() {
            const response = await retrieveQuote(JSON.stringify(quote));
            createActivityQuoteDB("RetriveEmail", "RECALL", "RetriveEmail quote recall");
            if (response === "MULTIPLE_QUOTES") {
                localStorage.setItem("retrieve_data", JSON.stringify({
                    email,
                    lastName,
                    zipcode
                }));
                history.push("/RetrieveEmailList");
            } else if (response !== "ERROR") {
                setfetchStatus(true);
            } else {
                setErrorHeight(65);
                seterrorMessage("This estimate can not be recovered.");
                setmodalError(true);
            }
        }

        localStorage.setItem('status', "RET");
        fetchData();

    }
    useEffect(() => {

        if (fetchStatus != null) {

            fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1)+ 'portal/selectLastPage', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Quote_Number: window.localStorage.getItem("quote_number"),
                })
            }).then((response) => response.json())
                .then((responseJson) => {

                    if (responseJson[0].toString() != null) {
                        history.push("/" + responseJson[0].toString());
                    } else {
                        history.push("/AdditionalInfo");
                    }


                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [fetchStatus]);

    return (

        <div className="retrieve_offset">

            {/*{modalError === true &&*/}
            {/*    <div className="overlay" onClick={onModalClose}>*/}
            {/*    <div className="modal_settings">*/}
            {/*        <h5>  {errorMessage} </h5>*/}
            {/*        <div className=" d-flex justify-content-end pt-4">*/}
            {/*            <button onClick={onModalClose} className="retrieve_button">Close</button>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}

            {
                width < 758 &&

                <Alert status={modalError} message={errorMessage} width={width - 30} height={errorHeight} margin={0} color={"#ffb3b3"} />

            }
            {
                width > 758 &&

                <Alert status={modalError} message={errorMessage} width={670} height={errorHeight} margin={220} color={"#ffb3b3"} />
            }

            <p className="retrieve_title">
                Let's Retrieve Your Homeowners Estimate</p>

            <div className="retrieve_box">

                <p className="retrieve_label bol">
                    Pull up your estimate by
                </p>
                <p className="retrieve_label bol smal">
                    Email or
                    <span> </span>
                    <Link to="/RetrieveQuote">
                        <span className="active_link">Estimate Number</span>
                    </Link>
                </p>

                <p className="retrieve_label">
                    Email</p>
                <input
                    name="Email"
                    className="inp_retrieve"
                    type="text" id="Email" placeholder="name@gmail.com" value={email} onChange={onEmailChange}
                />

                <p className="retrieve_label">
                    Last Name</p>

                <input
                    name="Last"
                    className="inp_retrieve"
                    type="text" id="lname" placeholder="Last" value={lastName} onChange={onlastNameChange}
                />


                <p className="retrieve_label">
                    Zip Code</p>
                <input
                    name="Zip"
                    className="inp_retrieve"
                    type="text" id="Zip" placeholder="Zip Code" value={zipcode} onChange={onzipcodeChange}
                />


                <button type="submit" className="retrieve_button" onClick={onButtonClick} >Retrieve</button>

            </div>

        </div>
    );
}

export default Page_retrieve_a
