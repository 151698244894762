import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Banner from '../Additional/Banner';
import './Coverages_page.css';
import Navigation from '../Overlays/Navigation';
import ClipLoader from "react-spinners/FadeLoader";
import { fetchQuote, createActivityQuoteDB } from '../API/Model';
import * as htmlToImage from 'html-to-image';
import { Redirect } from 'react-router';
import jsPDF from "jspdf";
import '../styles.css';


function Coverages_page() {

    let quote = {
        "ApplicationNumber": '"' + window.localStorage.getItem("quote_number") + '"',
        "Owner": "SalesPortal"
    }
    const page = 5;
    const [covA, set_covA] = useState(0);
    const [covB, set_covB] = useState(0);
    const [covC, set_covC] = useState(0);
    const [covD, set_covD] = useState(0);
    const [covE, set_covE] = useState(0);
    const [covF_eachPerson, set_covF_eachPerson] = useState(0);
    const [covF_eachOccurrence, set_covF_eachOccurrence] = useState(0);
    const [policy_deductible, set_policy_deductible] = useState(0);
    const [waterDamage, set_waterDamage] = useState(0);
    const [tropical_cyclone, set_tropical_cyclone] = useState(0);
    const [load, setonLoad] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);



    useEffect(() => {
        if (load === false) {

        createActivityQuoteDB("Coverages", "PL", "Coverages page loaded");
            localStorage.setItem('current_page', "Coverages");
            async function fetchData() {
                const response = await fetchQuote(JSON.stringify(quote));

                setonLoad(true);

                try {
                    window.localStorage.setItem("fiveYearSavingsLow", (response.DTOLine[0].EstimatedFiveYearSavingsLow).toString());
                    window.localStorage.setItem("fiveYearSavingsHigh", (response.DTOLine[0].EstimatedFiveYearSavingsHigh).toString());
                    window.localStorage.setItem("tenYearSavingsLow", (response.DTOLine[0].EstimatedTenYearSavingsLow).toString());
                    window.localStorage.setItem("tenYearSavingsHigh", (response.DTOLine[0].EstimatedTenYearSavingsHigh).toString());
                    window.localStorage.setItem("annualAssessment", (response.DTOLine[0].EstimatedAnnualAssessment).toString());
                    window.localStorage.setItem("estimatedPremium", (response.DTOLine[0].EstimatedPremium).toString());

                } catch (e) {
                    console.log("Error with fetching premium information");
                }

                set_covA(response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovALimit);
                set_covB(response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovBLimit);
                set_covC(response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovCLimit);
                set_covD(response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovDLimit);
                set_covE(response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovELimit);

                set_policy_deductible(response.DTOLine[0].DTORisk[0].DTOBuilding[0].AllPerilDed)
                set_covF_eachPerson(response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovFLimit);
                set_covF_eachOccurrence(response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovFLimitEachOccurrence);
                set_waterDamage(response.DTOLine[0].DTORisk[0].DTOBuilding[0].CovWaterDamageLimitIncrease);
                set_tropical_cyclone(response.DTOLine[0].DTORisk[0].DTOBuilding[0].NamedStormDed);

            }

            fetchData();
        }
    });

    function formatNumber(number) {

        if (number != null) {

            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
            });
            return formatter.format(number);
        }
    }

    function handleToggle() {
        setIsExpanded(!isExpanded);
    }
    const iconClass = isExpanded ? 'rotate' : '';


    function onButtonClick() {
        createActivityQuoteDB("Coverages", "NAV", "Continue button");

    }
    function onBackButtonClick() {
        createActivityQuoteDB("Coverages", "NAV", "Back button");
    }

    function printScreen() {


        htmlToImage.toPng(document.getElementById('content'), { quality: 2 })
            .then(function (dataUrl) {
                const pdf = new jsPDF('p', 'mm', [230, 400]);
                pdf.addImage(dataUrl, 'PNG', -15, -10, 230, 400);
                pdf.save("Coverage_" + window.localStorage.getItem("quote_number") + ".pdf");
            });
    }
    if (localStorage.getItem('status') === "COMP") {
        return <Redirect push to={'/QuoteSummary'} />;
    } else {
        return (
            <div className="container_p6">
                <Navigation page={page} progress={4} />
                {
                    load === false ?
                        <div className="loading">
                            <div className="loading_icon">
                                <ClipLoader color={"#003550"} loading={!load} height={20} width={5} radius={10} margin={10} />
                            </div>
                        </div>
                        :
/*                        <div className={load === true ? {} : "d-none"}>
*/                        <div className="wrapper_header">
                            {/*    <NavigationMobile page={page} />*/}

                            <div className="wrapper" id="content">


                                <Banner covA={formatNumber(covA.toString())}/>

                                <div className="title_1">
                                    <p className="title_label_p5">Coverage Details
                                        <button className="title_1_button" onClick={handleToggle}><span className={`arrow-icon ${iconClass}`}></span></button>
                                        <img alt=" " className="icon_p6" onClick={printScreen} src="/images/printer_icon.png" />
                                    </p>
                                </div>
                                {isExpanded &&
                                    <div>
                                <div className="contentBox_p5_1">
                                    <div className="p5_c1_r1"><b><h4>COVERAGE</h4></b></div>
                                    <div className="p5_c2_r1"><b><h4>LIMIT</h4></b></div>

                                    <div className="p5_c1_r2 p5_label">A - Residence
                                        <div className="info_icon" data-tooltip="The cost to rebuild your entire home in the event of a total loss."></div>
                                    </div>
                                    <div className="p5_c2_r2 p5_label bol">{formatNumber(covA.toString())}</div>

                                    <div className="p5_c1_r3 p5_label">B - Related Private Structures
                                        <div className="info_icon" data-tooltip="Coverage to rebuild other structures on your property, such as a detached garage, a fence, or a sidewalk"></div>
                                    </div>
                                    <div className="p5_c2_r3 p5_label bol">{formatNumber(covB.toString())}</div>

                                    <div className="p5_c1_r4 p5_label">C - Personal Property
                                        <div className="info_icon" data-tooltip="The cost to replace your personal belongings."></div>
                                    </div>
                                    <div className="p5_c2_r4 p5_label bol">{formatNumber(covC.toString())}</div>

                                    <div className="p5_c1_r5 p5_label">D - Additional Living Cost and Loss of Rent
                                        <div className="info_icon" data-tooltip="Covers living expenses while your home is being repaired."></div>
                                    </div>
                                    <div className="p5_c2_r5 p5_label bol">{formatNumber(covD.toString())}</div>

                                    <div className="p5_c1_r6 p5_label">E - Personal Liability
                                        <div className="info_icon" data-tooltip="Covers bodily injury sustained by others for which you or resident members of your family are responsible. Limits up to $1,000,000 are available – discuss with your underwriter."></div>
                                    </div>
                                    <div className="p5_c2_r6 p5_label bol">{formatNumber(covE.toString())}</div>

                                    <div className="p5_c1_r7 p5_label">F - Medical Payments to Others
                                        <div className="info_icon" data-tooltip="Covers medical expenses for another person if their injury occurs on your property."></div>
                                    </div>
                                    <div className="p5_c2_r7 p5_label bol"></div>

                                    <div className="p5_c1_r8 p5_label baner_small_b">Each Person</div>
                                    <div className="p5_c2_r8 p5_label bol">{formatNumber(covF_eachPerson.toString())}</div>

                                    <div className="p5_c1_r9 p5_label baner_small_b">Each Occurence</div>
                                    <div className="p5_c2_r9 p5_label bol">{formatNumber(covF_eachOccurrence.toString())}</div>

                                    <div className="p5_c1_r10 p5_label">Water Damage - Sewer/Drain/Sump/Surface Water
                                        <div className="info_icon" data-tooltip="Covers damage caused by the backup or overflow of water from sewers, drains or the seepage of surface water.  Higher limits are available – discuss with your underwriter."></div>
                                    </div>
                                    <div className="p5_c2_r10 p5_label bol">$5,000</div>

                                    <div className="p5_c1_r10_5">
                                        <div className="vDivider " />
                                    </div>

                                    <div className="p5_c1_r11 p5_label">
                                        Policy Deductible
                                        <div className="info_icon" data-tooltip="The amount you’ll be required to pay out of pocket for an eligible claim before your insurance policy starts to pay. This deductible applies to all claims that are not the result of Windstorm or Hail."></div>
                                        <br /> (Applies unless otherwise specified)</div>
                                    <div className="p5_c2_r11 p5_label bol">

                                        ${policy_deductible.toString().substring(0, policy_deductible.length - 3)},{policy_deductible.toString().substring(policy_deductible.length - 3, policy_deductible.length)}</div>

                                    <div className="p5_c1_r12 p5_label">Tropical Cyclone Deductible
                                        <div className="info_icon" data-tooltip="Applies to eligible claims that are the result of a Tropical Cyclone."></div>
                                    </div>
                                    <div className="p5_c2_r12 p5_label bol">{formatNumber(tropical_cyclone.toString())}</div>

                                    <div className="p5_c1_r13">
                                        <div className="vDivider " /><br />
                                        <b><h4>ADDITIONAL COVERAGES INCLUDED:</h4></b></div>

                                    <div className="p5_c1_r14 p5_label">Personal Property Replacement Cost
                                        <div className="info_icon" data-tooltip="Your personal property is covered at the cost to replace the property."></div>
                                    </div>

                                    <div className="p5_c1_r15 p5_label">Personal Property All Perils Coverage (HO5)
                                        <div className="info_icon" data-tooltip="Your personal property is covered if it is lost or damaged for any reason unless specifically excluded. This is broader coverage than specified perils coverage which only covers your property for specific causes of loss."></div>
                                    </div>

                                    <div className="p5_c1_r16 p5_label">Expanded Replacement Cost 125%
                                        <div className="info_icon" data-tooltip="Provides additional coverage to rebuild your house if the cost is higher than normal market prices."></div>
                                    </div>

                                    <div className="p5_c1_r17 p5_label">Inflation Guard Protection
                                        <div className="info_icon" data-tooltip="Adjusts the limits of your policy each year to account for increases in construction costs due to inflation."></div>
                                    </div>

                                    <div className="p5_c1_r18 p5_label">Service Line
                                        <div className="info_icon" data-tooltip="Covers the excavation and repair of damage to service lines such as electric, water, and sewer running from your home to the street."></div>
                                    </div>
                                    <div className="p5_c1_r19 p5_label">Equipment Breakdown
                                        <div className="info_icon" data-tooltip="Covers the cost to replace or repair electrical components in your home in the event of electrical or mechanical breakdown. This includes your washer & dryer, HVAC equipment, kitchen appliances and water heaters."></div>
                                    </div>
                                    <div className="p5_c1_r20 p5_label">Personal Injury
                                        <div className="info_icon" data-tooltip="Provides coverage if you or a member of your family is charged with slander, libel, or violation of another person’s privacy."></div>
                                    </div>
                                    <div className="p5_c1_r21 p5_label">Identity Recovery Coverage
                                        <div className="info_icon" data-tooltip="Provides case management services and expense reimbursement in the event your identity is stolen."></div>
                                    </div>

                                </div>

                                <div className="title_3">
                                    <p className="title_label_p5">Additional Coverages</p>
                                    <p className="sub_title">Your underwriter will discuss these and other additional coverages with you when they provide your firm quote.</p>
                                </div>
                                <div className="contentBox_p5_3">
                                    <div className="p5_c1_r1_3 p5_label">Scheduled Property
                                        <div className="info_icon" data-tooltip="Provides coverage for specific items like jewelry and artwork to protect against theft or damage."></div>
                                    </div>

                                    <div className="p5_c1_r2_3 p5_label">Earthquake coverage
                                        <div className="info_icon" data-tooltip="Provides coverage for your home and its contents in case of an earthquake."></div>
                                    </div>

                                    <div className="p5_c1_r3_3 p5_label">Homeowners or Condo Association
                                        <div className="info_icon" data-tooltip="Covers assessments made by your homeowner or condo association for damage or loss."></div>
                                    </div>

                                    <div className="p5_c1_r4_3 p5_label">Replacement Cost for Other Structures – walls, fences and walkways
                                        <div className="info_icon" data-tooltip="Provides full replacement cost coverage for specific other structures, including fences, walls, and walkways. See policy for details."></div>
                                    </div>
                                    </div>
                                    </div>
                                }
                                <div className="footer_p5">
                                    <div className="footer_p5_1">
                                        <Link to={{
                                            pathname: "/PropertyInfo",

                                        }}>
                                            <p className="btn_p5_2" onClick={onBackButtonClick}>&lt;<span>Back</span></p>
                                        </Link>
                                        <div className="btn_p5_wrapper">
                                        <Link to="/HomeInspection" className="link_p5">
                                                <button type="submit" className="btn_p5" onClick={onButtonClick}>Continue</button>
                                            </Link>
                                            </div>
                                    </div>
                                    <div className="footer_p5_2">
                                        <b>
                                            <p className="footer_1"> Questions? (800) 648-1794</p>
                                            <p className="footer_1_5"><a href="mailto: service@mutual-assurance.com">service@mutual-assurance.com</a></p>
                                            <p className="footer_2">(Monday to Friday 8am – 4:30pm)</p>
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

export default Coverages_page